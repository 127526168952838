import React, { useContext, useState } from "react";
import styled, { ThemeContext } from "styled-components";
import { Button, Checkbox, useBreakpoints } from "best-common-react";
import { useTranslation } from "react-i18next";
import { useAuthentication } from "../contexts/AuthenticationContext";
import { consentToDataStorage } from "../api/AuthenticatedClient";

const Container = styled.div`
  position: absolute;
  width: 100%;
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) => props.theme.colors["mlb-navy"]};
  color: white !important;
  padding: 2rem;
`;

const Body = styled.div`
  position: relative;
  max-width: 768px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding: 0.5rem;
`;

const ConsentToDataStorage: React.FC = () => {
  const { refreshUserDetails } = useAuthentication();
  const { t } = useTranslation("consent");
  const [checked, setChecked] = useState(false);
  const Theme = useContext(ThemeContext);
  const { isMobile } = useBreakpoints();

  const onConsentContinue = async () => {
    try {
      await consentToDataStorage();
      await refreshUserDetails();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Container>
      <Body>
        <div>{t("consent:dataStorage")}</div>
        <Checkbox
          id="consent"
          styles={{ ...Theme.checkbox, labelColor: "white" }}
          checked={checked}
          label={t("consent:giveConsent")}
          onChange={setChecked}
        />
        <div className="d-flex flex-column align-items-center">
          <Button className={isMobile ? "col-12" : ""} onClick={onConsentContinue} disabled={!checked}>
            {t("onboarding:continue")}
          </Button>
        </div>
      </Body>
    </Container>
  );
};

export default ConsentToDataStorage;
