import React, { useContext } from "react";
import PulseLoader from "react-spinners/PulseLoader";
import { ThemeContext } from "styled-components";

type LoaderProps = {
  loading: boolean;
};

const Loader: React.FC<LoaderProps> = ({ loading }) => {
  const Theme = useContext(ThemeContext);

  if (loading) {
    return <PulseLoader loading={loading} size={14} color={Theme.colors["mlb-navy"]} />;
  } else {
    return null;
  }
};

export default Loader;
