import { Select, ToggleButtonOption, ToggleButtons, ValueOpt } from "best-common-react";
import React from "react";
import styled from "styled-components";
import { LookupToggleType } from "../../../types/Metadata";
import { useTranslation } from "react-i18next";

const DropdownContainer = styled.div`
  flex-basis: 80%;
`;

const ToggleContainer = styled.div`
  flex-basis: 20%;
  line-height: 4.55;
  height: 38px;
`;

type DropdownWithToggleProps<T> = {
  id: string;
  label: string;
  lookupToggles: LookupToggleType<T>[];
  dropdownValue: number;
  toggleValue: number;
  onDropdownChange: (option: ValueOpt<T> | undefined) => void;
  onToggleChange: (value: number) => void;
  required?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
};

const DropdownWithToggle = <T,>(props: DropdownWithToggleProps<T>) => {
  const {
    id,
    label,
    lookupToggles,
    dropdownValue,
    toggleValue,
    onDropdownChange,
    onToggleChange,
    required = false,
    readOnly = false,
    disabled = false,
  } = props;
  let dropdownOptions = lookupToggles[0].options;
  let selectedDropdown = lookupToggles[0].optionGetter(dropdownValue);
  let selectedToggle = lookupToggles[0].toggleOption;
  const { t } = useTranslation("translation");

  const toggleOptions: ToggleButtonOption[] = [];
  lookupToggles.forEach((lookupToggle: LookupToggleType<T>) => {
    if (!lookupToggle.toggleOption) {
      return;
    }
    if (lookupToggle.toggleOption.value == toggleValue) {
      dropdownOptions = lookupToggle.options;
      selectedDropdown = lookupToggle.optionGetter(dropdownValue);
      selectedToggle = lookupToggle.toggleOption;
    }
    toggleOptions.push(lookupToggle.toggleOption);
  });

  let dropdownLabel = label;
  if (!!selectedToggle) {
    dropdownLabel += " (" + selectedToggle.label + ")";
  }

  return (
    <div id={id} className="d-flex">
      <DropdownContainer>
        <Select
          id="dropdown"
          label={dropdownLabel}
          placeholder={t("translation:selectPlaceholder")}
          required={required}
          value={selectedDropdown}
          options={dropdownOptions}
          onChange={onDropdownChange}
          readOnly={readOnly}
          disabled={disabled}
        />
      </DropdownContainer>
      {!readOnly && (
        <ToggleContainer>
          <ToggleButtons
            id="toggle-buttons"
            options={toggleOptions}
            value={toggleValue}
            onChange={onToggleChange}
            required={required}
            gutterBottom={false}
            styles={{
              buttonLineHeight: "0",
              height: "38px",
              padding: "3px",
            }}
            readOnly={readOnly}
            disabled={disabled}
          />
        </ToggleContainer>
      )}
    </div>
  );
};

export default DropdownWithToggle;
