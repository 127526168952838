import React, { useEffect, useState } from "react";
import { Button, FormColumn2Wide, Modal, Input, useLoading, Select, ValueOpt } from "best-common-react";
import { useTranslation } from "react-i18next";
import { ProspectProfessionalTeamDTO } from "../../../../types/ProspectProfile";
import { useDropdowns } from "../../../../contexts/DropdownsContext";
import { IntlProfessionalLeague, IntlProfessionalTeam } from "../../../../types/Metadata";
import { getIntlProfessionalTeamsByLeagueId } from "../../../../api/UnauthenticatedClient";

type AddProfessionalTeamModalProps = {
  show: boolean;
  onClose: () => void;
  onSave: (toSave: ProspectProfessionalTeamDTO) => Promise<void>;
};

const defaultTeam: ProspectProfessionalTeamDTO = {
  id: null,
  year: "",
  clubLocation: "",
  leagueId: undefined,
  teamId: undefined,
};

const AddProfessionalTeamModal: React.FC<AddProfessionalTeamModalProps> = ({ show, onClose, onSave }) => {
  const { t } = useTranslation(["profile", "translation"]);
  const [canSave, setCanSave] = useState(false);
  const { setLoading } = useLoading();
  const [team, setTeam] = useState<ProspectProfessionalTeamDTO>(defaultTeam);
  const { intlProfessionalLeagueOptions } = useDropdowns();
  const [intlProfessionalTeamOptions, setIntlProfessionalTeamOptions] = useState<ValueOpt<IntlProfessionalTeam>[]>([]);

  useEffect(() => {
    if (!!team.leagueId) {
      getIntlProfessionalTeamsByLeagueId(team.leagueId).then((teams) => {
        setIntlProfessionalTeamOptions(teams.map((team) => ({ label: team.description, value: team })));
      });
    } else {
      setIntlProfessionalTeamOptions([]);
    }
  }, [team.leagueId]);

  useEffect(() => {
    setCanSave(!!team.year && !!team.clubLocation.length && !!team.leagueId && !!team.teamId);
  }, [team]);

  const close = () => {
    setTeam(defaultTeam);
    onClose();
  };

  const save = async () => {
    setLoading(true);
    await onSave(team);
    setLoading(false);
    close();
  };

  return (
    <Modal show={show}>
      <Modal.Header>{t("profile:addProTeamHeader")}</Modal.Header>
      <Modal.Body>
        <div className="row mb-2">
          <FormColumn2Wide>
            <Input
              id="year"
              label={t("profile:year")}
              required
              value={team.year}
              onChange={(value) => setTeam({ ...team, year: value })}
            />
          </FormColumn2Wide>
          <FormColumn2Wide>
            <Input
              id="club-location"
              label={t("profile:clubLocation")}
              required
              value={team.clubLocation}
              onChange={(value) => setTeam({ ...team, clubLocation: value })}
            />
          </FormColumn2Wide>
        </div>
        <div className="row">
          <FormColumn2Wide>
            <Select
              id="league-name"
              label={t("profile:leagueName")}
              placeholder={t("translation:selectPlaceholder")}
              options={intlProfessionalLeagueOptions}
              value={intlProfessionalLeagueOptions.find((option) => option.value.id === team.leagueId)}
              onChange={(option: ValueOpt<IntlProfessionalLeague> | undefined) => {
                setTeam({
                  ...team,
                  leagueId: option?.value.id,
                  teamId: team.leagueId != option?.value.id ? undefined : team.teamId,
                });
              }}
              required
              clearable
            />
          </FormColumn2Wide>
          <FormColumn2Wide>
            <Select
              id="club-name"
              label={t("profile:clubName")}
              placeholder={t("translation:selectPlaceholder")}
              options={intlProfessionalTeamOptions}
              value={intlProfessionalTeamOptions.find((option) => option.value.id === team.teamId)}
              onChange={(option: ValueOpt<IntlProfessionalTeam> | undefined) => {
                setTeam({ ...team, teamId: option?.value.id });
              }}
              disabled={!team.leagueId}
              required
              clearable
            />
          </FormColumn2Wide>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={save} disabled={!canSave} className="me-1" variant="primary">
          {t("translation:continue")}
        </Button>
        <Button onClick={close} variant="secondary">
          {t("translation:cancel")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddProfessionalTeamModal;
