import { Button, Modal, Typography } from "best-common-react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { RouteConstants } from "../../../constants/RouteConstants";
import styled from "styled-components";

const EmailText = styled(Typography).attrs({
  variant: "body1",
})`
  color: ${(props) => props.theme.colors["blue"]};
  margin-bottom: 0px;
`;

type ProspectAlreadyRegisteredModalProps = {
  show: boolean;
};

const ProspectAlreadyRegisteredModal: React.FC<ProspectAlreadyRegisteredModalProps> = ({ show }) => {
  const { t } = useTranslation(["login", "registration"]);
  const navigate = useNavigate();

  const onConfirm = () => {
    navigate(RouteConstants.BASE);
  };

  return (
    <Modal show={show}>
      <Modal.Header>{t("registration:alreadyRegisteredHeader")}</Modal.Header>
      <Modal.Body>
        {t("registration:alreadyRegisteredBody")}
        <a href="mailto:registration@mlb.com" style={{ textDecoration: "none" }}>
          <EmailText>registration@mlb.com</EmailText>
        </a>
        .
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onConfirm} variant="primary">
          {t("login:signIn")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ProspectAlreadyRegisteredModal;
