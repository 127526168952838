import React from "react";
import { Icon } from "best-common-react";
import { EmailAddressDTO } from "../../../../types/Profile";
import styled from "styled-components";
import { Label, Value } from "../../read-only/ReadOnlyProfileStyles";
import { useTranslation } from "react-i18next";
import { useMetadata } from "../../../../contexts/MetadataContext";
import useLanguage from "../../../../hooks/useLanguage";

const Card = styled.div`
  display: flex;
  padding: 0.5rem;
  border: 1px solid ${(props) => props.theme.colors.fog};
  background: ${(props) => props.theme.colors.white};
  justify-content: space-between;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

type ProspectEmailAddressCardProps = {
  emailAddress: EmailAddressDTO;
  onDelete?: () => Promise<void>;
};

const ProspectEmailAddressCard: React.FC<ProspectEmailAddressCardProps> = ({ emailAddress, onDelete }) => {
  const { t } = useTranslation("prospect");
  const { isEn } = useLanguage();
  const { emailAddressTypes } = useMetadata();

  const type = emailAddressTypes.find((type) => type.emailAddressTypeId === emailAddress.emailAddressTypeId);

  return (
    <Card>
      <Column>
        <div>
          <Label>{t("prospect:emailAddress")}</Label>
          <Value>{emailAddress.emailAddress}</Value>
        </div>

        <div>
          <Label>{t("prospect:emailOf")}</Label>
          <Value>{isEn ? type?.description : type?.esDescription}</Value>
        </div>
      </Column>
      {!!onDelete && (
        <Column>
          <Icon iconName="fa-trash-alt" onClick={onDelete} />
        </Column>
      )}
    </Card>
  );
};

export default ProspectEmailAddressCard;
