import React from "react";
import styled from "styled-components";

const Icon = styled.i`
  font-size: 2.5rem !important;
`;

const ProfileIcon: React.FC = () => {
  return <Icon className="fa fa-circle-user" />;
};

export default ProfileIcon;
