import React, { useContext, useEffect, useState } from "react";
import { Card, Title, SubHeader } from "./HomeCardStyles";
import { RouteConstants } from "../../constants/RouteConstants";
import { useNavigate } from "react-router-dom";
import DocumentsIcon from "../icons/DocumentsIcon";
import PulseLoader from "react-spinners/PulseLoader";
import { ThemeContext } from "styled-components";
import { getProspectConsentFormStatus, getProspectOutstandingDocumentsCount } from "../../api/AuthenticatedClient";
import { useAuthentication } from "../../contexts/AuthenticationContext";
import { useTranslation } from "react-i18next";
import { ProfileIncompleteStatuses, ProspectStatus } from "../../types/Profile";
import { useProspectConsentFormSse } from "../../contexts/ProspectConsentFormSseContext";
import { ProspectStatusIds } from "../../constants/ProspectConstants";

const DocumentsCard: React.FC = () => {
  const { userDetails } = useAuthentication();
  const { t } = useTranslation(["home", "documents", "translation"]);
  const Theme = useContext(ThemeContext);
  const { signedTs } = useProspectConsentFormSse();
  const [enabled, setEnabled] = useState(false);
  const [outstanding, setOutstanding] = useState<number>();
  const [loading, setLoading] = useState(false);
  const [documentsSubmitted, setDocumentsSubmitted] = useState<boolean>();
  const [subHeaderText, setSubHeaderText] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (!ProfileIncompleteStatuses.includes(userDetails?.registrationStatusId as ProspectStatus)) {
      getProspectConsentFormStatus().then((res) => {
        setEnabled(res.canAccessDocuments);
      });
      setDocumentsSubmitted(userDetails.registrationStatusId !== ProspectStatusIds.PROFILE_COMPLETE);
    }
  }, [userDetails]);

  useEffect(() => {
    if (enabled) {
      setLoading(true);
      getProspectOutstandingDocumentsCount()
        .then((res) => setOutstanding(res))
        .catch(console.error)
        .finally(() => setLoading(false));
    }
  }, [enabled]);

  useEffect(() => {
    if (documentsSubmitted) {
      setSubHeaderText(t("documents:submitted"));
    } else if (!!outstanding) {
      setSubHeaderText(`${outstanding} ${t("translation:required")}`);
    } else {
      setSubHeaderText(t("home:awaitingSubmission"));
    }
  }, [documentsSubmitted, outstanding, t]);

  useEffect(() => {
    setEnabled(!!signedTs);
  }, [signedTs]);

  const onClick = () => {
    if (enabled) {
      navigate(RouteConstants.DOCUMENTS);
    }
  };

  return (
    <Card onClick={onClick} canClick={enabled} enabled={enabled}>
      <DocumentsIcon />
      <Title>
        {!enabled || outstanding ? t("home:documentsCardHeaderIncomplete") : t("home:documentsCardHeaderComplete")}
      </Title>
      {enabled && (
        <SubHeader complete={documentsSubmitted}>
          {loading ? (
            <PulseLoader loading={loading} size={10} color={Theme.colors["mlb-navy"]} />
          ) : (
            <>{subHeaderText}</>
          )}
        </SubHeader>
      )}
    </Card>
  );
};

export default DocumentsCard;
