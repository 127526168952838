export const ProspectStatusIds = {
  NOT_REGISTERED: 1,
  PENDING: 2,
  VERIFIED: 3,
  SENT_TO_EBIS: 4,
  PROFILE_COMPLETE: 5,
  CORRECTION_NEEDED: 6,
  WAITING_ON_GUARDIAN_SIGNATURE: 7,
};

export const EditableProspectStatusIds = [1, 2];
