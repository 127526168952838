import React, { useRef, useState } from "react";
import styled from "styled-components";
import { useAuthentication } from "../contexts/AuthenticationContext";
import { ProspectStatusIds } from "../constants/ProspectConstants";
import ProspectEditableProfile from "../components/profile/ProspectEditableProfile";
import ProspectReadOnlyProfile from "../components/profile/ProspectReadOnlyProfile";
import ProfileHeader from "../components/profile/ProfileHeader";
import ProspectProfileCompleteModal from "../components/completion/ProspectProfileCompleteModal";
import { ProspectProfileProvider } from "../contexts/ProsepctProfileContext";

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

const Body = styled.div`
  padding: 1rem;
  width: 100%;
  height: calc(100% - 75px);
  overflow-y: scroll;
`;

const ProspectProfile: React.FC = () => {
  const { userDetails } = useAuthentication();
  const editable = userDetails?.registrationStatusId === ProspectStatusIds.PENDING;
  const [showCompleteModal, setShowCompleteModal] = useState(false);
  const containerRef = useRef(null);

  if (showCompleteModal) {
    return <ProspectProfileCompleteModal tpp={userDetails?.tpp} />;
  }

  return (
    <ProspectProfileProvider>
      <Container>
        <ProfileHeader editable={editable} />
        <Body ref={containerRef}>
          {editable ? (
            <ProspectEditableProfile setShowCompleteModal={setShowCompleteModal} containerRef={containerRef} />
          ) : (
            <ProspectReadOnlyProfile />
          )}
        </Body>
      </Container>
    </ProspectProfileProvider>
  );
};

export default ProspectProfile;
