import React, { useEffect, useState } from "react";
import { getProspectConsentFormStatus } from "../api/AuthenticatedClient";
import { MetadataProvider } from "../contexts/MetadataContext";
import { DropdownsProvider } from "../contexts/DropdownsContext";
import { ConsentFormEsignProvider } from "../contexts/ConsentFormEsignContext";
import ProspectDefaultConsentForm from "./ProspectDefaultConsentForm";
import ProspectTppConsentForm from "./ProspectTppConsentForm";
import ProspectOptionalConsentForm from "./ProspectOptionalConsentForm";

const ProspectConsentFormWrapper: React.FC = () => {
  const [esign, setEsign] = useState<boolean>();
  const [optional, setOptional] = useState<boolean>();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    getProspectConsentFormStatus().then((status) => {
      setEsign(status.esign);
      setOptional(status.optional);
      setLoaded(true);
    });
  }, []);

  if (!loaded) return null;

  return (
    <MetadataProvider>
      <DropdownsProvider>
        {optional && <ProspectOptionalConsentForm />}
        {!optional && esign && (
          <ConsentFormEsignProvider>
            <ProspectDefaultConsentForm />
          </ConsentFormEsignProvider>
        )}
        {!optional && !esign && <ProspectTppConsentForm />}
      </DropdownsProvider>
    </MetadataProvider>
  );
};

export default ProspectConsentFormWrapper;
