import styled from "styled-components";

type MobileProps = {
  isMobile: boolean;
};

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 0.5rem;
`;

export const Item = styled.div<MobileProps>`
  flex-basis: ${(props) => (props.isMobile ? "100%" : "50%")};
`;

export const Label = styled.div`
  font-size: 0.75rem;
`;

export const Value = styled.div`
  font-weight: 400;
  font-size: 1rem;
`;
