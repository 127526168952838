export const LocalStorageConstants = {
  OKTA_TOKEN: "okta-token-storage",
  AUTH0_TOKEN: "auth0-token-storage",
  LAST_CACHE_TIME: "lastCacheTime",
  POSITIONS: "positions",
  NATIONALIDTYPES: "nationalIDTypes",
  COUNTRIES: "countries",
  STATES: "states",
  PROVINCES: "province",
  THROWING_HANDS: "throwingHands",
  BATTING_SIDE: "battingSide",
  PHONE_NUM_TYPES: "phoneNumberTypes",
  PHONE_NUM_COMMUNICATION_TYPES: "phoneNumberCommunicationTypes",
  EMAIL_TYPES: "emailAddressTypes",
  HEIGHT_UNITS: "heightUnitsOfMeasurement",
  WEIGHT_UNITS: "weightUnitsOfMeasurement",
  MEXICAN_LEAGUE_TEAMS: "mexicanLeagueTeams",
  COMMUNICATION_TYPES: "communicationTypes",
  CODE_VERIFIER: "code_verifier",
  INTL_PROFESSIONAL_LEAGUES: "intlProfessionalLeagues",
  INTL_PROFESSIONAL_TEAMS: "intlProfessionalTeams",
};
