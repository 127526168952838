import React, { useContext, useEffect, useState } from "react";
import { useAuthentication } from "../../contexts/AuthenticationContext";
import { Card, SubHeader, Title } from "./HomeCardStyles";
import PulseLoader from "react-spinners/PulseLoader";
import { RouteConstants } from "../../constants/RouteConstants";
import { useNavigate } from "react-router-dom";
import ConsentFormIcon from "../icons/ConsentFormIcon";
import { useTranslation } from "react-i18next";
import { ThemeContext } from "styled-components";
import { getProspectConsentFormStatus } from "../../api/AuthenticatedClient";
import { calcConsentFormSubHeaderText } from "../../util/ConsentFormUtil";
import { ProspectConsentFormStatusDTO } from "../../types/ConsentForm";
import useLanguage from "../../hooks/useLanguage";
// import { useProspectConsentFormSse } from "../../contexts/ProspectConsetFormSseContext";

const ConsentFormCard: React.FC = () => {
  const { userDetails } = useAuthentication();
  const navigate = useNavigate();
  const { t } = useTranslation("home");
  const { isEn } = useLanguage();
  const Theme = useContext(ThemeContext);
  const [enabled, setEnabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [consentFormStatus, setConsentFormStatus] = useState<ProspectConsentFormStatusDTO>();
  // TODO: Re-enable the SSE for updating the card
  // const { signedTs } = useProspectConsentFormSse();
  const [titleText, setTitleText] = useState<string>("");
  const [subHeaderText, setSubHeaderText] = useState<string>("");

  useEffect(() => {
    if (userDetails?.registrationStatusId != 2) {
      setEnabled(true);
    }
  }, [userDetails]);

  useEffect(() => {
    if (enabled) {
      setLoading(true);
      getProspectConsentFormStatus()
        .then(setConsentFormStatus)
        .finally(() => {
          setLoading(false);
        });
    }
  }, [enabled]);

  // useEffect(() => {
  //   if (signedTs) {
  //     setConsentFormStatus({ ...prospectConsentForm, prospectSignedTs: signedTs });
  //   }
  // }, [signedTs]);

  const calcTitleText = () => {
    // Not Enabled
    if (!enabled) {
      return t("home:consentFormNotEnabledHeader");
    }

    // Optional Consent Form
    else if (consentFormStatus?.optional) {
      return t("home:optionalConsentFormHeader");
    }

    // TPP Consent Form
    else if (userDetails?.tpp) {
      return consentFormStatus?.complete
        ? t("home:consentFormCardHeaderComplete")
        : t("home:tppConsentFormCardHeaderIncomplete");
    }

    // Default Consent Form
    else {
      return consentFormStatus?.complete
        ? t("home:consentFormCardHeaderComplete")
        : t("home:consentFormCardHeaderIncomplete");
    }
  };

  useEffect(() => {
    if (!!consentFormStatus) {
      setTitleText(calcTitleText());
      setSubHeaderText(
        calcConsentFormSubHeaderText(
          consentFormStatus.complete,
          consentFormStatus.optional,
          consentFormStatus.esign,
          consentFormStatus.requiredSignatures,
          consentFormStatus.completedSignatures,
          isEn,
        ),
      );
    }
  }, [userDetails, consentFormStatus, isEn]);

  const onClick = () => {
    if (enabled) {
      navigate(RouteConstants.CONSENT_FORM);
    }
  };

  return (
    <Card onClick={onClick} enabled={enabled} canClick={enabled}>
      <ConsentFormIcon />
      <Title>{titleText}</Title>
      {enabled && (
        <SubHeader complete={consentFormStatus?.complete === true || consentFormStatus?.optional === true}>
          {loading ? (
            <PulseLoader loading={loading} size={10} color={Theme.colors["mlb-navy"]} />
          ) : (
            <>{subHeaderText}</>
          )}
        </SubHeader>
      )}
    </Card>
  );
};

export default ConsentFormCard;
