import { InactivityMonitor } from "best-common-react";
import React, { ReactNode } from "react";
import { useAuthentication } from "../../contexts/AuthenticationContext";

type MonitorActivityProps = {
  children?: ReactNode;
};

const MonitorActivity: React.FC<MonitorActivityProps> = ({ children }) => {
  const { logout } = useAuthentication();
  return (
    <InactivityMonitor duration={1000 * 60 * 25} warningDuration={1000 * 60 * 5} logout={logout}>
      {children}
    </InactivityMonitor>
  );
};

export default MonitorActivity;
