import { useBreakpoints } from "best-common-react";
import React from "react";
import FooterMobileImagePng from "../../assets/footer-mobile.png";
import FooterImagePng from "../../assets/footer.png";
import IPLContentContainer from "../display/IPLContentContainer";

const FooterImage: React.FC = () => {
  const { isMobile } = useBreakpoints();
  return (
    <IPLContentContainer>
      <div className="d-flex justify-content-center">
        <img
          src={isMobile ? FooterMobileImagePng : FooterImagePng}
          alt="Footer Image"
          style={{ maxWidth: isMobile ? "305px" : "661px" }}
        />
      </div>
    </IPLContentContainer>
  );
};

export default FooterImage;
