import React, { ReactNode, useEffect, useState } from "react";
import { AuthenticationToken, ProspectUserDetails } from "../types/Auth";
import { getLocalStorageItem } from "best-common-react";
import { LocalStorageConstants } from "../constants/LocalStorageConstants";
import { removeLocalStorageItem } from "best-common-react/lib/esm/utils/LocalStorageUtil";
import { getProspectUserDetails, AuthenticatedClient } from "../api/AuthenticatedClient";

type AuthenticationContextType = {
  userDetails?: ProspectUserDetails;
  refreshUserDetails?: () => Promise<void>;
  authenticated: boolean;
  setAuthenticated: (value: boolean) => void;
  setRegistrationStatusId: (value: number) => void;
  getToken: () => AuthenticationToken;
  logout: () => void;
};

const AuthenticationContext = React.createContext<AuthenticationContextType | undefined>(undefined);

type AuthenticationProviderProps = {
  children?: ReactNode;
};

const AuthenticationProvider: React.FC<AuthenticationProviderProps> = ({ ...props }) => {
  const [authenticated, setAuthenticated] = useState<boolean>();
  const [userDetails, setUserDetails] = useState<ProspectUserDetails>();

  const getToken = (): AuthenticationToken => {
    const token = getLocalStorageItem<AuthenticationToken>(LocalStorageConstants.AUTH0_TOKEN);

    // TODO: Refresh the access token if it is expired

    return token;
  };

  const logout = () => {
    removeLocalStorageItem(LocalStorageConstants.AUTH0_TOKEN);
    setAuthenticated(false);
  };

  // Configure the client to use Bearer token when authenticated
  AuthenticatedClient.interceptors.request.use((config) => {
    const token = getToken();

    if (token) {
      config.headers.Authorization = `Bearer ${token.accessToken}`;
      config.headers["x-id-token"] = token.idToken;
    }
    return config;
  });

  // Logout prospect if token is invalid
  AuthenticatedClient.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response?.status === 401 || error.response?.status === 403) {
        logout();
      }

      return Promise.reject(error);
    },
  );

  useEffect(() => {
    const token = getToken();
    setAuthenticated(!!token);
  }, []);

  // Fetch prospect's UserDetails record if authenticated - will cause logout if token is invalid
  useEffect(() => {
    if (authenticated) {
      fetchProspectUserDetails().catch(console.error);
    }
  }, [authenticated]);

  const fetchProspectUserDetails = async () => {
    const res = await getProspectUserDetails();
    setUserDetails(res);
  };

  const setRegistrationStatusId = (value: number) => {
    if (userDetails) {
      setUserDetails({ ...userDetails, registrationStatusId: value });
    }
  };

  return (
    <AuthenticationContext.Provider
      value={{
        authenticated,
        userDetails,
        refreshUserDetails: fetchProspectUserDetails,
        setAuthenticated,
        setRegistrationStatusId,
        getToken,
        logout,
      }}
      {...props}
    />
  );
};

const useAuthentication = (): AuthenticationContextType => {
  const context: AuthenticationContextType | undefined = React.useContext(AuthenticationContext);
  if (context === undefined) {
    throw new Error(`useAuthentication must be used within a AuthenticationProvider`);
  }
  return context;
};

export { AuthenticationProvider, useAuthentication };
