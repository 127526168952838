import React, { useEffect, useState } from "react";
import { Button, Input, Modal, Typography, useDebounce } from "best-common-react";
import { useTranslation } from "react-i18next";
import { TrainerDTO } from "../../../../types/Trainer";
import styled from "styled-components";
import { useMetadata } from "../../../../contexts/MetadataContext";
import { getTrainers } from "../../../../api/AuthenticatedClient";
import Loader from "../../../display/Loader";
import { useProspectProfile } from "../../../../contexts/ProsepctProfileContext";

const TrainersContainer = styled.div`
  height: 350px;
  overflow: scroll;
  width: 100%;
  border: 1px solid ${(props) => props.theme.colors.fog};
`;

const LoadingContainer = styled.div`
  height: 350px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.5rem;
`;

const TrainerDiv = styled.div`
  display: flex;
  background: ${(props) => props.theme.colors.white};
  border-bottom: 1px solid ${(props) => props.theme.colors.fog};
  padding: 0.25rem;
  align-items: center;
`;

type TrainerSearchModalProps = {
  open: boolean;
  onClose: () => void;
  onContinue: (trainer: TrainerDTO) => void;
  onTrainerNotListed: () => void;
};

const TrainerSearchModal: React.FC<TrainerSearchModalProps> = ({ open, onClose, onContinue, onTrainerNotListed }) => {
  const { t } = useTranslation(["prospect", "translation", "profile"]);
  const { profile } = useProspectProfile();
  const { getCountryDialCode } = useMetadata();
  const [searchText, setSearchText] = useState<string>("");
  const [searchTextDisplay, setSearchTextDisplay] = useState<string>("");
  const [trainers, setTrainers] = useState<TrainerDTO[]>([]);
  const [selected, setSelected] = useState<TrainerDTO>();
  const [searching, setSearching] = useState(false);

  const updateSearchText = useDebounce((value: string) => {
    setSearchText(value);
  }, 400);

  const fetchTrainersSearch = async (search: string) => {
    setSearching(true);
    const results: TrainerDTO[] = await getTrainers(search);
    const previouslySelected = !!profile?.trainers ? profile.trainers.map((t) => t.trainerId) : [];
    const filtered = results.filter((t) => !previouslySelected.includes(t.trainerId));
    setTrainers(filtered);
    setSearching(false);
  };

  useEffect(() => {
    fetchTrainersSearch(searchText).catch(console.error);
  }, [searchText]);

  return (
    <Modal show={open}>
      <Modal.Header close={onClose}>{t("profile:searchTrainers")}</Modal.Header>
      <Modal.Body>
        <Typography>{t("prospect:trainerPrompt")}</Typography>
        <Input
          id="trainer-search-input"
          placeholder={t("prospect:trainerSearchPlaceholder")}
          value={searchTextDisplay}
          onChange={(value) => {
            updateSearchText(value);
            setSearchTextDisplay(value);
          }}
          clearable
          gutterBottom
        />
        {searching ? (
          <LoadingContainer>
            <Loader loading={true} />
          </LoadingContainer>
        ) : (
          <TrainersContainer>
            {trainers.map((trainer: TrainerDTO) => (
              <TrainerDiv key={trainer.trainerId}>
                <div className="ms-2">
                  {selected?.trainerId === trainer.trainerId ? (
                    <Button variant="primary" size="sm" onClick={() => setSelected(undefined)}>
                      {t("translation:deselect")}
                    </Button>
                  ) : (
                    <Button variant="secondary" size="sm" onClick={() => setSelected(trainer)}>
                      {t("translation:select")}
                    </Button>
                  )}
                </div>
                <div className="ms-2">
                  <div>
                    <div>
                      {trainer.firstName}
                      {!!trainer.nickName?.length ? ` (${trainer.nickName}) - ` : ""} {trainer.lastName}
                    </div>
                    <div>{trainer.academyName}</div>
                    {!!trainer.phoneNumberCountryId && !!trainer.phoneNumber && (
                      <div>
                        +({getCountryDialCode(trainer.phoneNumberCountryId)}) {trainer.phoneNumber}
                      </div>
                    )}
                    {!!trainer.emailAddress && <div>{trainer.emailAddress}</div>}
                  </div>
                </div>
              </TrainerDiv>
            ))}
          </TrainersContainer>
        )}
      </Modal.Body>
      <Modal.Footer>
        <ButtonsContainer>
          <Button variant="primary" onClick={() => onContinue(selected)} disabled={!selected}>
            {t("translation:continue")}
          </Button>
          <Button variant="secondary" onClick={onTrainerNotListed}>
            {t("profile:trainerNotListed")}
          </Button>
        </ButtonsContainer>
      </Modal.Footer>
    </Modal>
  );
};

export default TrainerSearchModal;
