import {
  Button,
  Email,
  Input,
  Label,
  Modal,
  PhoneNumber,
  RadioGroup,
  Select,
  SubSection,
  useBreakpoints,
  ValueOpt,
} from "best-common-react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDropdowns } from "../../../../contexts/DropdownsContext";
import { City, Country, CountryIds, District } from "../../../../types/Metadata";
import { useMetadata } from "../../../../contexts/MetadataContext";
import StateSelectOrTextInput from "../../fields/StateSelectOrTextInput";
import CitySelectOrTextInput from "../../fields/CitySelectOrTextInput";
import styled from "styled-components";
import { TrainerDTO } from "../../../../types/Trainer";

const SectionContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 0.5rem;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.5rem;
`;

type InputContainerProps = {
  isMobile: boolean;
};

const InputContainer = styled.div<InputContainerProps>`
  flex-basis: ${(props) => (props.isMobile ? "100%" : "calc(50% - 0.25rem)")};
`;

const PhoneCountryCodeContainer = styled.div`
  flex-basis: 22%;
  margin-right: 4px;
`;

const PhoneNumberContainer = styled.div`
  flex-basis: 78%;
`;

const Asterisk = styled.span`
  color: ${(props) => props.theme.colors["mlb-red"]};
`;

type CreateTrainerModalModalProps = {
  open: boolean;
  onClose: () => void;
  onSave: (trainer: TrainerDTO) => Promise<void>;
};

const CreateTrainerModal: React.FC<CreateTrainerModalModalProps> = ({ open, onClose, onSave }) => {
  const { isMobile } = useBreakpoints();
  const { t } = useTranslation(["translation", "prospect", "login", "profile"]);
  const {
    countryOptions,
    getCountryOptions,
    stateOptions,
    getStateOptions,
    provinceOptions,
    getProvinceOptions,
    getDistrictOptions,
    retrieveDistrictsByCountryId,
    retrieveCitiesByStateProvinceDistrictId,
    phoneNumberCommunicationTypeRadioOptions,
  } = useDropdowns();
  const { countries } = useMetadata();
  const [trainer, setTrainer] = useState<TrainerDTO>({
    firstName: "",
    nickName: "",
    lastName: "",

    countryId: undefined,
    stateId: undefined,
    state: undefined,
    cityId: undefined,
    city: undefined,

    phoneNumberCountryId: undefined,
    phoneNumber: "",
    phoneNumberCommunicationTypeId: undefined,
    emailAddress: "",

    academyName: "",
    trainingLocation: "",
  });
  const [canSave, setCanSave] = useState<boolean>(false);
  const [validEmail, setValidEmail] = useState<boolean>(false);
  const [validPhoneNumber, setValidPhoneNumber] = useState<boolean>(false);
  const [districtOptions, setDistrictOptions] = useState<ValueOpt<District>[]>();
  const [cityOptions, setCityOptions] = useState<ValueOpt<City>[]>();

  const updateValue = (key: string, value: any) => {
    setTrainer({ ...trainer, [key]: value });
  };

  useEffect(() => {
    const validName = !!trainer.lastName?.length && (!!trainer.firstName?.length || !!trainer.nickName?.length);
    const populatedPhoneNumber =
      !!trainer.phoneNumberCountryId && !!trainer.phoneNumber?.length && !!trainer.phoneNumberCommunicationTypeId;
    setCanSave(validName && populatedPhoneNumber && validPhoneNumber && (!trainer.emailAddress?.length || validEmail));
  }, [trainer, validEmail]);

  /* Trainer Location */
  const onCountryChange = (countryId: number) => {
    setTrainer({
      ...trainer,
      countryId: countryId,
      stateId: null,
      state: null,
      cityId: null,
      city: null,
    });
  };

  const onStateChange = (idValue: number, textValue: string) => {
    if (idValue !== trainer.stateId || textValue !== trainer.state) {
      setTrainer({
        ...trainer,
        stateId: idValue,
        state: textValue,
        cityId: null,
        city: null,
      });
    }
  };

  useEffect(() => {
    if (!!trainer.countryId && trainer.countryId != CountryIds.USA && trainer.countryId != CountryIds.CANADA) {
      retrieveDistrictsByCountryId(trainer.countryId).then(setDistrictOptions);
    } else {
      setDistrictOptions([]);
    }
  }, [trainer.countryId]);

  useEffect(() => {
    const country = countries.find((c) => c.countryId === trainer.countryId);
    if (!!country?.useDropdownFlag && !!trainer.stateId) {
      retrieveCitiesByStateProvinceDistrictId(trainer.stateId).then(setCityOptions);
    } else {
      setCityOptions([]);
    }
  }, [trainer.stateId]);

  return (
    <Modal show={open}>
      <Modal.Header>{t("prospect:createNewTrainer")}</Modal.Header>
      <Modal.Body>
        <SubSection header={t("translation:name")}>
          <SectionContainer>
            <InputContainer isMobile={isMobile}>
              <Input
                id="trainer-first-name"
                label={t("prospect:firstName")}
                type="text"
                value={trainer.firstName}
                onChange={(value) => {
                  if (value.length <= 50) {
                    updateValue("firstName", value);
                  }
                }}
                required
              />
            </InputContainer>
            <InputContainer isMobile={isMobile}>
              <Input
                id="trainer-nick-name"
                label={t("prospect:newTrainerNickName")}
                type="text"
                value={trainer.nickName}
                onChange={(value) => {
                  if (value.length <= 50) {
                    updateValue("nickName", value);
                  }
                }}
              />
            </InputContainer>
            <InputContainer isMobile={isMobile}>
              <Input
                id="trainer-last-name"
                label={t("prospect:lastName")}
                type="text"
                value={trainer.lastName}
                onChange={(value) => {
                  if (value.length <= 50) {
                    updateValue("lastName", value);
                  }
                }}
                required
              />
            </InputContainer>
          </SectionContainer>
        </SubSection>
        <SubSection header={t("translation:location")}>
          <SectionContainer>
            <InputContainer isMobile={isMobile}>
              <Select
                id="trainer-country"
                label={t("prospect:country")}
                placeholder={t("translation:selectPlaceholder")}
                value={getCountryOptions(trainer.countryId)}
                onChange={(value: ValueOpt<Country> | undefined) => {
                  onCountryChange(value?.value.countryId);
                }}
                options={countryOptions}
              />
            </InputContainer>
            <InputContainer isMobile={isMobile}>
              {trainer.countryId === CountryIds.USA && (
                <StateSelectOrTextInput
                  id="trainer-state"
                  label={t("prospect:stateTerritory")}
                  idField="stateId"
                  idValue={trainer.stateId}
                  textValue={trainer.state}
                  options={stateOptions}
                  getOptionValue={getStateOptions}
                  onChange={onStateChange}
                  readOnly={false}
                  disabled={!trainer.countryId}
                  required={false}
                />
              )}
              {trainer.countryId === CountryIds.CANADA && (
                <StateSelectOrTextInput
                  id="trainer-province"
                  label={t("prospect:stateTerritory")}
                  idField="provinceId"
                  idValue={trainer.stateId}
                  textValue={trainer.state}
                  options={provinceOptions}
                  getOptionValue={getProvinceOptions}
                  onChange={onStateChange}
                  readOnly={false}
                  disabled={!trainer.countryId}
                  required={false}
                />
              )}
              {trainer.countryId !== CountryIds.USA && trainer.countryId !== CountryIds.CANADA && (
                <StateSelectOrTextInput
                  id="trainer-district"
                  label={t("prospect:stateTerritory")}
                  idField="districtId"
                  idValue={trainer.stateId}
                  textValue={trainer.state}
                  options={districtOptions}
                  getOptionValue={(value) => getDistrictOptions(districtOptions, value)}
                  onChange={onStateChange}
                  readOnly={false}
                  disabled={!trainer.countryId}
                  required={false}
                />
              )}
            </InputContainer>
            <InputContainer isMobile={isMobile}>
              <CitySelectOrTextInput
                id="trainer-city"
                label={t("prospect:city")}
                cityId={trainer.cityId}
                city={trainer.city}
                options={cityOptions}
                onChange={(idValue, textValue) => {
                  setTrainer({ ...trainer, cityId: idValue, city: textValue });
                }}
                readOnly={false}
                disabled={!trainer.countryId && !trainer.stateId}
                required={false}
              />
            </InputContainer>
          </SectionContainer>
        </SubSection>
        <SubSection header={t("profile:contactInformationSection")}>
          <SectionContainer>
            <InputContainer isMobile={isMobile}>
              <Select
                id="trainer-phone-country-code"
                className="mb-2"
                label={t("prospect:countryCode")}
                placeholder={t("translation:selectPlaceholder")}
                value={getCountryOptions(trainer.phoneNumberCountryId)}
                options={countryOptions}
                onChange={(selected: ValueOpt<Country>) => {
                  setTrainer({ ...trainer, phoneNumberCountryId: selected?.value.countryId, phoneNumber: undefined });
                }}
                clearable
                required
              />
              <div className="mb-2">
                <Label htmlFor="phoneNumber">
                  {t("prospect:phoneNumber")}
                  <Asterisk>*</Asterisk>
                </Label>
                <div className="d-flex">
                  <PhoneCountryCodeContainer>
                    <Input
                      id="trainer-phone-number-dial-code"
                      label=""
                      disabled
                      required
                      styles={{ input: { height: "39px" } }}
                      value={
                        "+" +
                        (!!trainer.phoneNumberCountryId
                          ? getCountryOptions(trainer.phoneNumberCountryId).value.dialCode
                          : "")
                      }
                      onChange={() => {
                        return;
                      }}
                    />
                  </PhoneCountryCodeContainer>
                  <PhoneNumberContainer>
                    <PhoneNumber
                      id="trainer-phone-number"
                      label=""
                      type="text"
                      value={trainer.phoneNumber}
                      onChange={(value) => {
                        updateValue("phoneNumber", value);
                      }}
                      setValidPhoneNumber={setValidPhoneNumber}
                      countryCode={getCountryOptions(trainer.phoneNumberCountryId)?.value.countryCode}
                      required
                    />
                  </PhoneNumberContainer>
                </div>
              </div>
              <RadioGroup
                id="trainer-phone-number-communication-type"
                name="trainer-phone-number-communication-type"
                label={t("prospect:phoneNumberCommunicationType")}
                vertical
                value={trainer.phoneNumberCommunicationTypeId}
                options={phoneNumberCommunicationTypeRadioOptions}
                onChange={(value) => {
                  updateValue("phoneNumberCommunicationTypeId", value);
                }}
                optionStyles={{ paddingBottom: "6px" }}
                required
              />
            </InputContainer>
            <InputContainer isMobile={isMobile}>
              <Email
                id="trainer-email"
                label={t("prospect:emailAddress")}
                type="text"
                value={trainer.emailAddress}
                onChange={(value) => {
                  updateValue("emailAddress", value);
                }}
                setValidEmail={setValidEmail}
                invalidMessage={t("login:invalidEmail")}
              />
            </InputContainer>
          </SectionContainer>
        </SubSection>
        <SubSection header={t("profile:additionalInformation")}>
          <SectionContainer>
            <InputContainer isMobile={isMobile}>
              <Input
                id="trainer-academy-name"
                label={t("prospect:newTrainerAcademyName")}
                type="text"
                value={trainer.academyName}
                onChange={(value) => {
                  if (value.length <= 100) {
                    updateValue("academyName", value);
                  }
                }}
              />
            </InputContainer>
            <InputContainer isMobile={isMobile}>
              <Input
                id="training-location"
                label={t("prospect:newTrainerTrainingLocation")}
                type="text"
                value={trainer.trainingLocation}
                onChange={(value) => {
                  if (value.length <= 100) {
                    updateValue("trainingLocation", value);
                  }
                }}
              />
            </InputContainer>
          </SectionContainer>
        </SubSection>
      </Modal.Body>
      <Modal.Footer>
        <ButtonsContainer>
          <Button onClick={() => onSave(trainer)} disabled={!canSave} className="mr-2" variant="primary">
            {t("translation:save")}
          </Button>
          <Button onClick={onClose} variant="secondary">
            {t("translation:cancel")}
          </Button>
        </ButtonsContainer>
      </Modal.Footer>
    </Modal>
  );
};

export default CreateTrainerModal;
