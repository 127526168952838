import React, { useContext, useEffect, useState } from "react";
import { Card, Icon, Title, SubHeader } from "./HomeCardStyles";
import { useNavigate } from "react-router-dom";
import { RouteConstants } from "../../constants/RouteConstants";
import { getProspectProfile } from "../../api/AuthenticatedClient";
import { ProspectProfileDTO } from "../../types/ProspectProfile";
import PulseLoader from "react-spinners/PulseLoader";
import { ThemeContext } from "styled-components";
import { calcProspectProfileCompletion } from "../../util/ProspectProfileCompletionUtil";
import { useAuthentication } from "../../contexts/AuthenticationContext";
import { EditableProspectStatusIds } from "../../constants/ProspectConstants";
import { useTranslation } from "react-i18next";

const ProfileCard: React.FC = () => {
  const navigate = useNavigate();
  const Theme = useContext(ThemeContext);
  const { t } = useTranslation(["prospect", "home", "profile"]);
  const { userDetails } = useAuthentication();
  const [loading, setLoading] = useState(false);
  const [profile, setProfile] = useState<ProspectProfileDTO>();
  const [canEdit, setCanEdit] = useState(false);
  const [completion, setCompletion] = useState<number>(0.0);

  useEffect(() => {
    setLoading(true);
    getProspectProfile()
      .then((res) => setProfile(res))
      .catch(console.error)
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (profile && canEdit) {
      let percentage = calcProspectProfileCompletion(profile) * 100;
      percentage = Math.ceil(percentage);
      percentage = Math.min(percentage, 100);
      setCompletion(percentage);
    }
  }, [profile, canEdit]);

  useEffect(() => {
    if (userDetails) {
      setCanEdit(EditableProspectStatusIds.includes(userDetails.registrationStatusId));
    }
  }, [userDetails]);

  const onClick = () => {
    navigate(RouteConstants.PROFILE);
  };

  const activeSubHeader = () =>
    loading ? (
      <PulseLoader loading={loading} size={10} color={Theme.colors["mlb-navy"]} />
    ) : (
      <>
        {completion}% {t("profile:complete")}
      </>
    );

  return (
    <Card onClick={onClick} canClick enabled={true}>
      <Icon className="fa fa-circle-user" />
      <Title>{canEdit ? t("home:profileCardHeaderIncomplete") : t("home:profileCardHeaderComplete")}</Title>
      <SubHeader complete={!canEdit}>{canEdit ? activeSubHeader() : t("prospect:submitted")}</SubHeader>
    </Card>
  );
};

export default ProfileCard;
