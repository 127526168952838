import React, { useContext } from "react";
import { Accordion, AccordionBody, AccordionHeader, useBreakpoints } from "best-common-react";
import { ThemeContext } from "styled-components";
import { Body, Item, Label, Row, Value } from "./ReadOnlyProfileStyles";
import { useProspectProfile } from "../../../contexts/ProsepctProfileContext";
import { useTranslation } from "react-i18next";
import { useMetadata } from "../../../contexts/MetadataContext";
import useLanguage from "../../../hooks/useLanguage";

const ProspectAddressReadyOnly: React.FC = () => {
  const { isMobile } = useBreakpoints();
  const { t } = useTranslation("profile");
  const { isEs } = useLanguage();
  const { profile } = useProspectProfile();
  const Theme = useContext(ThemeContext);
  const { countries } = useMetadata();

  const country = countries?.find((c) => c.countryId === profile.addressCountryId);

  return (
    <Accordion
      accordionKey="basball-info"
      styles={{ ...Theme.accordion, body: { ...Theme.accordion.body, background: Theme.colors.cloud, border: "none" } }}
    >
      <AccordionHeader>{t("profile:prospectAddressSection")}</AccordionHeader>
      <AccordionBody>
        <Body>
          <Row>
            <Item isMobile={isMobile}>
              <Label>{t("profile:country")}</Label>
              <Value>{isEs && !!country?.esDescription?.length ? country?.esDescription : country?.description}</Value>
            </Item>
            <Item isMobile={isMobile}>
              <Label>{t("profile:streetAddress")}</Label>
              <Value className="mb-1">{profile.address1}</Value>
              {!!profile.address2?.length && <Value className="mb-1">{profile.address2}</Value>}
              {!!profile.address3?.length && <Value>{profile.address3}</Value>}
            </Item>
          </Row>
          <Row>
            <Item isMobile={isMobile}>
              <Label>{t("profile:stateTerritory")}</Label>
              <Value>{profile.addressStateProvince}</Value>
            </Item>
            <Item isMobile={isMobile}>
              <Label>{t("profile:zipCode")}</Label>
              <Value>{profile.addressZipCode}</Value>
            </Item>
          </Row>
          <Row>
            <Item isMobile={isMobile}>
              <Label>{t("profile:city")}</Label>
              <Value>{profile.addressCity}</Value>
            </Item>
          </Row>
        </Body>
      </AccordionBody>
    </Accordion>
  );
};

export default ProspectAddressReadyOnly;
