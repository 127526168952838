import React from "react";
import { HeaderText, NextStepContainer } from "./CompletionModalStyles";
import ConsentFormIcon from "../icons/ConsentFormIcon";
import ProfileIcon from "../icons/ProfileIcon";
import SectionCompleteModal from "./SectionCompleteModal";
import { useTranslation } from "react-i18next";

type ProspectProfileCompleteModalProps = {
  tpp: boolean;
};

const ProspectProfileCompleteModal: React.FC<ProspectProfileCompleteModalProps> = ({ tpp }) => {
  const { t } = useTranslation(["navigation", "home", "translation"]);
  return (
    <SectionCompleteModal
      headerIcon={<ProfileIcon />}
      headerText={t("home:profileComplete")}
      buttonText={t("translation:continue")}
    >
      <NextStepContainer>
        <HeaderText>{t("navigation:nextStep")}</HeaderText>
        {tpp ? (
          <div>{t("home:tppConsentFormCardHeaderIncomplete")}</div>
        ) : (
          <div>{t("home:consentFormCardHeaderIncomplete")}</div>
        )}
        <ConsentFormIcon />
      </NextStepContainer>
    </SectionCompleteModal>
  );
};

export default ProspectProfileCompleteModal;
