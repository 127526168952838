import React, { ReactNode, useEffect, useState } from "react";
import { useSse } from "best-common-react";
import { useAuthentication } from "./AuthenticationContext";

enum ServerSentEventType {
  URL_GENERATED = "PROSPECT_SIGNING_URL_GENERATED",
  PROSPECT_SIGNED = "PROSPECT_CONSENT_FORM_SIGNED",
}

type ServerSentEvent = {
  eventType: ServerSentEventType;
  data: any;
};

/* SSE Environment Variables */
const sseUrl = window.MLBBest.envVariables.SSE_URL;
const env = window.MLBBest.envVariables.ENV;
const sseChannelPrefix = `ipl-services-${env}.`;

type ProspectConsentFormSseContextType = {
  signingUrl?: string;
  signedTs?: Date;
};

const ProspectConsentFormSseContext = React.createContext<ProspectConsentFormSseContextType | undefined>(undefined);

type ProspectConsentFormSseProviderProps = {
  children?: ReactNode;
};

const ProspectConsentFormSseProvider: React.FC<ProspectConsentFormSseProviderProps> = ({ ...props }) => {
  const { userDetails, getToken } = useAuthentication();
  const [signingUrl, setSigningUrl] = useState<string>();
  const [signedTs, setSignedTs] = useState<Date>();

  // Connect to SSE channel
  const [event] = useSse<ServerSentEvent>({
    config: {
      sseUrl: sseUrl,
      channel: sseChannelPrefix + "prospect." + userDetails.prospectId,
      accessToken: getToken().accessToken,
      idToken: getToken().idToken,
    },
  });

  useEffect(() => {
    if (event) {
      switch (event.eventType) {
        case ServerSentEventType.URL_GENERATED:
          setSigningUrl(event.data as string);
          break;
        case ServerSentEventType.PROSPECT_SIGNED:
          setSignedTs(event.data as Date);
          break;
        default:
          console.error("Invalid Server Side Event Type.");
      }
    }
  }, [event]);

  return <ProspectConsentFormSseContext.Provider value={{ signingUrl, signedTs }} {...props} />;
};

const useProspectConsentFormSse = (): ProspectConsentFormSseContextType => {
  const context: ProspectConsentFormSseContextType | undefined = React.useContext(ProspectConsentFormSseContext);
  if (context === undefined) {
    throw new Error(`useProspectConsentFormSse must be used within a ProspectConsentFormSseProvider`);
  }
  return context;
};

export { ProspectConsentFormSseProvider, useProspectConsentFormSse };
