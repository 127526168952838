import React from "react";
import { Paper, SubSection } from "best-common-react";
import { useTranslation } from "react-i18next";
import ProspectRelativeInput from "./relatives/ProspectRelativeInput";
import { useProspectProfile } from "../../../contexts/ProsepctProfileContext";
import { ProspectAddressDTO } from "../../../types/ProspectProfile";

type RelativeTypeKey = "mother" | "father" | "legalGuardian";

const ProspectRelativesSection: React.FC = () => {
  const { t } = useTranslation(["profile"]);
  const { profile, updateProfileField } = useProspectProfile();
  const { mother, father } = profile;

  const handleChange = (type: RelativeTypeKey, key: string, value: any) => {
    updateProfileField(type, { ...profile[type], hasRelative: true, [key]: value });
  };

  return (
    <div>
      <Paper className="mb-3">{t("profile:prospectRelativesSection")}</Paper>
      <SubSection header={t("profile:mother")}>
        <ProspectRelativeInput
          id="Mother"
          relative={mother}
          updateField={(key: string, value: any) => handleChange("mother", key, value)}
          updateMultipleFields={(patch: { [p: string]: any }) => {
            updateProfileField("mother", { ...mother, ...patch });
          }}
          required={father?.hasRelative === false}
          prospectAddress={profile as ProspectAddressDTO}
        />
      </SubSection>
      <SubSection header={t("profile:father")}>
        <ProspectRelativeInput
          id="Father"
          relative={father}
          updateField={(key: string, value: any) => handleChange("father", key, value)}
          updateMultipleFields={(patch: { [p: string]: any }) => {
            updateProfileField("father", { ...father, ...patch });
          }}
          required={mother?.hasRelative === false}
          prospectAddress={profile as ProspectAddressDTO}
        />
      </SubSection>
    </div>
  );
};

export default ProspectRelativesSection;
