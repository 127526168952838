import { Button, Modal } from "best-common-react";
import React from "react";
import { useTranslation } from "react-i18next";

type DuplicateAccountDetectedModalProps = {
  show: boolean;
  onCancel: () => void;
  onConfirm: () => Promise<void>;
};

const DuplicateAccountDetectedModal: React.FC<DuplicateAccountDetectedModalProps> = ({ show, onCancel, onConfirm }) => {
  const { t } = useTranslation(["translation", "registration"]);

  const confirm = async () => {
    await onConfirm();
    onCancel();
  };

  return (
    <Modal show={show}>
      <Modal.Header>{t("registration:duplicateProspectDetectedTitle")}</Modal.Header>
      <Modal.Body>{t("registration:duplicateProspectDetectedBody")}</Modal.Body>
      <Modal.Footer>
        <Button onClick={confirm} className="me-1" variant="primary">
          {t("translation:continue")}
        </Button>
        <Button onClick={onCancel} variant="secondary">
          {t("translation:close")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DuplicateAccountDetectedModal;
