import React, { useContext } from "react";
import { Accordion, AccordionBody, AccordionHeader, useBreakpoints } from "best-common-react";
import { ThemeContext } from "styled-components";
import { Item, Label, Row, Value } from "./ReadOnlyProfileStyles";
import { useProspectProfile } from "../../../contexts/ProsepctProfileContext";
import { useTranslation } from "react-i18next";
import { useMetadata } from "../../../contexts/MetadataContext";
import useLanguage from "../../../hooks/useLanguage";
import { formatLocalDate } from "../../../util/ProfileUtils";

const BioInfoReadyOnly: React.FC = () => {
  const { t } = useTranslation(["common", "profile"]);
  const Theme = useContext(ThemeContext);
  const { isMobile } = useBreakpoints();
  const { profile } = useProspectProfile();
  const { isEs } = useLanguage();
  const { countries } = useMetadata();

  const registrationCountry = countries?.find((c) => c.countryId === profile.registrationCountryId);
  const birthCountry = countries?.find((c) => c.countryId === profile.birthCountryId);

  return (
    <Accordion
      accordionKey="basball-info"
      styles={{ ...Theme.accordion, body: { ...Theme.accordion.body, background: Theme.colors.cloud, border: "none" } }}
    >
      <AccordionHeader>{t("profile:bioInformationSection")}</AccordionHeader>
      <AccordionBody>
        <Row>
          <Item isMobile={isMobile}>
            <Label>{t("profile:registrationCountry")}</Label>
            <Value>
              {isEs && !!registrationCountry?.esDescription?.length
                ? registrationCountry?.esDescription
                : registrationCountry?.description}
            </Value>
          </Item>
          <Item isMobile={isMobile}>
            <Label>{t("profile:birthDate")}</Label>
            <Value>{formatLocalDate(profile.dateOfBirth)}</Value>
          </Item>
        </Row>
        <Row>
          <Item isMobile={isMobile}>
            <div className="mb-1">
              <Label>{t("profile:hasTwin")}</Label>
              <Value>{profile.hasTwin ? t("common:yes") : t("common:no")}</Value>
            </div>

            {profile.hasTwin && (
              <div>
                <Label>{t("profile:twinCount")}</Label>
                <Value>{profile.twinCount}</Value>
              </div>
            )}
          </Item>
          <Item isMobile={isMobile}>
            <div className="mb-1">
              <Label>{t("profile:birthCountry")}</Label>
              <Value>
                {isEs && !!birthCountry?.esDescription?.length
                  ? birthCountry?.esDescription
                  : birthCountry?.description}
              </Value>
            </div>

            <div className="mb-1">
              <Label>{t("profile:birthStateProvince")}</Label>
              <Value>{profile.birthStateProvince}</Value>
            </div>

            <div>
              <Label>{t("profile:birthCity")}</Label>
              <Value>{profile.birthCity}</Value>
            </div>
          </Item>
        </Row>
        <Row>
          <div className="mb-1">
            <Label>{t("profile:traveledToUsCanPr")}</Label>
            <Value>{profile.traveledToUsCanPrFlag ? t("common:yes") : t("common:no")}</Value>
          </div>

          {profile.traveledToUsCanPrFlag && (
            <div>
              <div className="mb-1">
                <Label>{t("profile:usCanPrCount")}</Label>
                <Value>{profile.traveledToUsCanPrCount}</Value>
              </div>

              <div className="mb-1">
                <Label>{t("profile:longestStayUsCanPr")}</Label>
                <Value>{profile.longestStayUsCanPr}</Value>
              </div>

              <div>
                <Label>{t("profile:mostRecentUsCanPr")}</Label>
                <Value>{formatLocalDate(profile.recentStayUsPrCan)}</Value>
              </div>
            </div>
          )}
        </Row>
      </AccordionBody>
    </Accordion>
  );
};

export default BioInfoReadyOnly;
