import {
  Alert,
  AlertConstants,
  Button,
  HeroImage,
  Input,
  Navy,
  Password,
  Typography,
  useLoading,
} from "best-common-react";
import { ButtonOverrideStyles } from "best-common-react/lib/esm/form/buttons/Button";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { RouteConstants } from "../constants/RouteConstants";
import ChangeLanguageModal from "../components/navigation/ChangeLanguageModal";
import { Auth0ErrorCallback, login } from "../api/auth";
import UnauthenticatedNavigation from "../components/navigation/UnauthenticatedNavigation";
import fieldBackdrop from "../assets/field-backdrop.png";
import AbsolutePositionedFooter from "../components/footer/AbsolutePositionedFooter";

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Body = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 400px;
`;

const FormHolder = styled.form`
  width: 250px;
`;

const Divider = styled.div`
  margin-top: 2rem;
  margin-bottom: 2rem;
  width: 100%;
  border-bottom: 1px solid darkgrey;
`;

const EmailText = styled.span`
  color: ${(props) => props.theme.colors["blue"]};
`;

const ErrorMessage = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  text-align: center;
`;

const Login: React.FC = () => {
  const { t, i18n } = useTranslation(["login"]);
  const navigate = useNavigate();
  const { setLoading } = useLoading();
  const [changeLanguageOpen, setChangeLanguageOpen] = useState<boolean>(false);
  const [loginError, setLoginError] = useState<boolean>(false);
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const signIn = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const loginCallback: Auth0ErrorCallback = (error) => {
      setLoginError(!!error);
      setLoading(false);
    };

    setLoading(true);
    setLoginError(false);
    login(username, password, loginCallback);
  };

  const buttonLinkStyles: ButtonOverrideStyles = {
    button: Navy.button,
    link: {
      ...Navy.link,
      size: "0.625rem",
    },
  };

  useEffect(() => {
    document.documentElement.lang = "es";
    i18n.changeLanguage("es").catch(console.error);
  }, []);

  return (
    <>
      <ChangeLanguageModal open={changeLanguageOpen} setOpen={setChangeLanguageOpen} />
      <Container>
        <UnauthenticatedNavigation />
        <HeroImage image={fieldBackdrop}>
          <Body>
            <LoginContainer>
              <div className="d-flex flex-column mt-3">
                <Typography variant="h6">{t("login:dontHaveAccount")}</Typography>
                <Button
                  size="lg"
                  variant="secondary"
                  className="w-100 mt-2"
                  styles={buttonLinkStyles}
                  onClick={() => navigate(RouteConstants.REGISTER)}
                >
                  {t("login:selfRegistration")}
                </Button>
              </div>
              <div className="d-flex mt-3">
                <Button
                  onClick={(event: React.MouseEvent<HTMLElement>) => {
                    event.preventDefault();
                    event.stopPropagation();
                    setChangeLanguageOpen(true);
                  }}
                  variant="link"
                  className="w-100"
                  styles={buttonLinkStyles}
                >
                  {t("login:changeLanguage")}
                </Button>
              </div>
              <Divider />
              <FormHolder
                onSubmit={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  signIn();
                }}
              >
                <Input
                  name="username"
                  label={t("login:username")}
                  id="username"
                  required={true}
                  value={username}
                  onChange={setUsername}
                />
                <div className="mt-2">
                  <Password
                    label={t("login:password")}
                    name="password"
                    id="password"
                    required={true}
                    value={password}
                    onChange={setPassword}
                  />
                </div>
                <Button size="lg" type="submit" className="w-100 mt-4" variant="secondary">
                  {t(`login:signIn`)}
                </Button>
              </FormHolder>
              {loginError && (
                <Alert className="mt-4" type={AlertConstants.TYPES.DANGER} id="login-error">
                  <ErrorMessage>
                    <div>{t("login:invalidCombo")}</div>
                    <div>
                      {t("login:pre2024Warning1")}{" "}
                      <a href="mailto:registration@mlb.com" style={{ textDecoration: "none" }}>
                        <EmailText>registration@mlb.com</EmailText>
                      </a>{" "}
                      {t("login:pre2024Warning2")}
                    </div>
                  </ErrorMessage>
                </Alert>
              )}
            </LoginContainer>
          </Body>
        </HeroImage>
        <AbsolutePositionedFooter />
      </Container>
    </>
  );
};

export default Login;
