import React from "react";
import Header from "../navigation/Header";
import { useProspectProfile } from "../../contexts/ProsepctProfileContext";
import styled from "styled-components";
import ProfileIcon from "../icons/ProfileIcon";
import { useAuthentication } from "../../contexts/AuthenticationContext";
import { ProfileStatuses } from "../../constants/ProfileConstants";
import { useTranslation } from "react-i18next";

type SubTitleProps = {
  isComplete: boolean;
};

const SubTitle = styled.div<SubTitleProps>`
  font-size: 1rem;
  color: ${(props) => (props.isComplete ? props.theme.colors.green : props.theme.colors["mlb-red"])};
`;

type ProfileHeaderProps = {
  editable: boolean;
};

const ProfileHeader: React.FC<ProfileHeaderProps> = () => {
  const { userDetails } = useAuthentication();
  const { completionPercentage } = useProspectProfile();
  const { t } = useTranslation("profile");

  const submitted = userDetails.registrationStatusId != ProfileStatuses.PENDING;
  const icon = <ProfileIcon />;

  let subTitle = null;
  if (!!completionPercentage) {
    subTitle = submitted ? (
      <SubTitle isComplete={true}>{t("profile:submitted")}</SubTitle>
    ) : (
      <SubTitle isComplete={completionPercentage === 100}>
        {completionPercentage}% {t("profile:complete")}
      </SubTitle>
    );
  }

  return <Header icon={icon} title={t("profile:profile")} subTitle={subTitle} />;
};

export default ProfileHeader;
