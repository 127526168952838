import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMetadata } from "../../../contexts/MetadataContext";
import { useDropdowns } from "../../../contexts/DropdownsContext";
import { FormColumn2Wide, Input, Paper, Select, ValueOpt } from "best-common-react";
import { City, Country, CountryIds, District, Sector } from "../../../types/Metadata";
import StateSelectOrTextInput from "../fields/StateSelectOrTextInput";
import CitySelectOrTextInput from "../fields/CitySelectOrTextInput";
import { checkZipcode, IsValidEndAdorner } from "../../../util/ProspectProfileValidationUtil";
import { useProspectProfile } from "../../../contexts/ProsepctProfileContext";
import { REQUIRED_ZIP_CODE_COUNTRIES } from "../../../constants/ProfileConstants";

const ProspectAddressSection: React.FC = () => {
  const { t } = useTranslation(["profile", "translation"]);
  const { profile, updateProfileField, updateMultipleProfileFields } = useProspectProfile();
  const { countries } = useMetadata();
  const {
    countryOptions,
    getCountryOptions,
    stateOptions,
    getStateOptions,
    provinceOptions,
    getProvinceOptions,
    retrieveDistrictsByCountryId,
    getDistrictOptions,
    retrieveCitiesByStateProvinceDistrictId,
    retrieveSectorsByCityId,
    getSectorOptions,
  } = useDropdowns();
  const [districtOptions, setDistrictOptions] = useState<ValueOpt<District>[]>();
  const [citiesOptions, setCitiesOptions] = useState<ValueOpt<City>[]>();
  const [sectorOptions, setSectorOptions] = useState<ValueOpt<Sector>[]>();
  const [zipCodeRequired, setZipCodeRequired] = useState(false);

  const {
    registrationCountryId,
    addressCountryId,
    addressStateProvinceId,
    addressStateProvince,
    addressSectorId,
    addressCityId,
    addressCity,
    address1,
    address2,
    address3,
    addressZipCode,
  } = profile;

  const onCountryChange = (countryId: number) => {
    // Only update if country has changed
    if (addressCountryId != countryId) {
      updateMultipleProfileFields({
        addressCountryId: countryId,
        addressStateProvinceId: null,
        addressStateProvince: null,
        addressCityId: null,
        addressCity: null,
        addressSectorId: null,
        address1: null,
        address2: null,
        address3: null,
        addressZipCode: null,
      });
    }
  };

  useEffect(() => {
    if (!!registrationCountryId && !addressCountryId) {
      updateProfileField("addressCountryId", registrationCountryId);
    }
  }, [registrationCountryId]);

  useEffect(() => {
    // Populate district options if country is not USA or Canada
    if (!!addressCountryId && addressCountryId != CountryIds.USA && addressCountryId != CountryIds.CANADA) {
      retrieveDistrictsByCountryId(addressCountryId).then((result) => {
        setDistrictOptions(result);
      });
    } else {
      setDistrictOptions([]);
    }
    setZipCodeRequired(!!addressCountryId && REQUIRED_ZIP_CODE_COUNTRIES.includes(addressCountryId));
  }, [addressCountryId]);

  useEffect(() => {
    const country = countries.find((c) => c.countryId === addressCountryId);
    if (!!country?.useDropdownFlag && addressStateProvinceId != undefined) {
      retrieveCitiesByStateProvinceDistrictId(addressStateProvinceId).then((result) => {
        setCitiesOptions(result);
      });
    } else {
      setCitiesOptions([]);
    }
  }, [addressStateProvinceId]);

  useEffect(() => {
    if (addressCityId != undefined) {
      retrieveSectorsByCityId(addressCityId).then((result) => {
        setSectorOptions(result);
      });
    } else {
      setSectorOptions([]);
    }
  }, [addressCityId]);

  return (
    <div>
      <Paper className="mb-3">{t("profile:prospectAddressSection")}</Paper>
      <div className="row">
        <FormColumn2Wide className="mb-2">
          <Select
            id="country"
            label={t("profile:country")}
            placeholder={t("translation:selectPlaceholder")}
            className="pb-2"
            value={getCountryOptions(addressCountryId)}
            options={countryOptions}
            onChange={(selected: ValueOpt<Country>) => {
              onCountryChange(selected.value.countryId);
            }}
            required
          />
          <div className="pb-2">
            {addressCountryId === CountryIds.USA && (
              <StateSelectOrTextInput
                id="state"
                label={t("profile:stateTerritory")}
                idField="stateId"
                idValue={addressStateProvinceId}
                textValue={addressStateProvince}
                options={stateOptions}
                getOptionValue={getStateOptions}
                onChange={(idValue, textValue) => {
                  if (idValue !== addressStateProvinceId || textValue !== addressStateProvince) {
                    updateMultipleProfileFields({
                      addressStateProvinceId: idValue,
                      addressStateProvince: textValue,
                      addressCityId: null,
                      addressCity: null,
                    });
                  }
                }}
                disabled={!addressCountryId}
                required={!!addressCountryId}
              />
            )}
            {addressCountryId === CountryIds.CANADA && (
              <StateSelectOrTextInput
                id="province"
                label={t("profile:stateTerritory")}
                idField="provinceId"
                idValue={addressStateProvinceId}
                textValue={addressStateProvince}
                options={provinceOptions}
                getOptionValue={getProvinceOptions}
                onChange={(idValue, textValue) => {
                  if (idValue !== addressStateProvinceId || textValue !== addressStateProvince) {
                    updateMultipleProfileFields({
                      addressStateProvinceId: idValue,
                      addressStateProvince: textValue,
                      addressCityId: null,
                      addressCity: null,
                    });
                  }
                }}
                disabled={!addressCountryId}
                required={!!addressCountryId}
              />
            )}
            {addressCountryId !== CountryIds.USA && addressCountryId !== CountryIds.CANADA && (
              <StateSelectOrTextInput
                id="district"
                label={t("profile:stateTerritory")}
                idField="districtId"
                idValue={addressStateProvinceId}
                textValue={addressStateProvince}
                options={districtOptions}
                getOptionValue={(value) => getDistrictOptions(districtOptions, value)}
                onChange={(idValue, textValue) => {
                  if (idValue !== addressStateProvinceId || textValue !== addressStateProvince) {
                    updateMultipleProfileFields({
                      addressStateProvinceId: idValue,
                      addressStateProvince: textValue,
                      addressCityId: null,
                      addressCity: null,
                    });
                  }
                }}
                disabled={!addressCountryId}
                required={!!addressCountryId}
              />
            )}
          </div>
          <div className="pb-2">
            <CitySelectOrTextInput
              id="city"
              label={t("profile:city")}
              cityId={addressCityId}
              city={addressCity}
              options={citiesOptions}
              onChange={(cityId, city) => {
                if (cityId !== addressCityId || city !== addressCity) {
                  updateMultipleProfileFields({
                    addressCityId: cityId,
                    addressCity: city,
                    addressSectorId: null,
                  });
                }
              }}
              disabled={!addressStateProvince && !addressStateProvinceId}
              required={!!addressStateProvince || !!addressStateProvinceId}
            />
          </div>
          {!!sectorOptions?.length && (
            <Select
              id="sector"
              className="pb-2"
              placeholder={t("translation:selectPlaceholder")}
              label={t("profile:sector")}
              value={getSectorOptions(sectorOptions, addressSectorId)}
              options={sectorOptions}
              onChange={(option: ValueOpt<Sector> | undefined) => {
                updateProfileField("addressSectorId", option?.value?.sectorId);
              }}
              required
            />
          )}
        </FormColumn2Wide>
        <FormColumn2Wide>
          <div className="pb-2">
            <Input
              id="streetAddress1"
              label={t("profile:streetAddress")}
              value={address1 ? address1 : ""}
              onChange={(value: string) => updateProfileField("address1", value)}
              required={!!addressStateProvince || !!addressStateProvinceId}
              disabled={!addressStateProvince && !addressStateProvinceId}
              maxLength={75}
            />
            <Input
              id="streetAddress2"
              value={address2 ? address2 : ""}
              onChange={(value: string) => updateProfileField("address2", value)}
              disabled={!address1}
              maxLength={75}
            />
            <Input
              id="streetAddress3"
              value={address3 ? address3 : ""}
              onChange={(value: string) => updateProfileField("address3", value)}
              disabled={!address2}
              maxLength={75}
            />
          </div>
          {zipCodeRequired && (
            <Input
              id="zipCode"
              label={t("profile:zipCode")}
              value={addressZipCode ? addressZipCode : ""}
              onChange={(value: string) => updateProfileField("addressZipCode", value)}
              endAdorner={
                !!addressZipCode && (
                  <IsValidEndAdorner
                    valid={checkZipcode(addressZipCode, addressCountryId)}
                    className={
                      checkZipcode(addressZipCode, addressCountryId) ? "fa fa-check-circle" : "fa fa-exclamation-circle"
                    }
                  />
                )
              }
              disabled={!addressStateProvince && !addressStateProvinceId}
              required={!!addressStateProvince || !!addressStateProvinceId}
            />
          )}
        </FormColumn2Wide>
      </div>
    </div>
  );
};

export default ProspectAddressSection;
