import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { AlertConstants, Button, useAlerts, useLoading } from "best-common-react";
import { RouteConstants } from "../../constants/RouteConstants";
import { ProspectProfileSection, useProspectProfile } from "../../contexts/ProsepctProfileContext";
import BaseballInformationSection from "./sections/BaseballInformationSection";
import IPLContentContainer from "../display/IPLContentContainer";
import NameSection from "./sections/NameSection";
import TrainerInformationSection from "./sections/TrainerInformationSection";
import BioInformationSection from "./sections/BioInformationSection";
import ContactInformationSection from "./sections/ContactInformationSection";
import NationalIdSection from "./sections/NationalIdSection";
import ProspectAddressSection from "./sections/ProspectAddressSection";
import { completeProspectProfile } from "../../api/AuthenticatedClient";
import { useAuthentication } from "../../contexts/AuthenticationContext";
import { ProspectStatusIds } from "../../constants/ProspectConstants";
import IplContentButtons from "../display/IplContentButtons";
import { useTranslation } from "react-i18next";
import ProspectRelativesSection from "./sections/ProspectRelativesSection";

const FieldsContainer = styled.div`
  min-height: 20rem;
`;

type ProspectEditableProfileProps = {
  setShowCompleteModal: (value: boolean) => void;
  containerRef: React.RefObject<HTMLDivElement>;
};

const ProspectEditableProfile: React.FC<ProspectEditableProfileProps> = ({ setShowCompleteModal, containerRef }) => {
  const { t } = useTranslation("profile");
  const navigate = useNavigate();
  const { setRegistrationStatusId } = useAuthentication();
  const { hasChanged, canSave, section, previousSection, nextSection, saveProfile } = useProspectProfile();
  const { loading, setLoading } = useLoading();
  const { addAlert } = useAlerts();

  const onClose = () => {
    navigate(RouteConstants.BASE);
  };

  const onBack = () => {
    previousSection();
  };

  const onSaveAndClose = async () => {
    if (hasChanged) {
      setLoading(true);
      await saveProfile();
      setLoading(false);
    }
    onClose();
  };

  const onSaveAndContinue = async () => {
    try {
      setLoading(true);
      await saveProfile();
      nextSection();
      containerRef.current.scrollTo({ top: 0, behavior: "smooth" });
    } catch (e) {
      console.error(e);
      addAlert({
        type: AlertConstants.TYPES.DANGER,
        text: "Error saving profile.",
      });
    } finally {
      setLoading(false);
    }
  };

  const onSaveAndSubmit = async () => {
    try {
      setLoading(true);
      await saveProfile();
      await completeProspectProfile();
      setRegistrationStatusId(ProspectStatusIds.PROFILE_COMPLETE);
      setShowCompleteModal(true);
    } catch (e) {
      console.error(e);
      addAlert({
        type: AlertConstants.TYPES.DANGER,
        text: "Error saving & submitting profile.",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <IPLContentContainer>
      <FieldsContainer>
        {section === ProspectProfileSection.NAME && <NameSection />}
        {section === ProspectProfileSection.BIO && <BioInformationSection />}
        {section === ProspectProfileSection.BASEBALL && <BaseballInformationSection />}
        {section === ProspectProfileSection.ADDRESS && <ProspectAddressSection />}
        {section === ProspectProfileSection.CONTACT && <ContactInformationSection />}
        {section === ProspectProfileSection.RELATIVES && <ProspectRelativesSection />}
        {section === ProspectProfileSection.TRAINER && <TrainerInformationSection />}
        {section === ProspectProfileSection.NATIONAL_ID && <NationalIdSection />}
      </FieldsContainer>
      <IplContentButtons>
        {section != 0 && (
          <Button variant="secondary" className="w-100" size="lg" onClick={onBack}>
            {t("profile:back")}
          </Button>
        )}
        <Button variant="secondary" className="w-100" size="lg" onClick={onSaveAndClose}>
          {t("profile:saveAndClose")}
        </Button>
        {section != ProspectProfileSection.NATIONAL_ID ? (
          <Button className="w-100" variant="primary" size="lg" disabled={!canSave} onClick={onSaveAndContinue}>
            {t("profile:saveAndContinue")}
          </Button>
        ) : (
          <Button
            className="w-100"
            variant="primary"
            size="lg"
            disabled={!canSave || loading}
            loading={loading}
            onClick={onSaveAndSubmit}
          >
            {t("profile:saveAndSubmit")}
          </Button>
        )}
      </IplContentButtons>
    </IPLContentContainer>
  );
};

export default ProspectEditableProfile;
