import { Input, Select, ToggleButtons } from "best-common-react";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { HeightUnits } from "../../../constants/ProfileConstants";
import { useDropdowns } from "../../../contexts/DropdownsContext";

const Container = styled.div`
  display: flex;
  gap: 3px;
`;

const SplitDropdownContainer = styled.div`
  flex-basis: 40%;
`;

const DropdownContainer = styled.div`
  flex-basis: 80%;
`;

const ToggleContainer = styled.div`
  flex-basis: 20%;
  line-height: 4.55;
  height: 38px;
`;

type ProspectHeightInputProps = {
  height?: number;
  onHeightChange: (height: number) => void;
  heightUnit?: number;
  onHeightUnitChange: (unit: number) => void;
  readOnly?: boolean;
  disabled?: boolean;
};

const ProspectHeightInput: React.FC<ProspectHeightInputProps> = ({
  height,
  onHeightChange,
  heightUnit = 2,
  onHeightUnitChange,
  readOnly,
  disabled,
}) => {
  const {
    heightCmOptions,
    getHeightCmOption,
    heightFtOptions,
    getHeightFtOption,
    heightInOptions,
    getHeightInOption,
    heightUnitOptions,
  } = useDropdowns();
  const { t } = useTranslation(["profile", "translation"]);

  const feet = !!height ? Math.floor(height / 12) : undefined;
  const inches = !!height ? height % 12 : undefined;

  const onFeetOrInchesChange = (feet = 0, inches = 0) => {
    onHeightChange(feet * 12 + inches);
  };

  if (readOnly) {
    return (
      <Input
        id="height"
        label={heightUnit === HeightUnits.CM ? t("profile:heightCm") : t("profile:heightFtIn")}
        value={heightUnit === HeightUnits.CM ? `${height}cm` : `${feet}'${inches}"`}
        onChange={() => {
          // Unused
        }}
        readOnly
      />
    );
  }

  return (
    <Container>
      {heightUnit === HeightUnits.CM ? (
        <DropdownContainer>
          <Select
            id="cm-select"
            label={t("profile:heightCm")}
            placeholder={t("translation:selectPlaceholder")}
            required
            value={getHeightCmOption(height)}
            options={heightCmOptions}
            onChange={(selected) => {
              onHeightChange(selected?.value);
            }}
            disabled={disabled}
          />
        </DropdownContainer>
      ) : (
        <>
          <SplitDropdownContainer>
            <Select
              id="ft-select"
              label={t("profile:heightFt")}
              placeholder="Ft"
              required
              value={getHeightFtOption(feet)}
              options={heightFtOptions}
              onChange={(selected) => {
                onFeetOrInchesChange(selected.value, inches);
              }}
              disabled={disabled}
            />
          </SplitDropdownContainer>
          <SplitDropdownContainer>
            <Select
              id="in-select"
              label={t("profile:heightIn")}
              placeholder="In"
              required
              value={getHeightInOption(inches)}
              options={heightInOptions}
              onChange={(selected) => {
                onFeetOrInchesChange(feet, selected.value);
              }}
              disabled={disabled}
            />
          </SplitDropdownContainer>
        </>
      )}
      <ToggleContainer>
        <ToggleButtons
          id="heightUnits"
          options={heightUnitOptions}
          value={heightUnit}
          onChange={onHeightUnitChange}
          required
          gutterBottom={false}
          styles={{
            buttonLineHeight: "0",
            height: "38px",
            padding: "3px",
          }}
          readOnly={readOnly}
          disabled={disabled}
        />
      </ToggleContainer>
    </Container>
  );
};

export default ProspectHeightInput;
