import { Button, Modal, StickyFooterButtons } from "best-common-react";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { TrainerDTO } from "../../../../types/Trainer";
import { useMetadata } from "../../../../contexts/MetadataContext";

const TrainersList = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1.2rem;
  gap: 0.5rem;
  overflow-y: auto;
  max-height: 25rem;
`;

const TrainerRow = styled.div`
  background-color: ${(props) => props.theme.colors["white"]};
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 0.5rem;
`;

type MatchedTrainerModalProps = {
  trainers: TrainerDTO[];
  open: boolean;
  onSave: () => Promise<void>;
  onClose: () => void;
  addTrainer: (trainer: TrainerDTO) => Promise<void>;
};

const MatchedTrainerModal: React.FC<MatchedTrainerModalProps> = ({ trainers, open, onSave, onClose, addTrainer }) => {
  const { t } = useTranslation(["translation", "prospect"]);
  const { getCountryDialCode } = useMetadata();

  const associateTrainer = async (trainer: TrainerDTO) => {
    await addTrainer(trainer);
    onClose();
  };

  return (
    <Modal show={open}>
      <Modal.Header>{t("prospect:matchTrainerFound")}</Modal.Header>
      <Modal.Body>
        {t("prospect:matchedTrainers")}
        <TrainersList>
          {trainers.map((trainer) => (
            <TrainerRow key={trainer.trainerId}>
              <div className="w-100">{trainer.firstName + " " + trainer.lastName}</div>
              <div className="w-100">{trainer.nickName}</div>
              {!!trainer.emailAddress?.length && <div className="w-100">{trainer.emailAddress}</div>}
              {!!trainer.phoneNumberCountryId && !!trainer.phoneNumber && (
                <div className="w-100">
                  +{getCountryDialCode(trainer.phoneNumberCountryId)} {trainer.phoneNumber}
                </div>
              )}
              <div>
                <Button className="mt-2" onClick={() => associateTrainer(trainer)} variant="secondary">
                  {t("translation:add")}
                </Button>
              </div>
            </TrainerRow>
          ))}
        </TrainersList>
      </Modal.Body>
      <Modal.Footer justifyContent="flex-end">
        <StickyFooterButtons>
          <Button onClick={onSave} variant="secondary">
            {t("translation:continue")}
          </Button>
          <Button onClick={onClose} variant="secondary">
            {t("translation:close")}
          </Button>
        </StickyFooterButtons>
      </Modal.Footer>
    </Modal>
  );
};

export default MatchedTrainerModal;
