import React from "react";
import { constructPhoneNumber, Icon, RadioLabelValue } from "best-common-react";
import { PhoneNumberDTO } from "../../../../types/Profile";
import { useDropdowns } from "../../../../contexts/DropdownsContext";
import styled from "styled-components";
import { Label, Value } from "../../read-only/ReadOnlyProfileStyles";
import { useTranslation } from "react-i18next";

const Card = styled.div`
  display: flex;
  padding: 0.5rem;
  border: 1px solid ${(props) => props.theme.colors.fog};
  background: ${(props) => props.theme.colors.white};
  justify-content: space-between;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

type ProspectPhoneNumberCardProps = {
  phoneNumber: PhoneNumberDTO;
  onDelete?: () => Promise<void>;
};

const ProspectPhoneNumberCard: React.FC<ProspectPhoneNumberCardProps> = ({ phoneNumber, onDelete }) => {
  const { t } = useTranslation("profile");
  const { phoneNumberCommunicationTypeRadioOptions, phoneNumberTypeRadioOptions } = useDropdowns();

  const getRadioOptionLabel = (options: RadioLabelValue[], id: number): string => {
    return options.find(({ value }) => value === id)?.label as string;
  };

  const formattedPhoneNumber = constructPhoneNumber(phoneNumber.phoneNumber, phoneNumber.countryCode)?.formatNational();
  const communicationType = getRadioOptionLabel(
    phoneNumberCommunicationTypeRadioOptions,
    phoneNumber.phoneNumberCommunicationTypeId,
  );
  const type = getRadioOptionLabel(phoneNumberTypeRadioOptions, phoneNumber.phoneNumberTypeId);

  return (
    <Card>
      <Column>
        <div>
          <Label>{t("profile:phoneNumber")}</Label>
          <Value>
            +{phoneNumber.dialCode} {formattedPhoneNumber}
          </Value>
        </div>

        <div>
          <Label>{t("profile:phoneNumberType")}</Label>
          <Value>
            {type} ({communicationType})
          </Value>
        </div>
      </Column>
      {!!onDelete && (
        <Column>
          <Icon iconName="fa-trash-alt" onClick={onDelete} />
        </Column>
      )}
    </Card>
  );
};

export default ProspectPhoneNumberCard;
