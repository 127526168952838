import React from "react";
import styled from "styled-components";

const Icon = styled.i`
  font-size: 2.5rem !important;
`;

const ConsentFormIcon: React.FC = () => {
  return <Icon className="fas fa-file-signature" />;
};

export default ConsentFormIcon;
