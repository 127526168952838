import React, { ReactNode } from "react";
import styled from "styled-components";
import { useBreakpoints } from "best-common-react";

type MobileProps = {
  isMobile: boolean;
};

const Container = styled.div`
  width: 100%;
  height: 75px;
  display: flex;
  align-items: center;
  background: ${(props) => props.theme.colors.white};
  padding: 1rem;
  gap: 1rem;
  border-bottom: 3px solid ${(props) => props.theme.colors["mlb-navy"]};
  overflow: hidden;
  white-space: nowrap;
`;

const TitleContainer = styled.div<MobileProps>`
  display: flex;
  flex-direction: ${(props) => (props.isMobile ? "column" : "row")};
  ${(props) => !props.isMobile && "gap: 0.5rem;"};
  ${(props) => !props.isMobile && "align-items: center;"};
`;

const Title = styled.div<MobileProps>`
  max-width: 100%;
  font-size: ${(props) => (props.isMobile ? "1.25rem" : "1.5rem")};
`;

type HeaderProps = {
  icon?: ReactNode;
  title: string;
  subTitle?: React.ReactNode;
};

const Header: React.FC<HeaderProps> = ({ icon, title, subTitle }) => {
  const { isMobile } = useBreakpoints();

  return (
    <Container>
      {!!icon ? icon : null}
      <TitleContainer isMobile={isMobile}>
        <Title isMobile={isMobile}>{title}</Title>
        <div>{subTitle}</div>
      </TitleContainer>
    </Container>
  );
};

export default Header;
