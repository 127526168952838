import styled from "styled-components";

export const IconsContainer = styled.i`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

export const CompleteModalCheckIcon = styled.i.attrs(() => ({
  className: "fa fa-check-circle",
}))`
  font-size: 1rem !important;
  color: ${(props) => props.theme.colors.green};
`;

export const EmptyIcon = styled.div`
  width: 1rem;
`;

export const CompleteModalContainer = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  padding: 2rem;
  background-color: ${(props) => props.theme.colors["mlb-navy"]};
  color: white !important;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  font-weight: 300;
`;

export const CompleteModalHeader = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  font-size: 1.5rem;
`;

export const CompleteModalBody = styled.div`
  max-width: 768px;
  width: 100%;
  height: 75%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
`;

export const NextStepContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
`;

export const HeaderText = styled.div`
  font-size: 2rem;
  line-height: 2rem;
`;
