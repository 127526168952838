import { EnvironmentType, NavigationConfigType, NavigationV2 } from "best-common-react";
import React from "react";
import dplLogo from "../../assets/prospect-link-logo.png";
import { RouteConstants } from "../../constants/RouteConstants";
import { useAuthentication } from "../../contexts/AuthenticationContext";
import { useTranslation } from "react-i18next";

const env = window.MLBBest.envVariables.ENV as EnvironmentType;

const Navigation: React.FC = () => {
  const { t, i18n } = useTranslation("navigation");
  const { authenticated, userDetails, logout } = useAuthentication();
  const isEs = i18n.language === "es";
  const toggleLanguage = async () => {
    if (isEs) {
      document.documentElement.lang = "en";
      return i18n.changeLanguage("en");
    } else {
      document.documentElement.lang = "es";
      return i18n.changeLanguage("es");
    }
  };

  const config: NavigationConfigType = {
    env: env,
    builds: [],
    title: "IPL",
    displayEnvIndicator: true,
    loggedIn: authenticated,
    endLogo: {
      logo: dplLogo,
      visible: true,
    },
    username: `${userDetails?.firstName} ${userDetails?.lastName}`,
    tabletSupport: false,
    usernameDropdownLinks: [
      {
        name: t("navigation:signOut"),
        onClick: logout,
      },
      {
        name: t("navigation:changeLanguage.name"),
        onClick: toggleLanguage,
      },
    ],
    navigationLinks: [
      {
        name: t("navigation:profile"),
        icon: "fa-user-circle",
        to: RouteConstants.BASE,
        quickLink: true,
        displayName: false,
      },
    ],
  };

  return <NavigationV2 config={config} />;
};

export default Navigation;
