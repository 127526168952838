import { getLocalStorageItem, isBefore, setLocalStorageItem } from "best-common-react";
import React, { Context, createContext, ReactNode, useContext, useEffect, useState } from "react";
import {
  getBattingSide,
  getCacheRefresh,
  getCommunicationTypes,
  getCountries,
  getEmailAddressTypes,
  getHeightUnitsOfMeasurement,
  getIntlProfessionalLeagues,
  getIntlProfessionalTeams,
  getMexicanLeagueTeams,
  getNationalIDTypes,
  getPhoneNumberCommunicationTypes,
  getPhoneNumberTypes,
  getPositions,
  getProvinces,
  getStates,
  getThrowingHands,
  getWeightUnitsOfMeasurement,
} from "../api/UnauthenticatedClient";
import { LocalStorageConstants } from "../constants/LocalStorageConstants";
import {
  BattingSide,
  CacheTimeDTO,
  CommunicationType,
  Country,
  CountryDialCode,
  EmailAddressType,
  IntlProfessionalLeague,
  IntlProfessionalTeam,
  MexicanLeagueTeam,
  NationalIDType,
  PhoneNumberCommunicationType,
  PhoneNumberType,
  Position,
  Province,
  State,
  ThrowingHand,
  UnitOfMeasurement,
} from "../types/Metadata";
import { CountryCode as LibCountryCode } from "libphonenumber-js/types";

type MetadataContextType = {
  positions: Position[];
  nationalIDTypes: NationalIDType[];
  countries: Country[];
  getCountryDialCode: (countryId: number) => LibCountryCode;
  dialCodes: CountryDialCode[];
  states: State[];
  provinces: Province[];
  battingSides: BattingSide[];
  throwingHands: ThrowingHand[];
  phoneNumberTypes: PhoneNumberType[];
  phoneNumberCommunicationTypes: PhoneNumberCommunicationType[];
  emailAddressTypes: EmailAddressType[];
  heightUnits: UnitOfMeasurement[];
  weightUnits: UnitOfMeasurement[];
  mexicanLeagueTeams: MexicanLeagueTeam[];
  communicationTypes: CommunicationType[];
  intlProfessionalLeagues: IntlProfessionalLeague[];
  intlProfessionalTeams: IntlProfessionalTeam[];
};

const MetadataContext: Context<MetadataContextType | undefined> = createContext<MetadataContextType | undefined>(
  undefined,
);

type MetadataProviderProps = {
  children?: ReactNode;
};

const MetadataProvider: React.FC<MetadataProviderProps> = ({ children }) => {
  const [refresh, setRefresh] = useState<boolean>(true);
  const [positions, setPositions] = useState<Position[]>([]);
  const [nationalIDTypes, setNationalIDTypes] = useState<NationalIDType[]>([]);
  const [countries, setCountries] = useState<Country[]>([]);
  const [dialCodes, setDialCodes] = useState<CountryDialCode[]>([]);
  const [states, setStates] = useState<State[]>([]);
  const [provinces, setProvinces] = useState<Province[]>([]);
  const [battingSides, setBattingSides] = useState<BattingSide[]>([]);
  const [throwingHands, setThrowingHands] = useState<ThrowingHand[]>([]);
  const [phoneNumberTypes, setPhoneNumberTypes] = useState<PhoneNumberType[]>([]);
  const [phoneNumberCommunicationTypes, setPhoneNumberCommunicationTypes] = useState<PhoneNumberCommunicationType[]>(
    [],
  );
  const [emailAddressTypes, setEmailAddressTypes] = useState<EmailAddressType[]>([]);
  const [heightUnits, setHeightUnits] = useState<UnitOfMeasurement[]>([]);
  const [weightUnits, setWeightUnits] = useState<UnitOfMeasurement[]>([]);
  const [mexicanLeagueTeams, setMexicanLeagueTeams] = useState<MexicanLeagueTeam[]>([]);
  const [communicationTypes, setCommunicationTypes] = useState<CommunicationType[]>([]);
  const [intlProfessionalLeagues, setIntlProfessionalLeagues] = useState<IntlProfessionalLeague[]>([]);
  const [intlProfessionalTeams, setIntlProfessionalTeams] = useState<IntlProfessionalTeam[]>([]);

  const getCacheRefreshTiming = async () => {
    try {
      const result: CacheTimeDTO = await getCacheRefresh();
      const lastCacheTime: string = getLocalStorageItem(LocalStorageConstants.LAST_CACHE_TIME) as string;
      const refresh = !lastCacheTime || isBefore(new Date(lastCacheTime), new Date(result.cacheTime));
      if (refresh) {
        setLocalStorageItem(LocalStorageConstants.LAST_CACHE_TIME, result.cacheTime);
        setRefresh(true);
      }
    } catch (e) {
      console.error(e);
      //if cant get timing do a refresh
      setRefresh(true);
    }
  };

  const createCountryDialCodes = (countries: Country[]) => {
    const tempCountryDialCodes: CountryDialCode[] = countries.map((country: Country) => ({
      countryId: country.countryId,
      country: `${country.description} (+${country.dialCode})`,
      countryCode: country.countryCode,
      dialCode: country.dialCode,
    }));
    setDialCodes(tempCountryDialCodes);
  };

  const getCountryDialCode = (countryId: number): LibCountryCode => {
    return countries.find((country) => country.countryId === countryId)?.dialCode as LibCountryCode;
  };

  const fetchMexicanLeagueTeams = async (refresh: boolean) => {
    try {
      const localStorage: MexicanLeagueTeam[] = getLocalStorageItem(
        LocalStorageConstants.MEXICAN_LEAGUE_TEAMS,
      ) as MexicanLeagueTeam[];
      if (localStorage !== null && !!localStorage && !refresh) {
        setMexicanLeagueTeams(localStorage);
      } else {
        const result: MexicanLeagueTeam[] = await getMexicanLeagueTeams();
        setMexicanLeagueTeams(result);
        setLocalStorageItem(LocalStorageConstants.MEXICAN_LEAGUE_TEAMS, result);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const fetchStates = async (refresh: boolean) => {
    try {
      const localStorage: State[] = getLocalStorageItem(LocalStorageConstants.STATES) as State[];
      if (localStorage !== null && !!localStorage && !refresh) {
        setStates(localStorage);
      } else {
        const result: State[] = await getStates();
        setStates(result);
        setLocalStorageItem(LocalStorageConstants.STATES, result);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const fetchProvinces = async (refresh: boolean) => {
    try {
      const localStorage: Province[] = getLocalStorageItem(LocalStorageConstants.PROVINCES) as Province[];
      if (localStorage !== null && !!localStorage && !refresh) {
        setProvinces(localStorage);
      } else {
        const result: Province[] = await getProvinces();
        setProvinces(result);
        setLocalStorageItem(LocalStorageConstants.PROVINCES, result);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const fetchCountries = async (refresh: boolean) => {
    try {
      const localStorage: Country[] = getLocalStorageItem(LocalStorageConstants.COUNTRIES) as Country[];
      if (localStorage !== null && !!localStorage && !refresh) {
        setCountries(localStorage);
        createCountryDialCodes(localStorage);
      } else {
        const result: Country[] = await getCountries();
        setCountries(result);
        createCountryDialCodes(result);
        setLocalStorageItem(LocalStorageConstants.COUNTRIES, result);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const fetchPositions = async (refresh: boolean) => {
    try {
      const localStorage: Position[] = getLocalStorageItem(LocalStorageConstants.POSITIONS) as Position[];
      if (!!localStorage && !refresh) {
        setPositions(localStorage);
      } else {
        const result: Position[] = await getPositions();
        setPositions(result);
        setLocalStorageItem(LocalStorageConstants.POSITIONS, result);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const fetchNationalIDTypes = async (refresh: boolean) => {
    try {
      const localStorage: NationalIDType[] = getLocalStorageItem(
        LocalStorageConstants.NATIONALIDTYPES,
      ) as NationalIDType[];
      if (!!localStorage && !refresh) {
        setNationalIDTypes(localStorage);
      } else {
        const result: NationalIDType[] = await getNationalIDTypes();
        setNationalIDTypes(result);
        setLocalStorageItem(LocalStorageConstants.NATIONALIDTYPES, result);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const fetchThrowingHands = async (refresh: boolean) => {
    try {
      const localStorage: ThrowingHand[] = getLocalStorageItem(LocalStorageConstants.THROWING_HANDS) as ThrowingHand[];
      if (!!localStorage && !refresh) {
        setThrowingHands(localStorage);
      } else {
        const result: ThrowingHand[] = await getThrowingHands();
        setThrowingHands(result);
        setLocalStorageItem(LocalStorageConstants.THROWING_HANDS, result);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const fetchCommunicationTypes = async (refresh: boolean) => {
    try {
      const localStorage: CommunicationType[] = getLocalStorageItem(
        LocalStorageConstants.COMMUNICATION_TYPES,
      ) as CommunicationType[];
      if (!!localStorage && !refresh) {
        setCommunicationTypes(localStorage);
      } else {
        const result: CommunicationType[] = await getCommunicationTypes();
        setCommunicationTypes(result);
        setLocalStorageItem(LocalStorageConstants.COMMUNICATION_TYPES, result);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const fetchBattingSides = async (refresh: boolean) => {
    try {
      const localStorage: BattingSide[] = getLocalStorageItem(LocalStorageConstants.BATTING_SIDE) as BattingSide[];
      if (!!localStorage && !refresh) {
        setBattingSides(localStorage);
      } else {
        const result: BattingSide[] = await getBattingSide();
        setBattingSides(result);
        setLocalStorageItem(LocalStorageConstants.BATTING_SIDE, result);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const fetchPhoneNumberTypes = async (refresh: boolean) => {
    try {
      const localStorage: PhoneNumberType[] = getLocalStorageItem(
        LocalStorageConstants.PHONE_NUM_TYPES,
      ) as PhoneNumberType[];
      if (!!localStorage && !refresh) {
        setPhoneNumberTypes(localStorage);
      } else {
        const result: PhoneNumberType[] = await getPhoneNumberTypes();
        setPhoneNumberTypes(result);
        setLocalStorageItem(LocalStorageConstants.PHONE_NUM_TYPES, result);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const fetchPhoneNumberCommunicationTypes = async (refresh: boolean) => {
    try {
      const localStorage: PhoneNumberCommunicationType[] = getLocalStorageItem(
        LocalStorageConstants.PHONE_NUM_COMMUNICATION_TYPES,
      ) as PhoneNumberCommunicationType[];
      if (!!localStorage && !refresh) {
        setPhoneNumberCommunicationTypes(localStorage);
      } else {
        const result: PhoneNumberCommunicationType[] = await getPhoneNumberCommunicationTypes();
        setPhoneNumberCommunicationTypes(result);
        setLocalStorageItem(LocalStorageConstants.PHONE_NUM_COMMUNICATION_TYPES, result);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const fetchEmailAddressTypes = async (refresh: boolean) => {
    try {
      const localStorage: EmailAddressType[] = getLocalStorageItem(
        LocalStorageConstants.EMAIL_TYPES,
      ) as EmailAddressType[];
      if (!!localStorage && !refresh) {
        setEmailAddressTypes(localStorage);
      } else {
        const result: EmailAddressType[] = await getEmailAddressTypes();
        setEmailAddressTypes(result);
        setLocalStorageItem(LocalStorageConstants.EMAIL_TYPES, result);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const fetchHeightUnitsOfMeasurement = async (refresh: boolean) => {
    try {
      const localStorage: UnitOfMeasurement[] = getLocalStorageItem(
        LocalStorageConstants.HEIGHT_UNITS,
      ) as UnitOfMeasurement[];
      if (!!localStorage && !refresh) {
        setHeightUnits(localStorage);
      } else {
        const result: UnitOfMeasurement[] = await getHeightUnitsOfMeasurement();
        setHeightUnits(result);
        setLocalStorageItem(LocalStorageConstants.HEIGHT_UNITS, result);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const fetchWeightUnitsOfMeasurement = async (refresh: boolean) => {
    try {
      const localStorage: UnitOfMeasurement[] = getLocalStorageItem(
        LocalStorageConstants.WEIGHT_UNITS,
      ) as UnitOfMeasurement[];
      if (!!localStorage && !refresh) {
        setWeightUnits(localStorage);
      } else {
        const result: UnitOfMeasurement[] = await getWeightUnitsOfMeasurement();
        setWeightUnits(result);
        setLocalStorageItem(LocalStorageConstants.WEIGHT_UNITS, result);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const fetchIntlProfessionalLeagues = async (refresh: boolean) => {
    try {
      const localStorage: IntlProfessionalLeague[] = getLocalStorageItem(
        LocalStorageConstants.INTL_PROFESSIONAL_LEAGUES,
      ) as IntlProfessionalLeague[];
      if (!!localStorage && !refresh) {
        setIntlProfessionalLeagues(localStorage);
      } else {
        const result: IntlProfessionalLeague[] = await getIntlProfessionalLeagues();
        setIntlProfessionalLeagues(result);
        setLocalStorageItem(LocalStorageConstants.INTL_PROFESSIONAL_LEAGUES, result);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const fetchIntlProfessionalTeams = async (refresh: boolean) => {
    try {
      const localStorage: IntlProfessionalTeam[] = getLocalStorageItem(
        LocalStorageConstants.INTL_PROFESSIONAL_TEAMS,
      ) as IntlProfessionalTeam[];
      if (!!localStorage && !refresh) {
        setIntlProfessionalTeams(localStorage);
      } else {
        const result: IntlProfessionalTeam[] = await getIntlProfessionalTeams();
        setIntlProfessionalTeams(result);
        setLocalStorageItem(LocalStorageConstants.INTL_PROFESSIONAL_TEAMS, result);
      }
    } catch (e) {
      console.error(e);
    }
  };

  //todo: figure out proper force refresh of cache - requestix uses a table with timestamp we compare
  useEffect(() => {
    fetchPositions(refresh);
    fetchNationalIDTypes(refresh);
    fetchCountries(refresh);
    fetchStates(refresh);
    fetchProvinces(refresh);
    fetchThrowingHands(refresh);
    fetchBattingSides(refresh);
    fetchPhoneNumberTypes(refresh);
    fetchPhoneNumberCommunicationTypes(refresh);
    fetchEmailAddressTypes(refresh);
    fetchHeightUnitsOfMeasurement(refresh);
    fetchWeightUnitsOfMeasurement(refresh);
    fetchMexicanLeagueTeams(refresh);
    fetchCommunicationTypes(refresh);
    fetchIntlProfessionalLeagues(refresh);
    fetchIntlProfessionalTeams(refresh);
  }, [refresh]);

  useEffect(() => {
    getCacheRefreshTiming();
  }, []);

  return (
    <MetadataContext.Provider
      value={{
        positions,
        nationalIDTypes,
        countries,
        getCountryDialCode,
        dialCodes,
        states,
        provinces,
        battingSides,
        throwingHands,
        phoneNumberTypes,
        phoneNumberCommunicationTypes,
        emailAddressTypes,
        heightUnits,
        weightUnits,
        mexicanLeagueTeams,
        communicationTypes,
        intlProfessionalLeagues,
        intlProfessionalTeams,
      }}
    >
      {children}
    </MetadataContext.Provider>
  );
};

const useMetadata = (): MetadataContextType => {
  const metadataContext: MetadataContextType = useContext<MetadataContextType>(MetadataContext);
  if (metadataContext === undefined) {
    throw new Error(`useMetadata must be used within a MetadataProvider`);
  }
  return metadataContext;
};

export { MetadataContext, MetadataProvider, useMetadata };
