import { Icon, PhoneNumberDisplay, Typography } from "best-common-react";
import React from "react";
import styled from "styled-components";
import { TrainerDTO } from "../../../../types/Trainer";
import { useMetadata } from "../../../../contexts/MetadataContext";

const TrainerDisplayDiv = styled.div`
  display: flex;
  justify-content: space-between;
  border: 1px solid ${(props) => props.theme.colors.fog};
  padding: 0.5rem;
  background: ${(props) => props.theme.colors.white};
  margin-bottom: 0.5rem;
`;

type TrainerDisplayProps = {
  trainer: TrainerDTO;
  onRemove?: () => void;
};

const TrainerDisplay: React.FC<TrainerDisplayProps> = ({ trainer, onRemove }) => {
  const { getCountryDialCode } = useMetadata();

  return (
    <TrainerDisplayDiv>
      <div>
        <div>
          <Typography className="mb-1">
            {trainer.firstName} {trainer.lastName}
          </Typography>
        </div>
        <div>
          <Typography className="mb-1">{trainer.nickName}</Typography>
        </div>
        {!!trainer.phoneNumberCountryId && !!trainer.phoneNumber && (
          <PhoneNumberDisplay
            id="phone-number-display"
            countryCode={getCountryDialCode(trainer.phoneNumberCountryId)}
            phoneNumber={trainer.phoneNumber}
            className="mb-1"
          />
        )}
        {!!trainer.emailAddress?.length && <Typography className="mb-1">{trainer.emailAddress}</Typography>}
      </div>
      {!!onRemove && <Icon iconName="fa-trash-alt" onClick={onRemove} />}
    </TrainerDisplayDiv>
  );
};

export default TrainerDisplay;
