import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useConsentFormEsign } from "../contexts/ConsentFormEsignContext";
import useLanguage from "../hooks/useLanguage";
import { useSearchParams } from "react-router-dom";
import { calcConsentFormSubHeaderText } from "../util/ConsentFormUtil";
import ConsentFormCompleteModal from "../components/completion/ConsentFormCompleteModal";
import Header from "../components/navigation/Header";
import ConsentFormIcon from "../components/icons/ConsentFormIcon";
import GuardianSignerInfoForm from "../components/consent-form/GuardianSignerInfoForm";
import ConsentFormSigners from "../components/consent-form/ConsentFormSigners";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

const Body = styled.div`
  padding: 1rem;
  width: 100%;
`;

type SubHeaderProps = {
  isComplete: boolean;
};

const SubHeader = styled.div<SubHeaderProps>`
  font-size: 1rem;
  color: ${(props) => (props.isComplete ? props.theme.colors.green : props.theme.colors["mlb-red"])};
`;

const ProspectDefaultConsentForm: React.FC = () => {
  const { t } = useTranslation("consent-form");
  const { loaded, showEditGuardians, guardianSigners, prospectConsentForm } = useConsentFormEsign();
  const { isEn } = useLanguage();
  const [searchParams] = useSearchParams();
  const [subHeaderText, setSubHeaderText] = useState("");
  const [showProspectSignedModal, setShowProspectSignedModal] = useState(false);

  useEffect(() => {
    if (
      !!prospectConsentForm &&
      (!prospectConsentForm.guardianSignaturesRequired || (!!guardianSigners.guardian1 && !!guardianSigners.guardian2))
    ) {
      let requiredSignatures = 1;
      let completedSignatures = !!prospectConsentForm.prospectSignedTs ? 1 : 0;
      if (prospectConsentForm.guardianSignaturesRequired) {
        const { guardian1, guardian2 } = guardianSigners;
        if (guardian1.required) {
          requiredSignatures = requiredSignatures + 1;
        }
        if (!!guardian1.signedTs) {
          completedSignatures = completedSignatures + 1;
        }
        if (guardian2.required) {
          requiredSignatures = requiredSignatures + 1;
        }
        if (!!guardian2.signedTs) {
          completedSignatures = completedSignatures + 1;
        }
      }

      setSubHeaderText(
        calcConsentFormSubHeaderText(
          !!prospectConsentForm.completedTs,
          prospectConsentForm.optional,
          true,
          requiredSignatures,
          completedSignatures,
          isEn,
        ),
      );
    }
  }, [prospectConsentForm, guardianSigners, isEn]);

  useEffect(() => {
    const agreementStatus: string = searchParams.get("agreementStatus");
    if (!!agreementStatus && agreementStatus == "completed") {
      setShowProspectSignedModal(true);
    }
  }, [searchParams]);

  if (showProspectSignedModal) {
    return <ConsentFormCompleteModal />;
  } else if (!loaded) {
    return null;
  } else {
    return (
      <Container>
        <Header
          icon={<ConsentFormIcon />}
          title={t("consent-form:consentForm")}
          subTitle={<SubHeader isComplete={!!prospectConsentForm?.completedTs}>{subHeaderText}</SubHeader>}
        />
        <Body>{showEditGuardians ? <GuardianSignerInfoForm /> : <ConsentFormSigners />}</Body>
      </Container>
    );
  }
};

export default ProspectDefaultConsentForm;
