import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useProspectProfile } from "../../../contexts/ProsepctProfileContext";
import { useDropdowns } from "../../../contexts/DropdownsContext";
import { FormColumn2Wide, Label, NaOpt, Paper, Select, ValueOpt } from "best-common-react";
import { NationalIDType } from "../../../types/Metadata";
import NationalIdInput from "../fields/NationalIdInput";
import { isNationalIdRequired } from "../../../util/ProfileUtils";

const LabelTextContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: start;
`;

const Asterisk = styled.span`
  color: ${(props) => props.theme.colors["mlb-red"]};
`;

const NationalIdSection: React.FC = () => {
  const { t } = useTranslation(["profile", "translation"]);
  const { profile, updateProfileField, updateMultipleProfileFields } = useProspectProfile();

  // National Id Type Options
  const { nationalIDTypeOptions, getNationalIDTypeOptions } = useDropdowns();
  const [options, setOptions] = useState(nationalIDTypeOptions);
  useEffect(() => {
    if (!!profile?.registrationCountryId) {
      setOptions(
        nationalIDTypeOptions.filter(
          (country: ValueOpt<NationalIDType>) => country.value.countryId == profile.registrationCountryId,
        ),
      );
    }
  }, [profile?.registrationCountryId]);

  const handleTypeChange = (selected: ValueOpt<NationalIDType | string>) => {
    updateMultipleProfileFields({
      nationalId: "",
      nationalIdTypeId:
        selected?.value === "N/A" ? -1 : (selected as ValueOpt<NationalIDType>)?.value?.nationalIdTypeId,
    });
  };

  const handleInputChange = (value: string) => {
    updateProfileField("nationalId", value);
  };

  const format = getNationalIDTypeOptions(profile?.nationalIdTypeId)?.value.format;
  const pattern = getNationalIDTypeOptions(profile?.nationalIdTypeId)?.value.pattern;
  const required = isNationalIdRequired(profile);

  return (
    <div>
      <Paper className="mb-3">{t("profile:nationalIdSection")}</Paper>
      <div className="row">
        <FormColumn2Wide>
          <Select
            id="national-id-type"
            label={t("profile:prospectNationalIdType")}
            placeholder={t("translation:selectPlaceholder")}
            value={profile?.nationalIdTypeId !== -1 ? getNationalIDTypeOptions(profile?.nationalIdTypeId) : NaOpt}
            options={options}
            onChange={(option: ValueOpt<NationalIDType | string> | undefined) => handleTypeChange(option)}
            required={required}
            clearable
          />
        </FormColumn2Wide>
        <FormColumn2Wide>
          <Label htmlFor="national-id-value">
            <LabelTextContainer>
              {t("profile:prospectNationalId")} {!!pattern ? `(${pattern})` : ""}
              {required && <Asterisk>*</Asterisk>}
            </LabelTextContainer>
          </Label>
          <NationalIdInput
            id="national-id-input"
            value={profile?.nationalId}
            onChange={handleInputChange}
            typeId={profile?.nationalIdTypeId}
            format={format}
            pattern={pattern}
            disabled={!profile?.nationalIdTypeId}
            required={required}
          />
        </FormColumn2Wide>
      </div>
    </div>
  );
};

export default NationalIdSection;
