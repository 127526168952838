import React, { useEffect, useState } from "react";
import {
  Button,
  Email,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  RadioGroup,
  RadioLabelValue,
} from "best-common-react";
import { EmailAddressDTO } from "../../../../types/Profile";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useAuthentication } from "../../../../contexts/AuthenticationContext";
import { useDropdowns } from "../../../../contexts/DropdownsContext";

const Body = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 350px;
`;

type AddEmailAddressModalProps = {
  open: boolean;
  onSave: (emailAddress: EmailAddressDTO) => Promise<void>;
  onClose: () => void;
};

const AddEmailAddressModal: React.FC<AddEmailAddressModalProps> = ({ open, onSave, onClose }) => {
  const { userDetails } = useAuthentication();
  const { t } = useTranslation(["profile", "translation"]);
  const { emailAddressTypeOptions } = useDropdowns();
  const [emailAddress, setEmailAddress] = useState<EmailAddressDTO>({
    prospectId: userDetails?.prospectId,
    emailAddress: "",
  });
  const [radioOptions, setRadioOptions] = useState<RadioLabelValue[]>([]);
  const [validEmail, setValidEmail] = useState(false);
  const [canSave, setCanSave] = useState(false);

  useEffect(() => {
    setCanSave(!!emailAddress.emailAddress.length && validEmail && !!emailAddress.emailAddressTypeId);
  }, [emailAddress, validEmail]);

  useEffect(() => {
    setRadioOptions(emailAddressTypeOptions.map((opt) => ({ value: opt.value?.emailAddressTypeId, label: opt.label })));
  }, [emailAddressTypeOptions]);

  return (
    <Modal show={open}>
      <ModalHeader>{t("profile:addEmailAddressTitle")}</ModalHeader>
      <ModalBody>
        <Body>
          <FormContainer>
            <Email
              id="prospect-email-address"
              label={t("profile:emailAddress")}
              value={emailAddress.emailAddress}
              className="mb-2"
              required
              setValidEmail={setValidEmail}
              onChange={(value) => {
                setEmailAddress({ ...emailAddress, emailAddress: value });
              }}
              invalidMessage={t("profile:invalidEmail")}
            />
            <RadioGroup
              id="email-address-type"
              name="email-address-type"
              label={t("profile:emailOf")}
              value={emailAddress.emailAddressTypeId}
              required
              vertical
              options={radioOptions.sort((a, b) => (a.value > b.value ? 1 : -1))}
              onChange={(value) => setEmailAddress({ ...emailAddress, emailAddressTypeId: value as number })}
              optionStyles={{ paddingBottom: "6px" }}
            />
          </FormContainer>
        </Body>
      </ModalBody>
      <ModalFooter>
        <Button onClick={() => onSave(emailAddress)} disabled={!canSave} className="me-1" variant="primary">
          {t("translation:continue")}
        </Button>
        <Button onClick={onClose} variant="secondary">
          {t("translation:close")}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AddEmailAddressModal;
