import React, { useEffect, useState } from "react";
import {
  AsSelectPlaceholders,
  DateInput,
  FormColumn,
  FormColumn2Wide,
  Paper,
  RadioGroup,
  Select,
  ValueOpt,
} from "best-common-react";
import { useProspectProfile } from "../../../contexts/ProsepctProfileContext";
import { useDropdowns } from "../../../contexts/DropdownsContext";
import { useTranslation } from "react-i18next";
import { City, Country, CountryIds, District, NationalIDType, Sector } from "../../../types/Metadata";
import { DATE_INPUT_MIN_DATE, dateFormats } from "../../../util/ProfileUtils";
import { parse, format } from "date-fns";
import StateSelectOrTextInput from "../fields/StateSelectOrTextInput";
import CitySelectOrTextInput from "../fields/CitySelectOrTextInput";
import { useMetadata } from "../../../contexts/MetadataContext";
import { USA_TERRITORIES_CANADA_IDS } from "../../../constants/ProfileConstants";
import TraveledToUsCanPrDetails from "./bio-info/TraveledToUsCanPrDetails";
import TravelInfoOnlyWarningModal from "./bio-info/TravelInfoOnlyWarningModal";
import { RadioValue } from "best-common-react/lib/esm/types";

const BioInformationSection: React.FC = () => {
  const { t } = useTranslation(["profile", "translation"]);
  const { countries, nationalIDTypes } = useMetadata();
  const {
    countryOptions,
    getCountryOptions,
    yesNoRadioValues,
    getYesNoRadioValues,
    twinOptions,
    stateOptions,
    getStateOptions,
    provinceOptions,
    getProvinceOptions,
    getDistrictOptions,
    retrieveDistrictsByCountryId,
    retrieveSectorsByCityId,
    retrieveCitiesByStateProvinceDistrictId,
    getSectorOptions,
  } = useDropdowns();
  const { profile, updateProfileField, updateMultipleProfileFields } = useProspectProfile();
  const [districtOptions, setDistrictOptions] = useState<ValueOpt<District>[]>();
  const [citiesOptions, setCitiesOptions] = useState<ValueOpt<City>[]>();
  const [sectorOptions, setSectorOptions] = useState<ValueOpt<Sector>[]>();
  const [showTravelInfoOnlyWarning, setShowTravelInfoOnlyWarning] = useState(false);

  const MONTHS: string[] = t("translation:months");
  const dateDropdownPlaceholders: AsSelectPlaceholders = {
    day: t("translation:day" as const),
    month: t("translation:month" as const),
    year: t("translation:year" as const),
  };

  useEffect(() => {
    // Populate district options if country is not USA or Canada
    if (
      !!profile?.birthCountryId &&
      profile.birthCountryId != CountryIds.USA &&
      profile.birthCountryId != CountryIds.CANADA
    ) {
      retrieveDistrictsByCountryId(profile.birthCountryId).then((result) => {
        setDistrictOptions(result);
      });
    } else {
      setDistrictOptions([]);
    }
  }, [profile?.birthCountryId]);

  useEffect(() => {
    const country = countries.find((c) => c.countryId === profile?.birthCountryId);
    if (!!country?.useDropdownFlag && profile?.birthStateProvinceId) {
      retrieveCitiesByStateProvinceDistrictId(profile?.birthStateProvinceId).then((result) => {
        setCitiesOptions(result);
      });
    } else {
      setCitiesOptions([]);
    }
  }, [profile?.birthStateProvinceId]);

  useEffect(() => {
    if (!!profile?.birthCityId) {
      retrieveSectorsByCityId(profile?.birthCityId).then((result) => {
        setSectorOptions(result);
      });
    } else {
      setSectorOptions([]);
    }
  }, [profile?.birthCityId]);

  useEffect(() => {
    if (profile.traveledToUsCanPrFlag && profile.livesInUsCanPr) {
      setShowTravelInfoOnlyWarning(true);
    }
  }, [profile.traveledToUsCanPrFlag, profile.livesInUsCanPr]);

  const onRegistrationCountry = (option: ValueOpt<Country> | undefined) => {
    if (profile?.registrationCountryId !== option?.value.countryId) {
      let clearTravelDetails = {};
      if (USA_TERRITORIES_CANADA_IDS.includes(option?.value.countryId)) {
        clearTravelDetails = {
          traveledToUsCanPrFlag: false,
          traveledToUsCanPrCount: null,
          longestStayUsCanPr: null,
          recentStayUsPrCan: null,
          livesInUsCanPr: null,
        };
      }

      updateMultipleProfileFields({
        registrationCountryId: option?.value.countryId,
        ...clearTravelDetails,
      });
    }
  };

  const onBirthCountryChange = (option: ValueOpt<Country> | undefined) => {
    if (profile?.birthCountryId !== option.value.countryId) {
      let clearTravelDetails = {};
      if (USA_TERRITORIES_CANADA_IDS.includes(option?.value.countryId)) {
        clearTravelDetails = {
          traveledToUsCanPrFlag: false,
          traveledToUsCanPrCount: null,
          longestStayUsCanPr: null,
          recentStayUsPrCan: null,
          lives: null,
        };
      }
      const nationalIdType: NationalIDType | undefined = nationalIDTypes.find(
        (type: NationalIDType) => type.countryId === option.value?.countryId,
      );
      updateMultipleProfileFields({
        birthCountryId: option?.value.countryId,
        birthStateProvinceId: null,
        birthStateProvince: null,
        birthCityId: null,
        birthCity: null,
        birthSectorId: null,
        nationalIdTypeId: nationalIdType?.nationalIdTypeId,
        ...clearTravelDetails,
      });
    }
  };

  return (
    <>
      <TravelInfoOnlyWarningModal
        show={showTravelInfoOnlyWarning}
        onClose={() => setShowTravelInfoOnlyWarning(false)}
      />
      <div>
        <Paper className="mb-3">{t("profile:bioInformationSection")}</Paper>
        <div className="row pb-2">
          <FormColumn2Wide>
            <Select
              id="registrationCountry"
              label={t("profile:registrationCountry")}
              placeholder={t("translation:selectPlaceholder")}
              className="pb-2"
              value={getCountryOptions(profile?.registrationCountryId)}
              required
              onChange={onRegistrationCountry}
              options={countryOptions}
            />
          </FormColumn2Wide>
          <FormColumn2Wide>
            <DateInput
              id="date-of-birth-input"
              label={t("profile:birthDate")}
              invalidDateMessage={t("translation:invalidDate")}
              asSelects
              asSelectsPlaceholders={dateDropdownPlaceholders}
              monthsValueOptions={MONTHS}
              minDate={DATE_INPUT_MIN_DATE}
              maxDate={new Date()}
              value={
                !!profile?.dateOfBirth ? parse(profile.dateOfBirth, dateFormats.YYYY_MM_DD, new Date()) : undefined
              }
              onChange={(value: Date | undefined) => {
                updateProfileField("dateOfBirth", !!value ? format(value, dateFormats.YYYY_MM_DD) : undefined);
              }}
              required
            />
          </FormColumn2Wide>
        </div>
        <div className="row pt-2">
          <FormColumn2Wide>
            <RadioGroup
              id="has-twin"
              label={t("profile:hasTwin")}
              name="hasTwin"
              className="pb-2"
              required
              options={yesNoRadioValues.sort((a, b) => (a.value > b.value ? -1 : 1))}
              value={getYesNoRadioValues(profile?.hasTwin)?.value}
              onChange={(value) => {
                updateMultipleProfileFields({ hasTwin: value === 1, twinCount: null });
              }}
            />
            {!!profile?.hasTwin && (
              <Select
                id="twin-count"
                label={t("profile:twinCount")}
                placeholder={t("translation:selectPlaceholder")}
                className="pb-2"
                value={twinOptions.find((option) => option.value == profile?.twinCount)}
                required
                onChange={(value: ValueOpt<number> | undefined) => {
                  updateProfileField("twinCount", value?.value);
                }}
                options={twinOptions}
              />
            )}
          </FormColumn2Wide>
          <FormColumn2Wide>
            <Select
              id="birth-country"
              label={t("profile:birthCountry")}
              placeholder={t("translation:selectPlaceholder")}
              value={getCountryOptions(profile?.birthCountryId)}
              options={countryOptions}
              onChange={onBirthCountryChange}
              required
            />
            <div className="mt-1">
              {profile?.birthCountryId === CountryIds.USA && (
                <StateSelectOrTextInput
                  id="birth-state"
                  label={t("profile:stateTerritory")}
                  idField="stateId"
                  idValue={profile?.birthStateProvinceId}
                  textValue={profile?.birthStateProvince}
                  options={stateOptions}
                  getOptionValue={getStateOptions}
                  onChange={(idValue, textValue) => {
                    if (idValue !== profile?.birthStateProvinceId || textValue !== profile?.birthStateProvince) {
                      updateMultipleProfileFields({
                        birthStateProvinceId: idValue,
                        birthStateProvince: textValue,
                        birthCityId: null,
                        birthCity: null,
                        birthSectorId: null,
                      });
                    }
                  }}
                  disabled={!profile?.birthCountryId}
                  required
                />
              )}
              {profile?.birthCountryId === CountryIds.CANADA && (
                <StateSelectOrTextInput
                  id="birth-province"
                  label={t("profile:stateTerritory")}
                  idField="provinceId"
                  idValue={profile?.birthStateProvinceId}
                  textValue={profile?.birthStateProvince}
                  options={provinceOptions}
                  getOptionValue={getProvinceOptions}
                  onChange={(idValue, textValue) => {
                    if (idValue !== profile?.birthStateProvinceId || textValue !== profile?.birthStateProvince) {
                      updateMultipleProfileFields({
                        birthStateProvinceId: idValue,
                        birthStateProvince: textValue,
                        birthCityId: null,
                        birthCity: null,
                        birthSectorId: null,
                      });
                    }
                  }}
                  disabled={!profile?.birthCountryId}
                  required
                />
              )}
              {profile?.birthCountryId !== CountryIds.USA && profile?.birthCountryId !== CountryIds.CANADA && (
                <StateSelectOrTextInput
                  id="birth-district"
                  label={t("profile:stateTerritory")}
                  idField="districtId"
                  idValue={profile?.birthStateProvinceId}
                  textValue={profile?.birthStateProvince}
                  options={districtOptions}
                  getOptionValue={(value) => getDistrictOptions(districtOptions, value)}
                  onChange={(idValue, textValue) => {
                    if (idValue !== profile?.birthStateProvinceId || textValue !== profile?.birthStateProvince) {
                      updateMultipleProfileFields({
                        birthStateProvinceId: idValue,
                        birthStateProvince: textValue,
                        birthCityId: null,
                        birthCity: null,
                        birthSectorId: null,
                      });
                    }
                  }}
                  disabled={!profile?.birthCountryId}
                  required
                />
              )}
            </div>
            <div className="mt-1">
              <CitySelectOrTextInput
                id="birth-city"
                label={t("profile:birthCity")}
                cityId={profile?.birthCityId}
                city={profile?.birthCity}
                options={citiesOptions}
                onChange={(cityId, city) => {
                  updateMultipleProfileFields({
                    birthCityId: cityId,
                    birthCity: city,
                    birthSectorId: null,
                  });
                }}
                required
                disabled={!profile?.birthStateProvince && !profile?.birthStateProvinceId}
              />
            </div>
          </FormColumn2Wide>
        </div>

        {!!sectorOptions?.length && (
          <div className="row pt-2">
            <FormColumn2Wide>
              <Select
                id="birth-sector"
                label={t("profile:birthSector")}
                placeholder={t("translation:selectPlaceholder")}
                value={getSectorOptions(sectorOptions, profile?.birthSectorId)}
                options={sectorOptions}
                onChange={(option: ValueOpt<Sector> | undefined) => {
                  updateProfileField("birthSectorId", option?.value.sectorId);
                }}
                required
              />
            </FormColumn2Wide>
            <FormColumn2Wide className="ps-3" />
          </div>
        )}

        {profile?.birthCountryId === CountryIds.CUBA && (
          <div className="row pt-2">
            <FormColumn width={1}>
              <RadioGroup
                id="lives-in-us-can-pr"
                label={t("profile:livesInUsCanPr")}
                name="livesInUsCanPr"
                className="pb-2"
                required
                options={yesNoRadioValues.sort((a, b) => (a.value > b.value ? -1 : 1))}
                value={getYesNoRadioValues(profile?.livesInUsCanPr)?.value}
                onChange={(value) => {
                  updateProfileField("livesInUsCanPr", value === 1);
                }}
              />
            </FormColumn>
          </div>
        )}

        <div className="row pt-2">
          <RadioGroup
            id="traveled-to-us-can-pr"
            label={t("profile:traveledToUsCanPr")}
            name="traveledToUsCanPr"
            value={!!profile?.traveledToUsCanPrFlag ? 1 : 0}
            required
            disabled={
              USA_TERRITORIES_CANADA_IDS.includes(profile?.registrationCountryId) ||
              USA_TERRITORIES_CANADA_IDS.includes(profile?.birthCountryId)
            }
            options={yesNoRadioValues.sort((a, b) => (a.value > b.value ? -1 : 1))}
            onChange={(value: RadioValue) => {
              updateMultipleProfileFields({
                traveledToUsCanPrFlag: !!value,
                traveledToUsCanPrCount: !!value ? profile?.traveledToUsCanPrCount : null,
                longestStayUsCanPr: !!value ? profile?.longestStayUsCanPr : null,
                recentStayUsPrCan: !!value ? profile?.recentStayUsPrCan : null,
              });
            }}
          />
        </div>

        {!!profile?.traveledToUsCanPrFlag && (
          <TraveledToUsCanPrDetails isCubanBorn={profile?.birthCountryId === CountryIds.CUBA} />
        )}
      </div>
    </>
  );
};

export default BioInformationSection;
