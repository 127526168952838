import { Button, Modal } from "best-common-react";
import React from "react";
import { useTranslation } from "react-i18next";

type SelfRegisteringTrainerModalProps = {
  show: boolean;
  close: () => void;
};

const SelfRegisteringTrainerModal: React.FC<SelfRegisteringTrainerModalProps> = ({ show, close }) => {
  const { t } = useTranslation(["translation", "registration"]);

  return (
    <Modal show={show}>
      <Modal.Header>Error</Modal.Header>
      <Modal.Body>
        <div>
          {t("registration:selfRegisteringTrainerBody")} <a href="mailto:registro@mlb.com">registro@mlb.com</a>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={close} variant="secondary">
          {t("translation:close")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SelfRegisteringTrainerModal;
