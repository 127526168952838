import React, { useState } from "react";
import TrainerSearchModal from "./TrainerSearchModal";
import CreateTrainerModal from "./CreateTrainerModal";
import MatchedTrainerModal from "./MatchedTrainerModal";
import { TrainerDTO } from "../../../../types/Trainer";
import { createTrainer, getTrainerMatches } from "../../../../api/AuthenticatedClient";

enum Step {
  SEARCH,
  CREATE,
  MATCHED,
}

type ProspectProfileTrainerModalProps = {
  onClose: () => void;
  addTrainer: (trainer: TrainerDTO) => Promise<void>;
};

const ProspectProfileTrainerModal: React.FC<ProspectProfileTrainerModalProps> = ({ onClose, addTrainer }) => {
  const [step, setStep] = useState(Step.SEARCH);
  const [matchedTrainers, setMatchedTrainers] = useState<TrainerDTO[]>([]);
  const [pendingSave, setPendingSave] = useState<TrainerDTO>();

  const saveTrainer = async (trainer: TrainerDTO) => {
    try {
      const savedTrainer = await createTrainer(trainer);
      await addTrainer(savedTrainer);
      onClose();
    } catch (e) {
      console.error(e);
    }
  };

  const save = async (trainer: TrainerDTO) => {
    try {
      const matched: TrainerDTO[] = await getTrainerMatches(trainer);
      if (!!matched.length) {
        setPendingSave(trainer);
        setMatchedTrainers(matched);
        setStep(Step.MATCHED);
      } else {
        await saveTrainer(trainer);
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      <TrainerSearchModal
        open={step === Step.SEARCH}
        onClose={onClose}
        onContinue={addTrainer}
        onTrainerNotListed={() => setStep(Step.CREATE)}
      />
      <CreateTrainerModal open={step === Step.CREATE} onClose={() => setStep(Step.SEARCH)} onSave={save} />
      <MatchedTrainerModal
        open={step === Step.MATCHED}
        trainers={matchedTrainers}
        onSave={() => saveTrainer(pendingSave)}
        onClose={onClose}
        addTrainer={addTrainer}
      />
    </>
  );
};

export default ProspectProfileTrainerModal;
