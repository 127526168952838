import React, { createContext, ReactNode, useContext, useEffect, useState } from "react";
import {
  getProspectProfile,
  saveBaseballInformation,
  saveBioInformation,
  saveProspectAddress,
  saveProspectName,
  saveProspectNationalId,
  saveProspectRelatives,
  saveProspectTrainerInformation,
} from "../api/AuthenticatedClient";
import {
  ProspectProfileDTO,
  ProspectNameDTO,
  BaseballInformationDTO,
  BioInformationDTO,
  ProspectAddressDTO,
  ProspectNationalIdDTO,
  ProspectRelativesDTO,
} from "../types/ProspectProfile";
import {
  validateBaseballInformation,
  validateBioInformation,
  validateContactInformation,
  validateProspectAddress,
  validateProspectName,
  validateNationalId,
  validateProspectRelatives,
  validateTrainerInformation,
} from "../util/ProspectProfileValidationUtil";
import { useMetadata } from "./MetadataContext";
import { calcProspectProfileCompletion } from "../util/ProspectProfileCompletionUtil";
import { CountryIds } from "../types/Metadata";

export enum ProspectProfileSection {
  NAME,
  BIO,
  BASEBALL,
  ADDRESS,
  CONTACT,
  RELATIVES,
  TRAINER,
  NATIONAL_ID,
}

type ProspectProfileContextType = {
  profile?: ProspectProfileDTO;
  updateProfileField: (key: keyof ProspectProfileDTO, value: any) => void;
  updateMultipleProfileFields: (patch: { [p: string]: any }) => void;
  saveProfile: () => Promise<void>;
  canSave: boolean;
  hasChanged: boolean;
  completionPercentage: number;
  section: ProspectProfileSection;
  previousSection: () => void;
  nextSection: () => void;
};

const ProspectProfileContext = createContext<ProspectProfileContextType | undefined>(undefined);

type ProfileProviderProps = {
  children?: ReactNode;
};

export const ProspectProfileProvider: React.FC<ProfileProviderProps> = ({ children }) => {
  const { nationalIDTypes, countries } = useMetadata();
  const [data, setData] = useState<ProspectProfileDTO>();
  const [hasChanged, setHasChanged] = useState(false);
  const [canSave, setCanSave] = useState(false);
  const [section, setSection] = useState<ProspectProfileSection>();
  const [completion, setCompletion] = useState<number>();

  useEffect(() => {
    const fetchProspectProfile = async () => {
      const res = await getProspectProfile();
      setData(res);
      calculateSection(res);
    };

    fetchProspectProfile().catch(console.error);
  }, []);

  // Calculate canSave
  useEffect(() => {
    if (!countries || !nationalIDTypes) return;

    switch (section) {
      case ProspectProfileSection.NAME:
        setCanSave(validateProspectName(data));
        break;
      case ProspectProfileSection.BIO:
        setCanSave(validateBioInformation(data));
        break;
      case ProspectProfileSection.BASEBALL:
        setCanSave(validateBaseballInformation(data, CountryIds.CUBA === data?.birthCountryId));
        break;
      case ProspectProfileSection.ADDRESS:
        setCanSave(validateProspectAddress(data));
        break;
      case ProspectProfileSection.CONTACT:
        setCanSave(validateContactInformation(data));
        break;
      case ProspectProfileSection.RELATIVES:
        setCanSave(validateProspectRelatives(data, countries, nationalIDTypes));
        break;
      case ProspectProfileSection.TRAINER:
        setCanSave(validateTrainerInformation(data));
        break;
      case ProspectProfileSection.NATIONAL_ID:
        const nationalIdType = nationalIDTypes.find((type) => type.nationalIdTypeId === data?.nationalIdTypeId);
        setCanSave(validateNationalId(data.registrationCountryId, nationalIdType, data?.nationalId));
        break;
      default:
        setCanSave(false);
        return;
    }
  }, [data, section, countries, nationalIDTypes]);

  useEffect(() => {
    let percentage = calcProspectProfileCompletion(data) * 100;
    percentage = Math.ceil(percentage);
    percentage = Math.min(percentage, 100);
    setCompletion(percentage);
  }, [data]);

  const calculateSection = (profile: ProspectProfileDTO) => {
    const nameComplete = validateProspectName(profile);
    const bioComplete = validateBioInformation(profile);
    const baseballComplete = validateBaseballInformation(profile, CountryIds.CUBA === profile?.birthCountryId);
    const addressComplete = validateProspectAddress(profile);
    const contactComplete = validateContactInformation(profile);
    const relativesComplete = validateProspectRelatives(profile, countries, nationalIDTypes);
    const trainerComplete = validateTrainerInformation(profile);

    if (
      nameComplete &&
      bioComplete &&
      baseballComplete &&
      addressComplete &&
      contactComplete &&
      relativesComplete &&
      trainerComplete
    ) {
      setSection(ProspectProfileSection.NATIONAL_ID);
    } else if (
      nameComplete &&
      bioComplete &&
      baseballComplete &&
      addressComplete &&
      contactComplete &&
      relativesComplete
    ) {
      setSection(ProspectProfileSection.TRAINER);
    } else if (nameComplete && bioComplete && baseballComplete && addressComplete && contactComplete) {
      setSection(ProspectProfileSection.RELATIVES);
    } else if (nameComplete && bioComplete && baseballComplete && addressComplete) {
      setSection(ProspectProfileSection.CONTACT);
    } else if (nameComplete && bioComplete && baseballComplete) {
      setSection(ProspectProfileSection.ADDRESS);
    } else if (nameComplete && bioComplete) {
      setSection(ProspectProfileSection.BASEBALL);
    } else if (nameComplete) {
      // Continue to show name section for initial login
      setSection(ProspectProfileSection.NAME);
    } else {
      setSection(ProspectProfileSection.NAME);
    }
  };

  const updateProfileField = (key: keyof ProspectProfileDTO, value: any) => {
    const patch = { [key]: value };
    setHasChanged(true);
    setData({ ...data, ...patch });
  };

  const updateMultipleProfileFields = (patch: { [p: string]: any }) => {
    setHasChanged(true);
    setData({ ...data, ...patch });
  };

  const saveProfile = async () => {
    if (!hasChanged) return;

    switch (section) {
      case ProspectProfileSection.NAME:
        await saveProspectName(data as ProspectNameDTO);
        break;
      case ProspectProfileSection.BIO:
        await saveBioInformation(data as BioInformationDTO);
        break;
      case ProspectProfileSection.BASEBALL:
        await saveBaseballInformation(data as BaseballInformationDTO);
        break;
      case ProspectProfileSection.ADDRESS:
        await saveProspectAddress(data as ProspectAddressDTO);
        break;
      case ProspectProfileSection.CONTACT:
        // do nothing
        break;
      case ProspectProfileSection.RELATIVES:
        await saveProspectRelatives(data as ProspectRelativesDTO);
        break;
      case ProspectProfileSection.TRAINER:
        if (!data.hasTrainer) {
          await saveProspectTrainerInformation({
            hasTrainer: false,
            trainers: [],
          });
        }
        break;
      case ProspectProfileSection.NATIONAL_ID:
        await saveProspectNationalId(data as ProspectNationalIdDTO);
        break;
      default:
        return;
    }
    setHasChanged(false);
  };

  const previousSection = () => {
    setSection(section - 1);
  };

  const nextSection = () => {
    setSection(section + 1);
  };

  return (
    <ProspectProfileContext.Provider
      value={{
        profile: data,
        updateProfileField,
        updateMultipleProfileFields,
        saveProfile,
        canSave,
        hasChanged,
        completionPercentage: completion,
        section,
        previousSection,
        nextSection,
      }}
    >
      {children}
    </ProspectProfileContext.Provider>
  );
};

export const useProspectProfile = (): ProspectProfileContextType => {
  const profileContext = useContext<ProspectProfileContextType>(ProspectProfileContext);
  if (profileContext === undefined) {
    throw new Error(`useProspectProfile must be used within a ProspectProfileProvider`);
  }
  return profileContext;
};
