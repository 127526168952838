import { ToggleButtonOption, ValueOpt } from "best-common-react";
import { CountryCode as LibCountryCode } from "libphonenumber-js/types";

export type CacheTimeDTO = {
  cacheTime: Date;
};

export const CountryIds = {
  NO_COUNTRY: 0,
  USA: 1,
  CANADA: 2,
  PR: 3,
  COLOMBIA: 15,
  CUBA: 18,
  DR: 22,
  GUAM: 29,
  JAPAN: 38,
  MEXICO: 41,
  PANAMA: 48,
  USVI: 66,
  VEN: 67,
};

export type Country = {
  countryId: number;
  abbreviation: string;
  description: string;
  esDescription: string;
  countryCode: LibCountryCode;
  dialCode: string;
  languageId: number;
  useDropdownFlag: boolean;
};

export type State = {
  stateId: number;
  name: string;
};

export type Province = {
  provinceId: number;
  description: string;
};

export type District = {
  districtId: number;
  description: string;
};

export type City = {
  cityId: number;
  description: string;
};

export type Sector = {
  sectorId: number;
  description: string;
};

export type Position = {
  positionId: number;
  description: string;
  esDescription: string;
  abbreviation: string;
};

export type NationalIDType = {
  nationalIdTypeId: number;
  description: string;
  countryId: number;
  format?: string;
  pattern?: string;
};

export type CountryCode = {
  country: string;
  code: string;
};

export type CountryDialCode = {
  countryId: number;
  country: string;
  countryCode: string;
  dialCode: string;
};

export type Language = {
  languageId: number;
  description: string;
};

export type ContactMethod = {
  contactMethodId: number;
  description: string;
};

export type ThrowingHand = {
  throwingHandId: number;
  description: string;
  esDescription: string;
};

export type BattingSide = {
  battingSideId: number;
  description: string;
  esDescription: string;
};

export type MetadataValue = {
  value: number;
  description: string;
  esDescription?: string;
};

export type PhoneNumberType = {
  phoneNumberTypeId: number;
  description: string;
  esDescription?: string;
};

export type PhoneNumberCommunicationType = {
  phoneNumberCommunicationTypeId: number;
  description: string;
  esDescription?: string;
};

export type EmailAddressType = {
  emailAddressTypeId: number;
  description: string;
  esDescription?: string;
};

export type UnitOfMeasurement = {
  unitId: number;
  description: string;
  abbreviation: string;
};

export type MexicanLeagueTeam = {
  id: string;
  name: string;
  teamName: string;
};

export type IntlProfessionalLeague = {
  id: number;
  description: string;
  esDescription: string;
};

export type IntlProfessionalTeam = {
  id: number;
  leagueId: number;
  description: string;
};

export const YesNo: MetadataValue[] = [
  {
    value: 0,
    description: "No",
  },
  {
    value: 1,
    description: "Yes",
    esDescription: "Sí",
  },
];

export type LookupToggleType<T> = {
  options: ValueOpt<T>[];
  optionGetter: (id: number) => ValueOpt<T> | undefined;
  toggleOption: ToggleButtonOption;
};

export type LanguageEnum = {
  languageId: number;
  description: string;
  locale: string;
};

export type CommunicationType = {
  communicationMethodId: number;
  description: string;
  esDescription: string;
};

export enum ContactType {
  EMAIL = 1,
  WHATSAPP,
  SMS,
}
