import React from "react";
import MLB_Logo from "../../assets/mlb-64x64.png";
import { Typography } from "best-common-react";
import styled from "styled-components";

const Container = styled.div``;

const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
    font-size: 1.5rem;
    font-weight: 600;
  color: ${(props) => props.theme.colors["mlb-navy"]}}

`;

const Body = styled.div`
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const ContactAddress = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: 600;
  margin-left: 1.5rem;
`;

const DrugTestLanguage: React.FC = () => {
  return (
    <Container>
      <Header>
        <img src={MLB_Logo} alt="MLB Logo" />
      </Header>
      <Body>
        <div className="text-center w-100">
          <Typography variant="h6">
            POLÍTICA DE PREVENCIÓN Y DE PRUEBAS DE DROGAS PARA PROSPECTOS INTERNACIONALES
          </Typography>
        </div>
        <div>
          <Typography variant="body2">
            A continuación, se presenta el Política de Prevención y de Pruebas de Drogas para Prospectos Internacionales
            del Béisbol de las Grandes Ligas (la “Política”).
          </Typography>
        </div>
        <div>
          <Typography variant="h6">1. INDIVIDUOS CUBIERTOS POR LA POLÍTICA</Typography>
          <Typography variant="body2">
            La Política cubre a todos los individuos de 13 años de edad o mayores que 1) sean o puedan ser elegibles
            para firmar un Contrato de jugador uniforme de ligas menores con un Club del Béisbol de las Grandes Ligas y
            2) no están sujetos a otro programa de pruebas de drogas de las Grandes Ligas (de aquí en adelante se
            denominan los “Prospectos”).
          </Typography>
        </div>
        <div>
          <Typography variant="h6">2. COMITÉ ASESOR DE POLÍTICA DE SALUD DE PROSPECTOS INTERNACIONALES</Typography>
          <Typography variant="body2">
            El Comité Asesor de Políticas de Salud de Prospectos Internacionales (“IPHPAC” por sus siglas en inglés)
            como se define en el Programa de Prevención de Drogas para Prospectos del Béisbol de las Grandes Ligas que
            no están Sujetos al “Draft” de Jugadores de Primer Año, es responsable de administrar y supervisar la
            Política.
          </Typography>
        </div>
        <div>
          <Typography variant="h6">3. SUSTANCIAS PROHIBIDAS</Typography>
          <Typography variant="body2">
            Los Prospectos se someterán a pruebas de Droga de Abuso, Estimulantes y Sustancia para Mejorar el
            Rendimiento (conocidas colectivamente como “Sustancias Prohibidas”) bajo la Política.
          </Typography>
        </div>
        <div style={{ marginLeft: "2rem" }}>
          <Typography variant="h6">A. Drogas de Abuso</Typography>
          <Typography variant="body2">
            A continuación se incluye una lista no exhaustiva de las Drogas de Abuso que están cubiertas por la
            Política:
          </Typography>
          <div style={{ marginLeft: "0.5em" }}>
            <Typography variant="body2">1. Canabinoides Sinteticos (p. ej., K2, Spice, y compuestos de JWH)</Typography>
            <Typography variant="body2">2. Cocaína</Typography>
            <Typography variant="body2">
              3. Narcóticos (p. ej., Codeína, Fentanilo y sus derivados, Heroína, Hidrocodona, y Oxicondona)
            </Typography>
            <Typography variant="body2">4. Metanfetamina (Metilamfetamina)</Typography>
            <Typography variant="body2">5. Metilendioxianfetamina (MDA)</Typography>
            <Typography variant="body2">6. Metilendioximetanfetamina (MDMA, Ecstasy)</Typography>
            <Typography variant="body2">
              7. “Sales de Bano” (p.ej., Catinona y sus análogos, Catinona sintéticos, y MDPV)
            </Typography>
            <Typography variant="body2">8. GHB</Typography>
            <Typography variant="body2">9. LSD</Typography>
            <Typography variant="body2">10. Fenciclidina (PCP)</Typography>
          </div>
        </div>
        <div style={{ marginLeft: "2rem" }}>
          <Typography variant="h6">B. Estimulantes</Typography>
          <Typography variant="body2">
            A continuación, se incluye una lista no exhaustiva de Estimulantes que están cubiertos por la Política:
          </Typography>
          <Typography variant="body2" style={{ marginLeft: "0.5rem" }}>
            Adrafinil, Amfepramona, Amifenazole, Amfetamina, Amfetaminil, Armodafinilo, Benfluorex, Benzfetamina,
            Benzilpiperazinz, Bromantan, Fonturacetam (Carfedon), Catina (Norpseudoefedrina), Clorfentermina,
            Clobenzorex, Clortermina, Cropropamida, Crotetamida, Dimetilamilamina, Dimethylamfetamina,
            1,3-Dimetilbutilamina (DMBA), Efedrina, Etamivan, Etilamfetamina, Etilefrina, Famprofazona, Fenbutrazato,
            Fencamina, Fencamfamin, Fenetilina, Fenfluramina, Fenproporex, Furfenorex, Heptaminol, Isometepteno,
            Levmetamfetamina, Lisdexanfetamina, Meclofenoxato, Mefenorex, Mefentermina, Mesocarb, Metilefedrina,
            Metilhexaneamina (Dimethylpentylamine, DMAA), Metilfenidato, Modafinil, N,alpha- Diethylphenylethylamine
            (N,a-DEPEA), N-etil-1-fenil-2-butanamina, Niketamid, Norfenefrina, Norfenfluramina, Octodrine (DMHA),
            Octopamina, Oxilofrina (Metilsinefrina), Parahidroxiamfetamina, Pemolina, Pentetrazol, Fendimetrazina,
            Fenetilamina, Fenmetrazine, Fenprometamina, Fentermine, Prenilamina, Prolintano, Propilhexedrina,
            Selegilina, Sibutramina, Stricnina, Tuaminoheptano, y todas las demás sustancias con estructura química o
            efectos biológicos similares.
          </Typography>
        </div>
        <div style={{ marginLeft: "2rem" }}>
          <Typography variant="h6">C. Sustancias para Mejorar el Rendimiento</Typography>
          <Typography variant="body2" />
          <div style={{ marginLeft: "1rem" }}>
            <Typography variant="body2" style={{ textDecoration: "underline" }}>
              1. Agentes Anabólicos
            </Typography>
            <div style={{ marginLeft: "1rem" }}>
              <Typography variant="body2">a. Esteroides Androgénicos Anabólicos:</Typography>
              <Typography variant="body2">
                1-androstenediol, 1-androstenediona, 4-hidroxitestosterona, 7-cKeto-DHEA, Androstadienediona,
                Androstanediol, Androstanediona, Androstanolona, Androstenediol, Androstenediona, Androt-2-en-17-one
                (2-Androsterone, Delta-2), Androsterona, Bolandiol, Bolasterona, Boldenona, Boldiona, Calusterona,
                Clostebol Clostebol (Clortestosterona), Dehidroclorometiltestosterona (DHCMT, Turinabol),
                Dehidroepiandrosterona (DHEA), Desoxi-metiltestosterona (DMT, Madol), Dihidrotestosterona, Drostanolona,
                Epiandrosterona, Epi-dihidrotestosterona, Epitestosterona, Etilestrenol, Fluoximesterona, Formebolona,
                Halodrol, Mestanolona, Mesterolona, Metandienona, Metandriol, Metasterona Metenolona, Metilclostebol,
                Metildienolona, Metilnortestosterona, Metilestenbolona (Ultradrol, M-Sten), Metiltestosterona,
                Metiltrienolona (Metribolona), Mibolerona, Nandrolona, Norandrostenediol, Norandrostenediona,
                Norandrosterona, Norboletona (Genabol), Norclostebol, Noretandrolona, Noretiocolanolona, Oxabolona,
                Oxandrolona, Oximesterona, Oximetolona, Prasterona (DHEA), Promagnon, Prostanozolol, Quinbolona,
                Estanozolol, Estenbolona, Testosterona, Tetrahidrogestrinona, Trenbolona y todas las demás sustancias
                con estructura química o efectos biológicos similares.
              </Typography>
              <Typography variant="body2">
                b. Otros agentes anabólicos incluso, pero sin limitarse a ellos, Clenbuterol, Moduladores Selectivos de
                Receptores de Andrógenos (SARM) (p. ej., Andarine, LGD-4033 (Ligandrol), Ostarine, y RAD140
                (Testolona)), Tibolona, Zeranol y Zilpaterol.
              </Typography>
            </div>
          </div>
          <div style={{ marginLeft: "1rem" }}>
            <Typography variant="body2" style={{ textDecoration: "underline" }}>
              2. Hormonas de Péptidos, Factores de Crecimiento y Sustancias Relacionadas
            </Typography>
            <div style={{ marginLeft: "1rem" }}>
              <Typography variant="body2">
                a. Hormona del Crecimiento (GH), sus fragmentos y sus factores liberadores, lo que incluye de manera
                enunciativa mas no limitativa:
              </Typography>
              <div style={{ marginLeft: "1rem" }}>
                <Typography variant="body2">
                  i. Fragmentos de Hormona de Crecimiento (p. ej., AOD-9604 y hGH 176-191);
                </Typography>
                <Typography variant="body2">
                  ii. Hormona que libera la hormona de crecimiento (GHRH) (p. ej., CJC-1295, Sermorelina y
                  Tesamorelina);
                </Typography>
                <Typography variant="body2">
                  iii. Secretagogos de la Hormona de Crecimiento (por sus siglas en ingles “GHS”) (p. ej., Grelina y sus
                  miméticos (p. ej., Anamorelina, Ibutamoren (MK-0677), y Ipamorelina));
                </Typography>
                <Typography variant="body2">
                  iv. Péptidos liberadores de hormona de crecimiento (por sus siglas en ingles “GHRP”) (p. ej.,
                  Alexamorelin, GHRP-2 (Pralmorelin), GHRP-6 y Hexarelin).
                </Typography>
              </div>

              <Typography variant="body2">
                b. Factor Insulinico de Crecimiento (IGF-1), incluyendo todos los análogos e isómeros de IGF- 1 (p. ej.,
                Factores Mecano de Crecimiento (MGFs) y Timosina Beta 4 (TB- 500));
              </Typography>
              <Typography variant="body2">
                c. Gonadotrofina Coriónica Humana (hCG), Hormona Luteinizante (LH) y sus factores liberadores (p.ej.,
                Triptorelina);
              </Typography>
              <Typography variant="body2">
                d. Corticotrofinas y sus factores liberadores (p. ej., Corticorelina);
              </Typography>
              <Typography variant="body2">
                e. Eritropoiesis-Agentes Estimulantes (p. ej., Eritropoietina (EPO), Darbepoetina (dEPO), Hematida y
                Metoxi polietileno glicol-epoetina beta (CERA)).
              </Typography>
            </div>
          </div>
          <div style={{ marginLeft: "1rem" }}>
            <Typography variant="body2" style={{ textDecoration: "underline" }}>
              3. Moduladores de Hormonas y Metabólicos
            </Typography>
            <div style={{ marginLeft: "1rem" }}>
              <Typography variant="body2">
                a. Inhibidores de Aromatasa incluso, pero sin limitación a, Anastrozole, Androstatrienediona (ATD),
                Androstenetriona (6-OXO), Aminoglutetimida, Armistane, Exemestano, Formestano, Letrozole y Testolactona;
              </Typography>
              <Typography variant="body2">
                b. Moduladores Selectivos de Receptores de Estrógeno (SERM), incluyendo, pero sin limitarse a,
                Bazedoxifeno, Ospemifeno, Raloxifeno, Tamoxifeno y Toremifeno;
              </Typography>
              <Typography variant="body2">
                d. Agentes que modifican la función de las miostatinas incluso, pero sin limitarse a los Inhibidores de
                Miostatina (p. ej., Folistatina);
              </Typography>
              <Typography variant="body2">
                e. Moduladores metabólicos, que incluye el Proliferador de Peroxisoma Activado δ del Receptor de PPAR
                (agonistas), incluyendo GW1516, GW50516, GW0742, activadores de la proteina quinasa activada por AMP
                (AMPK) (p. ej., AICAR y SR9009 (Stenabolic)), Meldonium (Mildronate) y Trimetazidina;
              </Typography>
              <Typography variant="body2">
                f. Estabilizadores HIF, incluyendo Roxadustate (FG-4592), Molidustat (BAY 85-3934), FG-2216, and BAY
                87-2243.
              </Typography>
            </div>
          </div>
          <div style={{ marginLeft: "1rem" }}>
            <Typography variant="body2" style={{ textDecoration: "underline" }}>
              4. Diuréticos y otros Agentes de Encubrimiento
            </Typography>
            <div style={{ marginLeft: "1rem" }}>
              <Typography variant="body2">
                a. Los agentes de encubrimiento incluyen: Diuréticos, Desmopressin, Probenecida, Expansores de Plasma
                (p. ej., administración intravenosa de albúmina, dextrano, hidroxietilo y manitol), y otras sustancias
                con efectos biológicos similares.
              </Typography>
              <Typography variant="body2">
                b. Los diuréticos incluyen: Acetazolamida, Amilorida, Bumetanida, Canrenona, Clortalidona, Ácido
                Etacrínico, Furosemida, Indapamida, Metolazona, Espironolactona, Tiazides (p. ej., Bendroflumetiazida,
                Clorotiazida, Hidroclorotiazida), Triamtereno, Vaptanes, y otras sustancias con estructura química o
                efecto biológico similar.
              </Typography>
            </div>
          </div>
        </div>

        <div>
          <Typography variant="h6">4. PRUEBAS</Typography>
          <div style={{ marginLeft: "2rem" }}>
            <Typography variant="h6" style={{ marginTop: "1rem" }}>
              A. Pruebas Obligatorias y al Azar
            </Typography>
            <Typography variant="body2">
              Cada Prospecto estará sujeto a pruebas de drogas no anunciadas obligatorias y aleatorias según la Política
              desde el momento en que se firme la Autorización descrita en la Sección 7 siguiente hasta lo primero
              entre: 1) el momento en que el Prospecto firme un Contrato de Jugador Uniforme de Ligas Menores; o 2)
              queda sujeto a otro programa de pruebas de drogas del Béisbol de las Grandes Ligas (el “período de
              prueba”).
            </Typography>

            <Typography variant="h6">B. Pruebas por Causa Rezonables</Typography>
            <Typography variant="body2">
              En el caso de que IPHPAC o un Club de las Grandes Ligas reciban información que le proporcione una causa
              razonable para creer que un Prospecto ha participado en el uso o la posesión de una Sustancia Prohibida
              durante el período de prueba, el Prospecto estará sujeto a una prueba inmediata.
            </Typography>

            <Typography variant="h6">C. Procedimientos de Recolección y Pruebas</Typography>
            <Typography variant="body2">
              Todos los especímenes bajo la Política serán recolectados por Drug Free Sport (“DFS” por sus siglas en
              inglés) y enviados al Laboratorio de Investigación y Pruebas de Medicina del Deporte (“SMRTL” por sus
              siglas en inglés) para su análisis. Cada espécimen recolectado bajo la Póliza será probado para la
              presencia de todas las Sustancias Prohibidas.
            </Typography>

            <Typography variant="h6">D. Notificación</Typography>
            <Typography variant="body2">
              Los prospectos serán notificados de una prueba programada por un representante de DFS. Los prospectos no
              recibirán notificación previa de la ocurrencia de una colección. Los prospectos son responsables de
              proporcionar a la Oficina del Comisionado - América Latina información precisa sobre el contacto y la
              ubicación (por ejemplo, números de teléfono y direcciones) en el momento en que se firme la Autorización
              descrita en la Sección 7 a continuación. Los prospectos deben comunicarse con Raymond Blais al
              809-518-2233 (extensión 5214) o Raymond.Blais@mlb.com para proporcionar información de contacto revisada o
              actualizada.
            </Typography>

            <Typography variant="h6">E. Resultados Positivos de Pruebas</Typography>
            <Typography variant="body2">
              Las pruebas que se conduzcan de acuerdo a la Política se considerarán “positivas” bajo las siguientes
              circunstancias:
            </Typography>
            <div style={{ marginLeft: "1rem" }}>
              <Typography variant="body2">
                1. Se detecta alguna Sustancia Prohibida en el espécimen proporcionado por el Prospecto; o
              </Typography>
              <Typography variant="body2">
                2. El Prospecto intenta sustituir, diluir, encubrir o adulterar un espécimen, o intenta interferir con
                una prueba de cualquier manera La determinación de si una prueba es “positiva” de acuerdo a esta sección
                4.E.2 ó 5.C.3 será hecha por el IPHPAC.
              </Typography>
            </div>

            <Typography variant="h6">F. Negación a la Prueba</Typography>
            <Typography variant="body2">
              Se considerará que un Prospecto se ha negado a ser sometido a la prueba de presentarse las siguientes
              situaciones:
            </Typography>
            <div style={{ marginLeft: "1rem" }}>
              <Typography variant="body2">
                1. Un Prospecto se niega o no realiza un examen de conformidad con la Sección 4 anterior, que incluye el
                rechazo de un padre o tutor para permitir que se realice una prueba, o un rechazo por el Prospecto (o
                sus padre(s) o tutor(es)) para firmar la Autorización descrita en la Sección 7 a continuación; o
              </Typography>
              <Typography variant="body2">
                2. No se logra contactar al Prospecto durante el Periodo de Prueba utilizando la misma información de
                contacto que proporcionó en el momento en que se firmó la autorización.
              </Typography>
            </div>
            <Typography variant="body2">
              La decisión sobre si un jugador se ha negado a someterse a la prueba será tomada por el IPHPAC.
            </Typography>
          </div>
        </div>

        <div>
          <Typography variant="h6">5. NOTIFICACIÓN DE RESULTADOS DE LA PRUEBA</Typography>
          <Typography variant="body2">
            Todos los resultados de las pruebas de Prospecto se mantendrán confidenciales en la mayor medida posible.
            Todos los resultados de las pruebas serán informados por SMRTL a IPHPAC. IPHPAC notificará al Prospecto, a
            su(s) representante(s) y a sus padres y/o tutores, según corresponda, sobre cualquier resultado positivo de
            la prueba. El Gerente General, Asistente al Gerente General, el Director Internacional de Exploración, o
            cualquier representante de los Clubes de las Grandes Ligas designado por éstos podrán ponerse en contacto
            con el IPHPAC para obtener los resultados de las pruebas de un Prospecto que el Club esté interesado en
            firmar. IPHPAC proporcionará al Club solicitante la fecha de la recolección, los resultados de la prueba de
            detección de drogas, y (si corresponde) la Sustancia Prohibida para la cual el Prospecto dio positivo. Si un
            Prospecto se rehúsa a tomar una prueba de drogas, se informará a cualquier Club que solicite los resultados
            de la prueba de esa Prospecto que el Prospecto se negó a someterse a la prueba bajo la Política.
          </Typography>
        </div>

        <div>
          <Typography variant="h6">6. DESAFÍOS DE UN RESULTADO POSITIVO DE LA PRUEBA</Typography>
          <Typography variant="body2">
            Un Prospecto que cuestione los resultados de su prueba de drogas tendrá la oportunidad de presentar una
            declaración por escrito detallando los motivos del desafío. EI PHPAC informará a cualquier Club que solicite
            los resultados de las pruebas del Prospecto que el Prospecto ha impugnado el resultado positivo de la prueba
            y un resumen de los motivos del desafío. La solicitud de impugnación escrita debe ser enviada a la atención
            de Raymond Blais en la Oficina del Comisionado - América Latina dentro de las 48 horas de la notificación
            del resultado positivo de la prueba. La información de contacto del Sr. Blais es la siguiente:
          </Typography>
          <ContactAddress>
            <div>Raymond Blais</div>
            <div>Office of the Commissioner – Latin America</div>
            <div>Telephone: 809-518-2233 (extension 5214)</div>
            <div>E-mail: Raymond.Blais@mlb.com</div>
          </ContactAddress>
          <Typography>
            Cualquier Prospecto que impugne un resultado positivo en la prueba de la cual tiene una receta válida y
            médicamente apropiada para una Sustancia Prohibida emitida por un médico debidamente autorizado, puede
            presentar la documentación de dicha prescripción luego de notificársele un resultado positivo de la prueba y
            reclamar el derecho a una Exención de Uso Terapéutico Terapéutico (“TUE” por sus siglas en inglés). Después
            de recibir un desafío basado en argumentos de TUE, se le instruirá al Prospecto sobre cómo enviar
            información médica directamente a IPHPAC. La determinación de si un Prospecto tiene derecho a una TUE será
            hecha por IPHPAC y se otorgará para los fines de la Política solamente. Si posteriormente un Prospecto queda
            cubierto por otro programa de pruebas de drogas de las Grandes Ligas, se le solicitará que vuelva a
            solicitar una TUE, de conformidad con las disposiciones del programa correspondiente.
          </Typography>
        </div>

        <div>
          <Typography variant="h6">7. CONFIDENCIALIDAD</Typography>
          <Typography variant="body2">
            Se requerirá que cada prospecto y sus padres y/o tutores, antes de someterse a la prueba de drogas conforme
            a la política, firmen un formulario de consentimiento en el que acepten someterse a pruebas de drogas para
            sustancias prohibidas de conformidad con la política y que autoricen el uso y/o divulgación de información
            de salud relacionada con las pruebas de drogas (“Autorización”). Los prospectos serán informados de la
            Política antes de firmar la Autorización. Esta autorización permitirá que IPHPAC reciba resultados de
            pruebas de drogas de SMRTL y divulgue los resultados de las pruebas de drogas a los Clubs como se describe
            en la Sección 5 anterior. Si no se firma la Autorización se considerará una negativa a realizar la prueba
            según el significado de la Sección 4.F.1 anterior.
          </Typography>
          <Typography>
            La Oficina del Comisionado (incluidos los miembros de IPHPAC), el Personal del Club y todos sus miembros,
            afiliados, agentes, consultores y empleados, utilizarán la máxima discreción en la diseminación de los
            resultados de las pruebas Prospecto, no divulgarán públicamente los resultados de las pruebas de un
            Prospecto. y no hará ningún comentario público con respecto a cualquier prueba realizada bajo la Política.
          </Typography>
        </div>

        <div>
          <Typography variant="h6">8. CONFORMIDAD CON LA LEY</Typography>
          <Typography variant="body2">
            La Política se implementará y se aplicará de una manera consistente con todas las leyes nacionales y locales
            que sean aplicables.
          </Typography>
        </div>

        <div>
          <Typography variant="h6">9. EDUCACIÓN</Typography>
          <Typography variant="body2">
            IPHPAC, en consulta con DFS, desarrollará programas educativos y materiales para prospectos que se enfocarán
            en los riesgos y consecuencias asociados con el uso de sustancias prohibidas. Los materiales educativos se
            distribuirán a todos los prospectos en el momento en que se firme la autorización y durante todo el período
            de prueba.
          </Typography>
        </div>

        <div>
          <Typography variant="h6">10. PREGUNTAS E INFORMACIÓN DE LA POLÍTICA</Typography>
          <Typography variant="body2">
            Los Prospectos que tengan preguntas generales sobre la Política o que deseen información adicional deben
            comunicarse con Raymond Blais en la Oficina del Comisionado – América Latina por teléfono al 809-518-2233
            (extensión 5214) o por correo electrónico en Raymond.Blais@mlb.com.
          </Typography>
        </div>
      </Body>
    </Container>
  );
};

export default DrugTestLanguage;
