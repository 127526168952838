import { ConsentFormSignerDTO } from "../types/ConsentForm";

export const guardianSignatureIncomplete = (signer?: ConsentFormSignerDTO) => {
  const emailIncomplete = !signer.email?.length;
  const whatsAppIncomplete = !signer.phoneNumberCountryId && !signer.phoneNumber?.length;
  return signer.required && emailIncomplete && whatsAppIncomplete;
};

export const calcConsentFormSubHeaderText = (
  complete: boolean,
  optional: boolean,
  eSign: boolean,
  requiredSignatures: number,
  completedSignatures: number,
  isEn: boolean,
): string => {
  if (optional) {
    return isEn ? "No Action Required" : "No se requiere accion";
  } else if (complete) {
    return isEn ? "Signed" : "Firmado";
  } else if (eSign) {
    if (requiredSignatures == 1 && completedSignatures == 0) {
      return isEn ? "1 Signature Required" : "1 Firma Requerida";
    } else {
      if (completedSignatures > 0) {
        return isEn
          ? `${completedSignatures} of ${requiredSignatures} Signed`
          : `${completedSignatures} de ${requiredSignatures} firmado`;
      } else {
        return isEn ? `${requiredSignatures} Signatures Required` : `${requiredSignatures} Firmas Requeridas`;
      }
    }
  } else {
    return isEn ? "Please print, sign and upload your consent form" : "Por favor imprima, firme y cargue su formulario";
  }
};
