import styled from "styled-components";

type CardProps = {
  enabled: boolean;
  canClick?: boolean;
};

export const Card = styled.div<CardProps>`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  width: 100%;
  justify-content: center;
  align-items: center;

  color: ${(props) => (props.enabled ? "default" : props.theme.colors.slate)};
  cursor: ${(props) => (props.canClick ? "pointer" : "default")};
  ${(props) =>
    props.enabled &&
    `
    box-shadow: 0 0 4px 0 rgba(4, 30, 66, 0.2);
    border-radius: 4px;
    background: white;
  `}
`;

export const Icon = styled.i`
  font-size: 2.5rem;
  margin-bottom: 0.25rem;
`;

export const Title = styled.div`
  font-size: 1.5rem;
  text-align: center;
`;

type SubHeaderProps = {
  complete: boolean;
};

export const SubHeader = styled.div<SubHeaderProps>`
  font-size: 1rem;
  color: ${(props) => (props.complete ? props.theme.colors.green : props.theme.colors["mlb-red"])};
`;
