import React from "react";
import { Button, FormColumn, Icon, RadioGroup, SubSection } from "best-common-react";
import { useTranslation } from "react-i18next";
import { useDropdowns } from "../../../../contexts/DropdownsContext";
import { useProspectProfile } from "../../../../contexts/ProsepctProfileContext";
import { Label, Value } from "../../read-only/ReadOnlyProfileStyles";
import styled from "styled-components";
import { LinkButtonStyles } from "../contact-info/ContactInformationStyles";
import AddProfessionalTeamModal from "./AddProfessionalTeamModal";
import { deleteProspectProfessionalTeam, saveProspectProfessionalTeam } from "../../../../api/AuthenticatedClient";
import { ProspectProfessionalTeamDTO } from "../../../../types/ProspectProfile";
import { useMetadata } from "../../../../contexts/MetadataContext";
import useLanguage from "../../../../hooks/useLanguage";

const ButtonText = styled.span`
  margin-left: 0.25rem;
`;

const Card = styled.div`
  display: flex;
  padding: 0.5rem;
  border: 1px solid ${(props) => props.theme.colors.fog};
  background: ${(props) => props.theme.colors.white};
  justify-content: space-between;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const Asterisk = styled.span`
  color: ${(props) => props.theme.colors["mlb-red"]};
`;

const CubanBornBaseballInfo: React.FC = () => {
  const { t } = useTranslation(["profile", "translation"]);
  const { profile, updateProfileField } = useProspectProfile();
  const { yesNoRadioValues, getYesNoRadioValues } = useDropdowns();
  const [showAddProfessionalTeamModal, setShowAddProfessionalTeamModal] = React.useState(false);
  const { intlProfessionalLeagues, intlProfessionalTeams } = useMetadata();
  const { isEs } = useLanguage();

  const onDelete = async (id: number) => {
    await deleteProspectProfessionalTeam(id);
    updateProfileField(
      "professionalTeams",
      profile.professionalTeams.filter((team) => team.id !== id),
    );
  };

  const onSave = async (toSave: ProspectProfessionalTeamDTO) => {
    const saved = await saveProspectProfessionalTeam(toSave);
    updateProfileField("professionalTeams", [...profile.professionalTeams, saved]);
  };

  return (
    <>
      <AddProfessionalTeamModal
        show={showAddProfessionalTeamModal}
        onClose={() => setShowAddProfessionalTeamModal(false)}
        onSave={onSave}
      />
      <div className="row pb-3">
        <FormColumn width={1}>
          <RadioGroup
            id="participated-in-pro-baseball"
            label={t("profile:participatedInProBaseball")}
            name="participated-in-pro-baseball"
            className="pb-2"
            required
            options={yesNoRadioValues.sort((a, b) => (a.value > b.value ? -1 : 1))}
            value={getYesNoRadioValues(profile?.participatedInProBaseball)?.value}
            onChange={(value) => {
              updateProfileField("participatedInProBaseball", value === 1);
            }}
          />
        </FormColumn>
      </div>

      {profile?.participatedInProBaseball === true && (
        <>
          <div className="row pb-3">
            <SubSection
              header={
                <span>
                  {t("profile:teams")} <Asterisk>*</Asterisk>
                </span>
              }
            >
              {profile.professionalTeams.map((proTeamDTO) => {
                const league = intlProfessionalLeagues.find((league) => league.id === proTeamDTO.leagueId);
                const leagueName = isEs ? league?.esDescription : league?.description;
                const teamName = intlProfessionalTeams.find((team) => team.id === proTeamDTO.leagueId)?.description;

                return (
                  <Card key={`professional-team-${proTeamDTO.id}`}>
                    <Column style={{ width: "45%" }}>
                      <div>
                        <Label>{t("profile:year")}</Label>
                        <Value>{proTeamDTO.year}</Value>
                      </div>
                      <div>
                        <Label>{t("profile:clubLocation")}</Label>
                        <Value>{proTeamDTO.clubLocation}</Value>
                      </div>
                    </Column>
                    <Column style={{ width: "45%" }}>
                      <div>
                        <Label>{t("profile:leagueName")}</Label>
                        <Value>{leagueName}</Value>
                      </div>
                      <div>
                        <Label>{t("profile:clubName")}</Label>
                        <Value>{teamName}</Value>
                      </div>
                    </Column>
                    <Column style={{ width: "10%", alignItems: "end" }}>
                      <Icon iconName="fa-trash-alt" onClick={() => onDelete(proTeamDTO.id)} />
                    </Column>
                  </Card>
                );
              })}
            </SubSection>
          </div>
          <div className="pb-3">
            <Button
              variant="link"
              className="ps-0"
              styles={LinkButtonStyles}
              onClick={() => setShowAddProfessionalTeamModal(true)}
            >
              <i className="fa-solid fa-circle-plus" />
              <ButtonText>{t("profile:addProTeamButton")}</ButtonText>
            </Button>
          </div>
        </>
      )}
    </>
  );
};

export default CubanBornBaseballInfo;
