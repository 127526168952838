import React, { useContext, useEffect, useState } from "react";
import ProfileCard from "../components/home/ProfileCard";
import ConsentFormCard from "../components/home/ConsentFormCard";
import DocumentsCard from "../components/home/DocumentsCard";
import styled, { ThemeContext } from "styled-components";
import profileImage from "../assets/profile-image.png";
import { EnvironmentType, HeroImage, Navy, useBreakpoints } from "best-common-react";
import { useAuthentication } from "../contexts/AuthenticationContext";
import Navigation from "../components/navigation/Navigation";
import { ProspectStatusIds } from "../constants/ProspectConstants";
import Footer from "../components/footer/Footer";
import { useTranslation } from "react-i18next";
import { getProspectConsentFormStatus } from "../api/AuthenticatedClient";
import { useProspectConsentFormSse } from "../contexts/ProspectConsentFormSseContext";

const env = window.MLBBest.envVariables.ENV as EnvironmentType;

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

type BodyProps = {
  offset: number;
};

const Body = styled.div<BodyProps>`
  height: ${(props) => `calc(100% - ${props.offset}px)`};
  display: flex;
  flex-direction: column;
`;

const HeaderContainer = styled.div`
  height: 10rem;
  width: 100%;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
`;

const NameText = styled.div`
  font-size: 2rem;
  overflow: hidden;
  text-decoration: none;
  text-overflow: ellipsis;
`;

const StatusText = styled.span`
  font-size: 1rem;
`;

type StatusValueProps = {
  textColor: string;
};

const StatusValue = styled.span<StatusValueProps>`
  font-size: 1rem;
  color: ${(props) => props.textColor};
`;

const CardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  gap: 1rem;
  flex-grow: 1;
  margin-bottom: 2rem; // Extra padding for mobile
`;

const Home: React.FC = () => {
  const { t, i18n } = useTranslation("home");
  const Theme = useContext(ThemeContext);
  const { userDetails } = useAuthentication();
  const { signedTs } = useProspectConsentFormSse();
  const { isMobile } = useBreakpoints();
  const [status, setStatus] = useState("");
  const [statusTextColor, setStatusTextColor] = useState("inherit");
  const [navSize, setNavSize] = useState<number>(isMobile ? (env === "prod" ? 45 : 63) : 70);

  useEffect(() => {
    switch (userDetails?.registrationStatusId) {
      case ProspectStatusIds.NOT_REGISTERED:
      case ProspectStatusIds.PENDING:
        setStatus(t("home:incompleteProfile"));
        setStatusTextColor(Theme.colors["mlb-red"]);
        break;
      case ProspectStatusIds.SENT_TO_EBIS:
        setStatus(t("home:verificationPending"));
        setStatusTextColor(Theme.colors.green);
        break;
      case ProspectStatusIds.PROFILE_COMPLETE:
        getProspectConsentFormStatus().then(({ canAccessDocuments }) => {
          const hasSigned = !!signedTs || !!canAccessDocuments;
          if (!hasSigned) {
            setStatus(t("home:consentFormIncomplete"));
            setStatusTextColor(Theme.colors["mlb-red"]);
          } else {
            setStatus(t("home:documentsMissing"));
            setStatusTextColor(Theme.colors["mlb-red"]);
          }
        });
        break;
      case ProspectStatusIds.VERIFIED:
        setStatus(t("home:verified"));
        setStatusTextColor(Theme.colors.green);
        break;
      case ProspectStatusIds.CORRECTION_NEEDED:
        setStatus(t("home:correctionNeeded"));
        setStatusTextColor(Theme.colors["mlb-red"]);
        break;
      case ProspectStatusIds.WAITING_ON_GUARDIAN_SIGNATURE:
        setStatus(t("home:waitingOnGuardian"));
        setStatusTextColor(Theme.colors["mlb-red"]);
        break;
      default:
        setStatus("");
        setStatusTextColor("inherit");
    }
  }, [userDetails?.registrationStatusId, signedTs, i18n.language]);

  useEffect(() => {
    setNavSize(isMobile ? (env === "prod" ? 45 : 63) : 70);
  }, [isMobile, env]);

  return (
    <Container>
      <Navigation />
      <Body offset={navSize}>
        <HeaderContainer>
          <HeroImage
            image={profileImage}
            styles={{
              ...Navy.heroImage,
              borderBottom: `3px solid ${Navy.colors["mlb-navy"]}`,
              backgroundPositionY: "bottom",
            }}
          >
            <Header>
              <NameText>
                {userDetails?.firstName} {userDetails?.lastName}
              </NameText>
              <div>
                <StatusText>{t("home:registrationStatus")}: </StatusText>
                <StatusValue textColor={statusTextColor}>{status}</StatusValue>
              </div>
            </Header>
          </HeroImage>
        </HeaderContainer>
        <CardsContainer>
          <ProfileCard />
          <ConsentFormCard />
          <DocumentsCard />
        </CardsContainer>
        <Footer />
      </Body>
    </Container>
  );
};

export default Home;
