import React, { useEffect, useState } from "react";
import Header from "../components/navigation/Header";
import styled from "styled-components";
import DocumentsIcon from "../components/icons/DocumentsIcon";
import {
  deleteProspectDocument,
  downloadProspectBlankSwornDeclaration,
  getConsentFormGuardianSigners,
  getProspectConsentForm,
  getProspectRequiredDocuments,
  getProspectUploadedDocuments,
  submitProspect,
  uploadProspectDocument,
} from "../api/AuthenticatedClient";
import { FileUploadDTO, ProspectUploadDTO, RequiredDocumentDTO } from "../types/Documents";
import { useTranslation } from "react-i18next";
import useLanguage from "../hooks/useLanguage";
import {
  AlertConstants,
  Button,
  downloadFile,
  FileAttachment,
  Typography,
  useAlerts,
  useLoading,
} from "best-common-react";
import { ACCEPTED_FILE_TYPES } from "../util/ProfileUtils";
import UploadedProspectDocument from "../components/documents/UploadedProspectDocument";
import IPLContentContainer from "../components/display/IPLContentContainer";
import IplContentButtons from "../components/display/IplContentButtons";
import { RouteConstants } from "../constants/RouteConstants";
import { useNavigate } from "react-router-dom";
import { useAuthentication } from "../contexts/AuthenticationContext";
import { ProspectStatusIds } from "../constants/ProspectConstants";
import DocumentsCompleteModal from "../components/completion/DocumentsCompleteModal";
import { DocumentTypes } from "../constants/DocumentConstants";

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

const Body = styled.div`
  padding: 1rem;
  width: 100%;
`;

const DocumentsList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.5rem;
`;

const DocumentCard = styled.div`
  padding: 0.5rem;
  border: 1px solid ${(props) => props.theme.colors.fog};
  background: ${(props) => props.theme.colors.white};
`;

type SubTitleProps = {
  isComplete: boolean;
};

const SubTitle = styled.div<SubTitleProps>`
  font-size: 1rem;
  color: ${(props) => (props.isComplete ? props.theme.colors.green : props.theme.colors["mlb-red"])};
`;

const ProspectDocuments: React.FC = () => {
  const { userDetails, refreshUserDetails } = useAuthentication();
  const { t } = useTranslation(["documents", "translation", "home"]);
  const { isEn } = useLanguage();
  const { loading, setLoading } = useLoading();
  const { addAlert } = useAlerts();
  const navigate = useNavigate();
  const [requiredDocuments, setRequiredDocuments] = useState<RequiredDocumentDTO[]>([]);
  const [uploadedDocuments, setUploadedDocuments] = useState<ProspectUploadDTO[]>([]);
  const [documentsSubmitted, setDocumentsSubmitted] = useState<boolean>();
  const [canSubmit, setCanSubmit] = useState<boolean>(false);
  const [canUpload, setCanUpload] = useState<boolean>(false);
  const [subHeaderText, setSubHeaderText] = useState<string>("");
  const [showCompleteModal, setShowCompleteModal] = useState(false);
  const [guardianOutstanding, setGuardianOutstanding] = useState(false);
  const [outstandingCount, setOutstandingCount] = useState<number>();

  const calcGuardianOutstanding = async () => {
    const { guardianSignaturesRequired } = await getProspectConsentForm();
    if (guardianSignaturesRequired) {
      const { guardian1, guardian2 } = await getConsentFormGuardianSigners();
      if (!guardian1.signedTs) {
        setGuardianOutstanding(true);
      } else if (guardian2.required && !guardian2.signedTs) {
        setGuardianOutstanding(true);
      } else {
        setGuardianOutstanding(false);
      }
    } else {
      setGuardianOutstanding(false);
    }
  };

  useEffect(() => {
    getProspectRequiredDocuments().then(setRequiredDocuments);
    getProspectUploadedDocuments().then(setUploadedDocuments);
  }, []);

  useEffect(() => {
    calcGuardianOutstanding().catch(console.error);
  }, []);

  useEffect(() => {
    if (userDetails) {
      setDocumentsSubmitted(userDetails.registrationStatusId !== ProspectStatusIds.PROFILE_COMPLETE);
      setCanUpload(userDetails.registrationStatusId !== ProspectStatusIds.VERIFIED);
    }
  }, [userDetails]);

  useEffect(() => {
    if (requiredDocuments.length) {
      const outstanding = requiredDocuments.filter(
        (required) =>
          !required.optionalFlag && !uploadedDocuments.find((uploaded) => uploaded.documentId === required.documentId),
      );

      setCanSubmit(!outstanding.length);
      setOutstandingCount(outstanding.length);
    }
  }, [requiredDocuments, uploadedDocuments]);

  useEffect(() => {
    if (documentsSubmitted === true) {
      setSubHeaderText(t("documents:submitted"));
    } else if (outstandingCount != undefined) {
      setSubHeaderText(
        !!outstandingCount ? `${outstandingCount} ${t("translation:required")}` : t("home:awaitingSubmission"),
      );
    } else {
      setSubHeaderText("");
    }
  }, [documentsSubmitted, outstandingCount]);

  const onSubmit = async () => {
    setLoading(true);
    await submitProspect();
    await refreshUserDetails();
    setLoading(false);
    setShowCompleteModal(true);
  };

  const addDocument = async (documentId: number, files: File[]) => {
    if (files?.length > 0) {
      setLoading(true);

      const filesMapped = files.map((file: File) => {
        const reader = new FileReader();
        return new Promise((resolve) => {
          reader.onload = () =>
            resolve({
              fileName: file.name,
              fileData: reader.result,
              documentId: documentId,
            } as FileUploadDTO);
          reader.readAsDataURL(file);
        });
      });
      const filesToSave = await Promise.all(filesMapped);
      const promises = filesToSave.map(
        (uploadDTO: FileUploadDTO) =>
          new Promise((resolve) => {
            resolve(uploadProspectDocument(uploadDTO));
          }),
      );

      const newDocuments = await Promise.all(promises);
      setUploadedDocuments([...uploadedDocuments, ...(newDocuments as ProspectUploadDTO[])]);

      setLoading(false);
    }
  };

  const deleteDocument = async (uploadId: number) => {
    try {
      setLoading(true);
      await deleteProspectDocument(uploadId);
      setUploadedDocuments([...uploadedDocuments.filter((doc) => doc.prospectUploadId != uploadId)]);
    } catch (e) {
      addAlert({
        type: AlertConstants.TYPES.DANGER,
        text: "Error deleting document.",
      });
    } finally {
      setLoading(false);
    }
  };

  const downloadSwornDeclaration = async () => {
    try {
      setLoading(true);
      const resp = await downloadProspectBlankSwornDeclaration();
      downloadFile(resp);
      const _requiredDocuments = await getProspectRequiredDocuments();
      setRequiredDocuments(_requiredDocuments);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  if (showCompleteModal) {
    return <DocumentsCompleteModal guardianOutstanding={guardianOutstanding} />;
  }

  return (
    <Container>
      <Header
        icon={<DocumentsIcon />}
        title={t("documents:documents")}
        subTitle={<SubTitle isComplete={documentsSubmitted}>{subHeaderText}</SubTitle>}
      />
      <Body>
        <IPLContentContainer>
          <Typography variant="body1">{t("documents:requiredDocuments")}</Typography>
          <DocumentsList>
            {requiredDocuments.map((required) => (
              <DocumentCard key={required.documentId}>
                <div className="d-flex flex-row justify-content-between">
                  <div className="d-flex flex-column justify-content-start" style={{ fontFamily: "Helvetica" }}>
                    <Typography variant="span">{isEn ? required.documentName : required.esDocumentName}</Typography>
                  </div>
                  <div className="d-flex flex-column align-items-end">
                    {required.requiredFlag ? (
                      <div style={{ textAlign: "right", width: 100 }}>
                        <Typography variant="subtitle1" elementStyles={{ color: "#bf0d3e", weight: "bold" }}>
                          * {t("translation:required")}
                        </Typography>
                      </div>
                    ) : (
                      <div style={{ textAlign: "right", width: 80 }}>
                        <Typography variant="subtitle1" elementStyles={{ color: "#041e42", weight: "bold" }}>
                          {t("translation:optional")}
                        </Typography>
                      </div>
                    )}
                    {required.documentId === DocumentTypes.SWORN_DECLARATION && (
                      <Button onClick={downloadSwornDeclaration} variant="primary">
                        {t("documents:printSwornDeclaration")}
                      </Button>
                    )}
                  </div>
                </div>
                <div className="d-flex flex-column align-items-center my-2">
                  <FileAttachment
                    id="document"
                    className="w-100"
                    placeholder={t("documents:dropFilesHere")}
                    fileTypes={ACCEPTED_FILE_TYPES}
                    maxFiles={10}
                    files={[]}
                    onChange={(files: File[]) => addDocument(required.documentId, files)}
                    showTable={false}
                    browseButtonText={t("translation:browse")}
                    disabled={!canUpload || !required.enabled}
                  />
                </div>
                {uploadedDocuments
                  .filter((uploaded) => uploaded.documentId === required.documentId)
                  .map((uploaded) => (
                    <UploadedProspectDocument
                      key={`uploaded-document-${uploaded.prospectUploadId}`}
                      fileName={uploaded.fileName}
                      resourceUrl={uploaded.resourceUrl}
                      canDelete={!documentsSubmitted}
                      onDelete={() => deleteDocument(uploaded.prospectUploadId)}
                    />
                  ))}
              </DocumentCard>
            ))}
          </DocumentsList>
          <IplContentButtons>
            <Button variant="secondary" className="w-100" size="lg" onClick={() => navigate(RouteConstants.BASE)}>
              {t("translation:close")}
            </Button>
            {documentsSubmitted === false && (
              <Button
                variant="primary"
                className="w-100"
                size="lg"
                disabled={!canSubmit || loading}
                loading={loading}
                onClick={onSubmit}
              >
                {t("documents:submitDocuments")}
              </Button>
            )}
          </IplContentButtons>
        </IPLContentContainer>
      </Body>
    </Container>
  );
};

export default ProspectDocuments;
