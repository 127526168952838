import { Button, Modal, Typography } from "best-common-react";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { RouteConstants } from "../../../constants/RouteConstants";
import styled, { ThemeContext } from "styled-components";

const EmailText = styled(Typography).attrs({
  variant: "body1",
})`
  display: inline;
  color: ${(props) => props.theme.colors["blue"]};
  margin-bottom: 0;
`;

type RegistrationPeriodClosedModalProps = {
  show: boolean;
};

const RegistrationPeriodClosedModal: React.FC<RegistrationPeriodClosedModalProps> = ({ show }) => {
  const { t } = useTranslation(["translation", "registration"]);
  const navigate = useNavigate();
  const Theme = useContext(ThemeContext);

  return (
    <Modal show={show} styles={{ ...Theme.modal, backdropOpacity: 1.0 }}>
      <Modal.Header>{t("registration:registrationPeriodClosedHeader")}</Modal.Header>
      <Modal.Body>
        {t("registration:registrationPeriodClosedBody")}
        <a href="mailto:registration@mlb.com" style={{ textDecoration: "none" }}>
          <EmailText>registration@mlb.com</EmailText>
        </a>
        .
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => navigate(RouteConstants.BASE)} variant="secondary">
          {t("translation:close")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default RegistrationPeriodClosedModal;
