import { Button, Modal } from "best-common-react";
import React from "react";
import { useTranslation } from "react-i18next";

type ChangeLanguageModalProps = {
  open: boolean;
  setOpen: (value: boolean) => void;
};

const ChangeLanguageModal: React.FC<ChangeLanguageModalProps> = ({ open, setOpen }) => {
  const { t, i18n } = useTranslation(["translation", "navigation"]);
  const isEs = i18n.language === "es";
  const toggleLanguage = async () => {
    if (isEs) {
      document.documentElement.lang = "en";
      return i18n.changeLanguage("en");
    } else {
      document.documentElement.lang = "es";
      return i18n.changeLanguage("es");
    }
  };

  const changeLanguage = async () => {
    await toggleLanguage();
    setOpen(false);
  };

  return (
    <Modal show={open}>
      <Modal.Header>{t("navigation:changeLanguage.name")}</Modal.Header>
      <Modal.Body>{t("navigation:changeLanguage.confirmation")}</Modal.Body>
      <Modal.Footer>
        <Button onClick={changeLanguage} className="me-1" variant="primary">
          {t("navigation:changeLanguage.yes")}
        </Button>
        <Button onClick={() => setOpen(false)} variant="secondary">
          {t("navigation:changeLanguage.cancel")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ChangeLanguageModal;
