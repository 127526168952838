import React, { useEffect, useState } from "react";
import {
  Email,
  Input,
  Label,
  PhoneNumber,
  RadioGroup,
  RequiredIndicator,
  Select,
  useBreakpoints,
  ValueOpt,
} from "best-common-react";
import styled from "styled-components";
import { ContactType, Country } from "../../types/Metadata";
import { useMetadata } from "../../contexts/MetadataContext";
import { useDropdowns } from "../../contexts/DropdownsContext";
import { useTranslation } from "react-i18next";
import { RadioLabelValue, RadioValue } from "best-common-react/lib/esm/types";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const PhoneCountryCodeContainer = styled.div`
  flex-basis: 18%;
  margin-right: 4px;
`;

const PhoneNumberContainer = styled.div`
  flex-basis: 78%;
`;

type EditSignerContactPreferenceProps = {
  name: string;
  contactTypeId?: ContactType;
  setContactTypeId: (value?: ContactType) => void;
  email?: string;
  setEmail: (value?: string) => void;
  phoneNumberCountryId?: number;
  setPhoneNumberCountryId?: (value?: number) => void;
  phoneNumber?: string;
  setPhoneNumber?: (value?: string) => void;
  setContactValid?: (value: boolean) => void;
};

const EditSignerContactPreference: React.FC<EditSignerContactPreferenceProps> = ({
  name,
  contactTypeId,
  setContactTypeId,
  email,
  setEmail,
  phoneNumberCountryId,
  setPhoneNumberCountryId,
  phoneNumber,
  setPhoneNumber,
  setContactValid,
}) => {
  const { t } = useTranslation(["login", "prospect", "consent-form", "translation"]);
  const { isMobile } = useBreakpoints();
  const { countries } = useMetadata();
  const { countryOptions, getCountryOptions } = useDropdowns();
  const [whatsappCountry, setWhatsappCountry] = useState<Country>();

  const selectOptions: ValueOpt<ContactType>[] = [
    {
      label: t("prospect:emailAddress"),
      value: ContactType.EMAIL,
    },
    {
      label: "WhatsApp",
      value: ContactType.WHATSAPP,
    },
    {
      label: t("prospect:textMessage"),
      value: ContactType.SMS,
    },
  ];

  const radioOptions: RadioLabelValue[] = [
    {
      label: t("prospect:emailAddress"),
      value: ContactType.EMAIL,
    },
    {
      label: "WhatsApp",
      value: ContactType.WHATSAPP,
    },
    {
      label: t("prospect:textMessage"),
      value: ContactType.SMS,
    },
  ];

  useEffect(() => {
    if (phoneNumberCountryId) {
      setWhatsappCountry(countries.find((c) => c.countryId === phoneNumberCountryId));
    } else {
      setWhatsappCountry(undefined);
    }
  }, [phoneNumberCountryId]);

  const setValidEmail = (value: boolean) => {
    if (contactTypeId == ContactType.EMAIL) {
      setContactValid(value);
    }
  };

  const setValidPhoneNumber = (value: boolean) => {
    if (contactTypeId == ContactType.WHATSAPP) {
      setContactValid(value);
    }
  };

  return (
    <Container>
      {isMobile ? (
        <Select
          id={`singer-${name}-contact-type`}
          name={`${name} Contact Preference`}
          label={`${name} ${t("consent-form:contactPreference")}`}
          value={selectOptions.find((opt) => opt.value === contactTypeId)}
          options={selectOptions}
          onChange={(value: ValueOpt<ContactType>) => {
            setContactTypeId(value?.value);
          }}
          placeholder={t("translation:selectPlaceholder")}
          required={true}
        />
      ) : (
        <RadioGroup
          id={`singer-${name}-contact-type`}
          name={`${name} Contact Preference`}
          label={`${name} ${t("consent-form:contactPreference")}`}
          value={contactTypeId as number}
          options={radioOptions}
          onChange={(value?: RadioValue) => {
            setContactTypeId(value as ContactType);
          }}
          required={true}
        />
      )}

      <div>
        {contactTypeId === 1 && (
          <Email
            id={`singer-${name}-email-address`}
            label={t("prospect:contactEmail")}
            value={email}
            className="mb-2"
            required
            onChange={(value) => {
              setEmail(value as string);
            }}
            invalidMessage={t("login:invalidEmail")}
            setValidEmail={setValidEmail}
          />
        )}
        {(contactTypeId === ContactType.WHATSAPP || contactTypeId === ContactType.SMS) && (
          <div>
            <Select
              id={`singer-${name}-whatsapp-country-select`}
              label={
                contactTypeId === ContactType.WHATSAPP ? t("prospect:whatsAppCountryCode") : t("prospect:countryCode")
              }
              className="pb-2"
              required
              value={getCountryOptions(phoneNumberCountryId)}
              options={countryOptions}
              onChange={(selected: ValueOpt<Country>) => {
                setPhoneNumberCountryId(selected?.value.countryId);
              }}
              placeholder={t("translation:selectPlaceholder")}
            />
            <div>
              <Label htmlFor="phone-number">
                {t("prospect:phoneNumber")}
                <RequiredIndicator />
              </Label>
              <div className="d-flex">
                <PhoneCountryCodeContainer>
                  <Input
                    id={`singer-${name}-phone-number-dial-code`}
                    label=""
                    disabled
                    required
                    styles={{ input: { height: "39px" } }}
                    value={"+" + whatsappCountry?.dialCode}
                    onChange={() => {
                      return; // unused
                    }}
                  />
                </PhoneCountryCodeContainer>
                <PhoneNumberContainer>
                  <PhoneNumber
                    id={`singer-${name}-whatsapp-input`}
                    label=""
                    required
                    value={phoneNumber}
                    countryCode={whatsappCountry?.countryCode}
                    invalidMessage="Invalid Phone/WhatsApp Number"
                    onChange={(value) => {
                      setPhoneNumber(value as string);
                    }}
                    setValidPhoneNumber={setValidPhoneNumber}
                  />
                </PhoneNumberContainer>
              </div>
            </div>
          </div>
        )}
      </div>
    </Container>
  );
};

export default EditSignerContactPreference;
