import React, { useContext } from "react";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  Checkbox,
  constructPhoneNumber,
  SubSection,
  useBreakpoints,
} from "best-common-react";
import { Item, Label, Row, Value } from "./ReadOnlyProfileStyles";
import { ThemeContext } from "styled-components";
import { useTranslation } from "react-i18next";
import { useProspectProfile } from "../../../contexts/ProsepctProfileContext";
import { ContactType } from "../../../types/Metadata";
import { useMetadata } from "../../../contexts/MetadataContext";
import { CountryCode as LibCountryCode } from "libphonenumber-js/types";
import { ProspectRelativeDTO } from "../../../types/ProspectProfile";
import useLanguage from "../../../hooks/useLanguage";

type ProspectRelativeReadOnlyProps = {
  relative: ProspectRelativeDTO;
  type: string;
};

const ProspectRelativeReadOnly: React.FC<ProspectRelativeReadOnlyProps> = ({ relative, type }) => {
  const { t } = useTranslation(["common", "profile", "prospect"]);
  const { isMobile } = useBreakpoints();
  const { countries, nationalIDTypes } = useMetadata();
  const { isEs } = useLanguage();

  const getCountryName = (countryId: number): string => {
    if (!countryId) return "";
    const country = countries.find((country) => country.countryId === countryId);
    if (isEs && !!country.esDescription) {
      return country.esDescription;
    } else {
      return country.description;
    }
  };

  const getCountryDialCode = (countryId: number): string => {
    return countries.find((country) => country.countryId === countryId).dialCode;
  };

  const getCountryCode = (countryId: number): LibCountryCode => {
    return countries.find((country) => country.countryId === countryId).countryCode;
  };

  const getNationalIdTypeDescription = (nationalIdTypeId: number): string => {
    if (!nationalIdTypeId) return "";
    return nationalIDTypes.find((idType) => idType.nationalIdTypeId === nationalIdTypeId).description;
  };

  const ContactTypes: { [typeId: number]: string } = {
    1: t("prospect:emailAddress"),
    2: "WhatsApp",
    3: t("prospect:textMessage"),
  };

  if (!relative) {
    return undefined;
  } else if (!relative.hasRelative) {
    return (
      <div>
        <Checkbox
          id={`${type}-disable`}
          checked={true}
          disabled={true}
          label={t("profile:noParent")}
          onChange={() => {
            // do nothing
          }}
        />
      </div>
    );
  } else {
    return (
      <div>
        <Row>
          <Item isMobile={isMobile}>
            <Label>{t("profile:firstName")}</Label>
            <Value>{relative.firstName}</Value>
          </Item>
          <Item isMobile={isMobile}>
            <Label>{t("profile:lastName")}</Label>
            <Value>{relative.lastName}</Value>
          </Item>
        </Row>

        <Row>
          <Item isMobile={isMobile}>
            <Label>{t("profile:contactPreference")}</Label>
            <Value>{!!relative.contactTypeId ? ContactTypes[relative.contactTypeId] : ""}</Value>
          </Item>
          {relative.contactTypeId === ContactType.EMAIL && (
            <Item isMobile={isMobile}>
              <Label>{t("prospect:emailAddress")}</Label>
              <Value>{relative.email}</Value>
            </Item>
          )}
          {relative.contactTypeId === ContactType.WHATSAPP && (
            <Item isMobile={isMobile}>
              <Label>{t("prospect:emailAddress")}</Label>
              <Value>
                +{getCountryDialCode(relative.phoneNumberCountryId)}{" "}
                {constructPhoneNumber(
                  relative.phoneNumber,
                  getCountryCode(relative.phoneNumberCountryId),
                )?.formatNational()}
              </Value>
            </Item>
          )}
          {relative.contactTypeId === ContactType.SMS && (
            <Item isMobile={isMobile}>
              <Label>{t("prospect:phoneNumber")}</Label>
              <Value>
                +{getCountryDialCode(relative.phoneNumberCountryId)}{" "}
                {constructPhoneNumber(
                  relative.phoneNumber,
                  getCountryCode(relative.phoneNumberCountryId),
                )?.formatNational()}
              </Value>
            </Item>
          )}
        </Row>

        <Row>
          <Item isMobile={isMobile}>
            <Label>{t("profile:country")}</Label>
            <Value>{getCountryName(relative.countryId)}</Value>
          </Item>
          <Item isMobile={isMobile}>
            <Label>{t("profile:city")}</Label>
            <Value>{relative.city}</Value>
          </Item>
        </Row>
        <Row>
          <Item isMobile={isMobile}>
            <Label>{t("profile:stateTerritory")}</Label>
            <Value>{relative.state}</Value>
          </Item>
          <Item isMobile={isMobile}>
            <Label>{t("profile:streetAddress")}</Label>
            <Value>{relative.address}</Value>
          </Item>
        </Row>

        <Row>
          <Item isMobile={isMobile}>
            <Label>{t("profile:nationalIdType")}</Label>
            <Value>{getNationalIdTypeDescription(relative.nationalIdTypeId)}</Value>
          </Item>
          <Item isMobile={isMobile}>
            <Label>{t("profile:nationalId")}</Label>
            <Value>{relative.nationalId}</Value>
          </Item>
        </Row>
      </div>
    );
  }
};

const ProspectRelativesReadOnly: React.FC = () => {
  const Theme = useContext(ThemeContext);
  const { t } = useTranslation(["common", "profile", "prospect"]);
  const { profile } = useProspectProfile();

  return (
    <Accordion
      accordionKey="basball-info"
      styles={{ ...Theme.accordion, body: { ...Theme.accordion.body, background: Theme.colors.cloud, border: "none" } }}
    >
      <AccordionHeader>{t("profile:prospectRelativesSection")}</AccordionHeader>
      <AccordionBody>
        <SubSection header={t("profile:mother")}>
          <ProspectRelativeReadOnly relative={profile.mother} type="mother" />
        </SubSection>

        <SubSection header={t("profile:father")}>
          <ProspectRelativeReadOnly relative={profile.father} type="father" />
        </SubSection>
      </AccordionBody>
    </Accordion>
  );
};

export default ProspectRelativesReadOnly;
