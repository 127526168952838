import React, { ReactNode } from "react";
import Divider from "./Divider";
import styled from "styled-components";
import { useBreakpoints } from "best-common-react";

type ButtonsContainerProps = {
  isMobile: boolean;
};

const ButtonsContainer = styled.div<ButtonsContainerProps>`
  display: flex;
  flex-direction: ${(props) => (props.isMobile ? "column" : "row")};
  width: 100%;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
  padding: 2rem;
`;

type IplContentButtonsProps = {
  children?: ReactNode;
};

const IplContentButtons: React.FC<IplContentButtonsProps> = ({ children }) => {
  const { isMobile } = useBreakpoints();

  return (
    <div>
      <Divider />
      <ButtonsContainer isMobile={isMobile}>{children}</ButtonsContainer>
    </div>
  );
};

export default IplContentButtons;
