import React from "react";
import SectionCompleteModal from "./SectionCompleteModal";
import DocumentsIcon from "../icons/DocumentsIcon";
import { useTranslation } from "react-i18next";

type DocumentsCompleteModalProps = {
  guardianOutstanding: boolean;
};

const DocumentsCompleteModal: React.FC<DocumentsCompleteModalProps> = ({ guardianOutstanding }) => {
  const { t } = useTranslation(["home", "documents", "translation"]);

  return (
    <SectionCompleteModal
      headerIcon={<DocumentsIcon />}
      headerText={t("documents:documentsSubmitted")}
      buttonText={t("translation:close")}
    >
      <div>
        {guardianOutstanding ? t("home:documentsGuardianOutstandingModalBody") : t("home:documentsCompleteModalBody")}
      </div>
    </SectionCompleteModal>
  );
};

export default DocumentsCompleteModal;
