import React from "react";
import Header from "../components/navigation/Header";
import ConsentFormIcon from "../components/icons/ConsentFormIcon";
import IPLContentContainer from "../components/display/IPLContentContainer";
import { Button, downloadFile, useLoading } from "best-common-react";
import IplContentButtons from "../components/display/IplContentButtons";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { downloadProspectOptionalConsentForm } from "../api/AuthenticatedClient";
import { RouteConstants } from "../constants/RouteConstants";
import { useNavigate } from "react-router-dom";

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

const Body = styled.div`
  padding: 1rem;
  width: 100%;
  min-height: 25rem;
`;

const Card = styled.div`
  padding: 0.5rem;
  border: 1px solid ${(props) => props.theme.colors.fog};
  background: ${(props) => props.theme.colors.white};
`;

const CardInfo = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: center;
`;

const ProspectOptionalConsentForm: React.FC = () => {
  const { t } = useTranslation(["consent-form", "documents", "prospect", "translation"]);
  const { setLoading } = useLoading();
  const navigate = useNavigate();

  const onPrintConsentForm = async () => {
    try {
      setLoading(true);
      const resp = await downloadProspectOptionalConsentForm();
      downloadFile(resp);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const onClose = () => {
    navigate(RouteConstants.BASE);
  };

  return (
    <Container>
      <Header icon={<ConsentFormIcon />} title={t("consent-form:consentForm")} />
      <IPLContentContainer>
        <Body>
          <Card>
            <CardInfo>
              <Button onClick={onPrintConsentForm} size="lg" variant="primary" className="mt-3 mb-4">
                {t("prospect:printConsentForm")}
              </Button>
            </CardInfo>
          </Card>
        </Body>
        <IplContentButtons>
          <Button variant="secondary" className="w-100" size="lg" onClick={onClose}>
            {t("translation:close")}
          </Button>
        </IplContentButtons>
      </IPLContentContainer>
    </Container>
  );
};

export default ProspectOptionalConsentForm;
