import React from "react";
import { EnvironmentType, NavigationConfigType, NavigationV2 } from "best-common-react";
import dplLogo from "../../assets/prospect-link-logo.png";

const env = window.MLBBest.envVariables.ENV as EnvironmentType;

const UnauthenticatedNavigation: React.FC = () => {
  const config: NavigationConfigType = {
    username: "",
    env: env,
    builds: [],
    title: "IPL",
    displayEnvIndicator: false,
    loggedIn: false,
    endLogo: {
      logo: dplLogo,
      visible: true,
    },
    navigationLinks: [],
    usernameDropdownLinks: [],
  };

  return <NavigationV2 config={config} />;
};

export default UnauthenticatedNavigation;
