import styled from "styled-components";

export const SignerCardContainer = styled.div`
  display: flex;
  justify-content: space-between;
  border: 1px solid ${(props) => props.theme.colors.fog};
  padding: 0.5rem;
  background: ${(props) => props.theme.colors.white};
  font-weight: 300;
`;

export const SignerCardColumn = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const SignerCardTitle = styled.div`
  font-size: 1.25rem;
`;

type SignerCardStatusProps = {
  signed: boolean;
};

export const SignerCardStatus = styled.div<SignerCardStatusProps>`
  font-size: 1rem;
  color: ${(props) => (props.signed ? props.theme.colors.green : props.theme.colors["mlb-red"])};
`;

export const ConsentFormSignedIconContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ConsentFormSignedIcon = styled.i.attrs({
  className: "fa fa-check-circle",
})`
  font-size: 1rem;
  color: ${(props) => props.theme.colors["green"]};
`;

export const RequiredText = styled.div`
  font-size: 0.75rem;
  color: ${(props) => props.theme.colors["mlb-red"]};
`;
