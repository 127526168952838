import React, { useEffect, useState } from "react";
import Header from "../components/navigation/Header";
import ConsentFormIcon from "../components/icons/ConsentFormIcon";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import IPLContentContainer from "../components/display/IPLContentContainer";
import { Button, downloadFile, FileAttachment, useLoading } from "best-common-react";
import IplContentButtons from "../components/display/IplContentButtons";
import { RouteConstants } from "../constants/RouteConstants";
import { useNavigate } from "react-router-dom";
import { ACCEPTED_FILE_TYPES } from "../util/ProfileUtils";
import {
  acknowledgeDrugTestLanguage,
  downloadProspectTppConsentForm,
  getProspectTppConsentForm,
  uploadProspectTppConsentForm,
} from "../api/AuthenticatedClient";
import { FileUploadDTO } from "../types/Documents";
import { ProspectTppConsentFormDTO } from "../types/ConsentForm";
import UploadedProspectDocument from "../components/documents/UploadedProspectDocument";
import DrugTestAcknowledgementModal from "../components/consent-form/DrugTestAcknowledgementModal";

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

const Body = styled.div`
  padding: 1rem;
  width: 100%;
  min-height: 25rem;
`;

const Card = styled.div`
  padding: 0.5rem;
  border: 1px solid ${(props) => props.theme.colors.fog};
  background: ${(props) => props.theme.colors.white};
`;

const CardInfo = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: center;
`;

type SubHeaderProps = {
  isComplete: boolean;
};

const SubHeader = styled.div<SubHeaderProps>`
  font-size: 1rem;
  color: ${(props) => (props.isComplete ? props.theme.colors.green : props.theme.colors["mlb-red"])};
`;

const ProspectTppConsentForm: React.FC = () => {
  const { t } = useTranslation(["consent-form", "documents", "prospect", "translation"]);
  const navigate = useNavigate();
  const { setLoading } = useLoading();
  const [tppConsentForm, setTppConsentForm] = useState<ProspectTppConsentFormDTO>();
  const [subHeader, setSubHeader] = useState<string>("");
  const [showDrugTestAcknowledgementModal, setShowDrugTestAcknowledgementModal] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    getProspectTppConsentForm()
      .then(setTppConsentForm)
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (tppConsentForm) {
      setSubHeader(
        !!tppConsentForm.completedTs ? t("translation:signed") : "Please download, sign and upload consent form.",
      );
    }
  }, [tppConsentForm]);

  const onClose = () => {
    navigate(RouteConstants.BASE);
  };

  const onPrintConsentForm = () => {
    if (tppConsentForm?.hasAcknowledgedDrugTestLanguage === true) {
      printConsentFormCall();
    } else {
      setShowDrugTestAcknowledgementModal(true);
    }
  };

  const printConsentFormCall = async () => {
    try {
      setLoading(true);
      const resp = await downloadProspectTppConsentForm();
      downloadFile(resp);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const onDrugTestConsent = async () => {
    await acknowledgeDrugTestLanguage();
    setShowDrugTestAcknowledgementModal(false);
    setTppConsentForm({ ...tppConsentForm, hasAcknowledgedDrugTestLanguage: true });
    await printConsentFormCall();
  };

  const uploadConsentForm = async (files: File[]) => {
    if (files?.length > 0) {
      setLoading(true);

      const filesMapped = files.map((file: File) => {
        const reader = new FileReader();
        return new Promise((resolve) => {
          reader.onload = () =>
            resolve({
              fileName: file.name,
              fileData: reader.result,
            } as FileUploadDTO);
          reader.readAsDataURL(file);
        });
      });
      const filesToSave = await Promise.all(filesMapped);
      const promises = filesToSave.map(
        (uploadDTO: FileUploadDTO) =>
          new Promise((resolve) => {
            resolve(uploadProspectTppConsentForm(uploadDTO));
          }),
      );

      const uploaded = await Promise.all(promises);
      setTppConsentForm(uploaded[0] as ProspectTppConsentFormDTO);

      setLoading(false);
    }
  };

  return (
    <>
      <DrugTestAcknowledgementModal
        open={showDrugTestAcknowledgementModal}
        onAcknowledge={onDrugTestConsent}
        onClose={() => setShowDrugTestAcknowledgementModal(false)}
      />
      <Container>
        <Header
          icon={<ConsentFormIcon />}
          title={t("consent-form:consentForm")}
          subTitle={<SubHeader isComplete={!!tppConsentForm?.completedTs}>{subHeader}</SubHeader>}
        />
        <IPLContentContainer>
          <Body>
            <Card>
              <CardInfo>
                <Button onClick={onPrintConsentForm} size="lg" variant="primary" className="mt-3 mb-4">
                  {t("prospect:printConsentForm")}
                </Button>
              </CardInfo>

              <div className="w-100">
                <div className="d-flex flex-column align-items-center">
                  <FileAttachment
                    id="prospect-tpp-consent-form"
                    className="w-100"
                    placeholder={t("documents:dropFilesHere")}
                    fileTypes={ACCEPTED_FILE_TYPES}
                    maxFiles={10}
                    files={[]}
                    onChange={(files: File[]) => uploadConsentForm(files)}
                    showTable={false}
                    browseButtonText={t("translation:browse")}
                    disabled={false}
                  />
                </div>
                {!!tppConsentForm && !!tppConsentForm.completedTs && (
                  <UploadedProspectDocument
                    fileName={tppConsentForm.signedFileName}
                    resourceUrl={tppConsentForm.signedResourceUrl}
                    canDelete={false}
                  />
                )}
              </div>
            </Card>
          </Body>
          <IplContentButtons>
            <Button variant="secondary" className="w-100" size="lg" onClick={onClose}>
              {t("translation:close")}
            </Button>
          </IplContentButtons>
        </IPLContentContainer>
      </Container>
    </>
  );
};

export default ProspectTppConsentForm;
