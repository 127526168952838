import React from "react";
import { useNavigate } from "react-router-dom";
import { RouteConstants } from "../../../constants/RouteConstants";
import { Button, Modal } from "best-common-react";
import { useTranslation } from "react-i18next";

type InvalidRegistrationCountryWarningModalProps = {
  show: boolean;
};

const InvalidRegistrationCountryWarningModal: React.FC<InvalidRegistrationCountryWarningModalProps> = ({ show }) => {
  const { t } = useTranslation(["translation", "registration"]);
  const navigate = useNavigate();

  const onContinue = () => {
    navigate(RouteConstants.BASE);
  };

  return (
    <Modal show={show}>
      <Modal.Header>{t("translation:warning")}</Modal.Header>
      <Modal.Body>
        <div>
          {t("registration:invalidCountryModalBody")}{" "}
          <a href={`mailto:${t("registration:email")}`}>{t("registration:email")}</a>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onContinue} variant="primary">
          {t("translation:continue")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default InvalidRegistrationCountryWarningModal;
