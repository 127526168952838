import styled from "styled-components";
import {
  BaseballInformationDTO,
  BioInformationDTO,
  ContactInformationDTO,
  ProspectAddressDTO,
  ProspectNameDTO,
  ProspectRelativeDTO,
  ProspectRelativesDTO,
  TrainerInformationDTO,
} from "../types/ProspectProfile";
import { PhoneNumberDTO } from "../types/Profile";
import { isValidPhoneNumber } from "best-common-react";
import { Country, CountryIds, NationalIDType } from "../types/Metadata";
import validate from "validate.js";
import { REQUIRED_NATIONAL_ID_COUNTRIES, REQUIRED_ZIP_CODE_COUNTRIES } from "../constants/ProfileConstants";
import { CountryCode } from "libphonenumber-js/types";

export const validateProspectName = (profile: ProspectNameDTO): boolean => {
  if (!profile) return false;
  return (
    !!profile.firstName?.length &&
    !!profile.middleName?.length &&
    !!profile.lastName?.length &&
    !!profile.extLastName?.length
  );
};

export const validateBioInformation = (profile: BioInformationDTO): boolean => {
  if (!profile) return false;
  return (
    !!profile.registrationCountryId &&
    !!profile.dateOfBirth?.length &&
    profile.hasTwin != undefined &&
    (profile.hasTwin === false || profile.twinCount != undefined) &&
    !!profile.birthCountryId &&
    (!!profile.birthStateProvince?.length || !!profile.birthStateProvinceId) &&
    (!!profile.birthCity?.length || !!profile.birthCityId) &&
    (profile.birthCountryId !== CountryIds.CUBA ||
      (profile.livesInUsCanPr !== undefined && profile.livesInUsCanPr !== null)) &&
    profile.traveledToUsCanPrFlag != undefined &&
    (profile.traveledToUsCanPrFlag === false ||
      (profile.traveledToUsCanPrCount != undefined &&
        profile.longestStayUsCanPr != undefined &&
        !!profile.recentStayUsPrCan?.length &&
        (profile.birthCountryId !== CountryIds.CUBA || !!profile.travelDates.length)))
  );
};

export const validateBaseballInformation = (profile: BaseballInformationDTO, isCubanBorn: boolean): boolean => {
  if (!profile) return false;
  return (
    profile.positionId != undefined &&
    profile.throwingHandId != undefined &&
    !!profile.height &&
    profile.heightUnitId != undefined &&
    !!profile.weight &&
    profile.weightUnitId != undefined &&
    (!isCubanBorn ||
      (profile.participatedInProBaseball != undefined &&
        (profile.participatedInProBaseball === false || !!profile.professionalTeams.length)))
  );
};

export const validateProspectAddress = (profile: ProspectAddressDTO): boolean => {
  if (!profile) return false;
  return (
    !!profile.addressCountryId &&
    (!!profile.addressStateProvince?.length || !!profile.addressStateProvinceId) &&
    (!!profile.addressCity?.length || !!profile.addressCityId) &&
    !!profile.address1?.length &&
    (REQUIRED_ZIP_CODE_COUNTRIES.includes(profile.addressCountryId) ? !!profile.addressZipCode?.length : true)
  );
};

export const validateContactInformation = (profile: ContactInformationDTO): boolean => {
  if (!profile) return false;
  return !!profile.phoneNumbers?.length || !!profile.emailAddresses?.length;
};

export const validateProspectRelatives = (
  profile: ProspectRelativesDTO,
  countries: Country[],
  nationalIdTypes: NationalIDType[],
): boolean => {
  if (!profile) return false;
  const { mother, father } = profile;
  if (!mother || !father) return false;

  if (mother.hasRelative !== false) {
    const countryCode = countries.find((country) => country.countryId === mother.phoneNumberCountryId)?.countryCode;
    const nationalIdType = nationalIdTypes.find((type) => type.nationalIdTypeId === mother.nationalIdTypeId);
    if (!validateProspectRelative(mother, countryCode, nationalIdType)) {
      return false;
    }
  }

  if (father.hasRelative !== false) {
    const countryCode = countries.find((country) => country.countryId === father.phoneNumberCountryId)?.countryCode;
    const nationalIdType = nationalIdTypes.find((type) => type.nationalIdTypeId === father.nationalIdTypeId);
    if (!validateProspectRelative(father, countryCode, nationalIdType)) {
      return false;
    }
  }

  return true;
};

const validateProspectRelative = (
  relative: ProspectRelativeDTO,
  countryCode?: CountryCode,
  nationalIdType?: NationalIDType,
): boolean => {
  if (!relative.firstName?.length || !relative.lastName?.length) {
    return false;
  }

  // Entering phone number or whatsapp, but it is invalid
  if (
    (!!relative.phoneNumberCountryId || relative.phoneNumber?.length) &&
    !isValidPhoneNumber(relative.phoneNumber, countryCode)
  ) {
    return false;
  }
  // Entering whatsapp, but it is invalid
  else if (!!relative.email?.length && !isValidEmail(relative.email)) {
    return false;
  }
  // Hasn't entered either phone number, whatsapp or email
  else if ((!relative.phoneNumberCountryId || !relative.phoneNumber?.length) && !relative.email?.length) {
    return false;
  }

  // If the prospect has selected a country id then a valid address must be entered
  if (relative.countryId) {
    if (!relative.stateId && !relative.state?.length) {
      return false;
    }

    if (!relative.cityId && !relative.city?.length) {
      return false;
    }

    if (!relative.address?.length) {
      return false;
    }
  }

  // If the prospect has selected a national id type then a valid national id must be entered
  if (relative.nationalIdTypeId) {
    if (!relative.nationalId?.length) {
      return false;
    }

    if (!validateNationalId(relative.countryId, nationalIdType, relative.nationalId)) {
      return false;
    }
  }

  // Relative is valid
  return true;
};

export const validateTrainerInformation = (profile: TrainerInformationDTO): boolean => {
  if (!profile) return false;
  return profile.hasTrainer === false || !!profile.trainers?.length;
};

export const validateNationalId = (countryId: number, type?: NationalIDType, id?: string): boolean => {
  const typeId = type?.nationalIdTypeId;
  const format = type?.format;

  if (!REQUIRED_NATIONAL_ID_COUNTRIES.includes(countryId) && !typeId && !id?.length) {
    return true;
  } else if (!typeId) {
    return false;
  } else if (!!format) {
    if (!id?.length) {
      return true;
    } else {
      const regex = new RegExp(format);
      return regex.test(id);
    }
  } else {
    return !!id?.length;
  }
};

export const validatePhoneNumber = (phoneNumber: PhoneNumberDTO): boolean => {
  return (
    !!phoneNumber.countryId &&
    !!phoneNumber.phoneNumber &&
    isValidPhoneNumber(phoneNumber.phoneNumber, phoneNumber.countryCode) &&
    !!phoneNumber.phoneNumberTypeId &&
    !!phoneNumber.phoneNumberCommunicationTypeId
  );
};

export const checkZipcode = (zipcode: string, countryId: number): boolean => {
  let regex;
  switch (countryId) {
    // usa territories
    case 1:
    case 3:
    case 66:
      regex = new RegExp("^[0-9]{5}(?:-[0-9]{4})?$");
      return regex.test(zipcode);
    // canada
    case 2:
      regex = new RegExp("^(?!.*[DFIOQU])[A-VXY][0-9][A-Z] ?[0-9][A-Z][0-9]$");
      return regex.test(zipcode);
    default:
      return true;
  }
};

const isValidEmail = (email?: string): boolean => {
  if (!email || !email.length) return false;
  return !validate({ email }, { email: { email: true } });
};

type IsValidEndAdornerProps = {
  valid: boolean;
};

export const IsValidEndAdorner = styled.i<IsValidEndAdornerProps>`
  color: ${({ valid }) => (valid ? "green" : "red")};
`;
