import { RadioLabelValue, ToggleButtonOption, ValueOpt } from "best-common-react";
import React, { createContext, ReactNode, useContext, useEffect, useState } from "react";
import { getCities, getDistricts, getSectors } from "../api/UnauthenticatedClient";
import useLanguage from "../hooks/useLanguage";
import {
  BattingSide,
  City,
  CommunicationType,
  Country,
  CountryDialCode,
  District,
  EmailAddressType,
  IntlProfessionalLeague,
  MexicanLeagueTeam,
  NationalIDType,
  PhoneNumberCommunicationType,
  PhoneNumberType,
  Position,
  Province,
  Sector,
  State,
  ThrowingHand,
  UnitOfMeasurement,
  YesNo,
} from "../types/Metadata";
import { useMetadata } from "./MetadataContext";

// Height
const HEIGHT_MIN_CM = 121;
const HEIGHT_MAX_CM = 213;
const HEIGHT_MIN_FT = 4;
const HEIGHT_MAX_FT = 7;
const HEIGHT_MIN_IN = 0;
const HEIGHT_MAX_IN = 11;

// Weight
const WEIGHT_MIN_KG = 45;
const WEIGHT_MAX_KG = 140;
const WEIGHT_MIN_LB = 100;
const WEIGHT_MAX_LB = 310;

// Twin Count
const TWIN_COUNT_MIN = 1;
const TWIN_COUNT_MAX = 7;

type DropdownsContextType = {
  positionOptions: ValueOpt<Position>[];
  getPositionOption: (positionId: number) => ValueOpt<Position> | undefined;
  batsOptions: ValueOpt<BattingSide>[];
  getBatsOption: (batsId: number) => ValueOpt<BattingSide> | undefined;
  throwsOptions: ValueOpt<ThrowingHand>[];
  getThrowsOption: (throwsId: number) => ValueOpt<ThrowingHand> | undefined;
  heightCmOptions: ValueOpt<number>[];
  getHeightCmOption: (heightCm?: number) => ValueOpt<number> | undefined;
  heightFtOptions: ValueOpt<number>[];
  getHeightFtOption: (heightFt?: number) => ValueOpt<number> | undefined;
  heightInOptions: ValueOpt<number>[];
  getHeightInOption: (heightIn?: number) => ValueOpt<number> | undefined;
  weightKgOptions: ValueOpt<number>[];
  getWeightKgOption: (weightKg: number) => ValueOpt<number> | undefined;
  weightLbOptions: ValueOpt<number>[];
  getWeightLbOption: (weightLb: number) => ValueOpt<number> | undefined;
  nationalIDTypeOptions: ValueOpt<NationalIDType>[];
  getNationalIDTypeOptions: (nationalIDTypeId: number) => ValueOpt<NationalIDType> | undefined;
  yesNoRadioValues: RadioLabelValue[];
  getYesNoRadioValues: (value: boolean) => RadioLabelValue | undefined;
  countryOptions: ValueOpt<Country>[];
  getCountryOptions: (countryId: number) => ValueOpt<Country> | undefined;
  countryDialCodeOptions: ValueOpt<CountryDialCode>[];
  getCountryDialCodeOption: (countryId?: number) => ValueOpt<CountryDialCode> | undefined;
  stateOptions: ValueOpt<State>[];
  getStateOptions: (stateId: number) => ValueOpt<State> | undefined;
  provinceOptions: ValueOpt<Province>[];
  getProvinceOptions: (provinceId: number) => ValueOpt<Province> | undefined;
  retrieveDistrictsByCountryId: (countryId: number) => Promise<ValueOpt<District>[]>;
  getDistrictOptions: (districtsOptions: ValueOpt<District>[], districtId: number) => ValueOpt<District> | undefined;
  phoneNumberTypeOptions: ValueOpt<PhoneNumberType>[];
  phoneNumberTypeRadioOptions: RadioLabelValue[];
  getPhoneNumberTypeOption: (typeId: number) => ValueOpt<PhoneNumberType> | undefined;
  phoneNumberCommunicationTypeRadioOptions: RadioLabelValue[];
  emailAddressTypeOptions: ValueOpt<EmailAddressType>[];
  getEmailAddressTypeOption: (typeId: number) => ValueOpt<EmailAddressType> | undefined;
  retrieveCitiesByStateProvinceDistrictId: (stateProvinceDistrictId: number) => Promise<ValueOpt<City>[]>;
  getCitiesOptions: (citiesOptions: ValueOpt<City>[], cityId: number) => ValueOpt<City> | undefined;
  retrieveSectorsByCityId: (sectorId: number) => Promise<ValueOpt<Sector>[]>;
  getSectorOptions: (sectorOptions: ValueOpt<Sector>[], sectorId: number) => ValueOpt<Sector> | undefined;
  heightUnitOptions: ToggleButtonOption[];
  getHeightUnitOption: (unitId: number) => ToggleButtonOption;
  weightUnitOptions: ToggleButtonOption[];
  getWeightUnitOption: (unitId: number) => ToggleButtonOption;
  mexicanLeagueTeamOptions: ValueOpt<MexicanLeagueTeam>[];
  getMexicanLeagueTeamOptions: (teamId: string) => ValueOpt<MexicanLeagueTeam> | undefined;
  communicationTypeOptions: RadioLabelValue[];
  getCommunicationTypeOption: (typeId: number) => RadioLabelValue | undefined;
  twinOptions: ValueOpt<number>[];
  intlProfessionalLeagueOptions: ValueOpt<IntlProfessionalLeague>[];
};

const DropdownsContext = createContext<DropdownsContextType | undefined>(undefined);

type DropdownsProviderProps = {
  children?: ReactNode;
};

const DropdownsProvider: React.FC<DropdownsProviderProps> = ({ children }) => {
  const { isEs } = useLanguage();
  const {
    positions,
    nationalIDTypes,
    countries,
    dialCodes,
    states,
    provinces,
    throwingHands,
    battingSides,
    phoneNumberTypes,
    phoneNumberCommunicationTypes,
    emailAddressTypes,
    heightUnits,
    weightUnits,
    mexicanLeagueTeams,
    communicationTypes,
    intlProfessionalLeagues,
  } = useMetadata();

  const getLabel = (value: any, isEs: boolean): string => {
    if (isEs && !!value.esDescription?.length) {
      return value.esDescription;
    }
    return value.description;
  };

  const FILTER_POSITION_IDS: number[] = [4, 7, 9, 10, 12, 14, 15];
  const [positionOptions, setPositionOptions] = useState<ValueOpt<Position>[]>([]);
  useEffect(() => {
    //filter out unnecessary positions per https://baseball.atlassian.net/browse/IPL-268
    setPositionOptions(
      positions
        .filter((pos) => FILTER_POSITION_IDS.includes(pos.positionId))
        .map((position: Position) => ({ label: getLabel(position, isEs), value: position })),
    );
  }, [positions, isEs]);
  const getPositionOption = (positionId: number): ValueOpt<Position> | undefined => {
    return positionOptions.find((opt: ValueOpt<Position>) => opt.value.positionId === positionId);
  };

  const [nationalIDTypeOptions, setNationalIDTypeOptions] = useState<ValueOpt<NationalIDType>[]>([]);
  useEffect(() => {
    setNationalIDTypeOptions(
      nationalIDTypes.map((nationalIDType: NationalIDType) => ({
        label: nationalIDType.description,
        value: nationalIDType,
      })),
    );
  }, [nationalIDTypes]);
  const getNationalIDTypeOptions = (nationalIDTypeId: number): ValueOpt<NationalIDType> | undefined => {
    return nationalIDTypeOptions.find(
      (opt: ValueOpt<NationalIDType>) => opt.value.nationalIdTypeId === nationalIDTypeId,
    );
  };

  const [countryOptions, setCountryOptions] = useState<ValueOpt<Country>[]>([]);
  useEffect(() => {
    setCountryOptions(countries.map((country: Country) => ({ label: getLabel(country, isEs), value: country })));
  }, [countries, isEs]);
  const getCountryOptions = (countryId: number): ValueOpt<Country> | undefined => {
    return countryOptions.find((opt: ValueOpt<Country>) => opt.value.countryId === countryId);
  };

  const [countryDialCodeOptions, setCountryDialCodeOptions] = useState<ValueOpt<CountryDialCode>[]>([]);
  useEffect(() => {
    setCountryDialCodeOptions(
      dialCodes.map((countryCode: CountryDialCode) => ({ label: countryCode.country, value: countryCode })),
    );
  }, [dialCodes]);
  const getCountryDialCodeOption = (countryId?: number): ValueOpt<CountryDialCode> | undefined => {
    return countryDialCodeOptions.find((opt: ValueOpt<CountryDialCode>) => opt.value?.countryId === countryId);
  };

  const [mexicanLeagueTeamOptions, setMexicanLeagueTeamOptions] = useState<ValueOpt<MexicanLeagueTeam>[]>([]);
  useEffect(() => {
    setMexicanLeagueTeamOptions(
      mexicanLeagueTeams.map((team: MexicanLeagueTeam) => ({ label: team.name, value: team })),
    );
  }, [mexicanLeagueTeams]);
  const getMexicanLeagueTeamOptions = (teamId: string): ValueOpt<MexicanLeagueTeam> | undefined => {
    return mexicanLeagueTeamOptions.find((opt: ValueOpt<MexicanLeagueTeam>) => opt.value.id === teamId);
  };

  const [stateOptions, setStateOptions] = useState<ValueOpt<State>[]>([]);
  useEffect(() => {
    setStateOptions(states.map((state: State) => ({ label: getLabel(state, isEs), value: state })));
  }, [states, isEs]);
  const getStateOptions = (stateId: number): ValueOpt<State> | undefined => {
    return stateOptions.find((opt: ValueOpt<State>) => opt.value.stateId === stateId);
  };

  const [provinceOptions, setProvinceOptions] = useState<ValueOpt<Province>[]>([]);
  useEffect(() => {
    setProvinceOptions(provinces.map((province: Province) => ({ label: getLabel(province, isEs), value: province })));
  }, [provinces, isEs]);
  const getProvinceOptions = (provinceId: number): ValueOpt<Province> | undefined => {
    return provinceOptions.find((opt: ValueOpt<Province>) => opt.value.provinceId === provinceId);
  };

  const retrieveDistrictsByCountryId = async (countryId: number): Promise<ValueOpt<District>[]> => {
    try {
      if (countryId) {
        const districts = await getDistricts(countryId);
        return districts.map((district: District) => ({ label: getLabel(district, isEs), value: district }));
      }
    } catch (e) {
      console.error(e);
    }
    return [];
  };

  const getDistrictOptions = (
    districtsOptions: ValueOpt<District>[],
    districtId: number,
  ): ValueOpt<District> | undefined => {
    return districtsOptions?.find((opt: ValueOpt<District>) => opt.value.districtId === districtId);
  };

  const retrieveCitiesByStateProvinceDistrictId = async (
    stateProvinceDistrictId: number,
  ): Promise<ValueOpt<City>[]> => {
    const cities = await getCities(stateProvinceDistrictId);
    return cities.map((city) => ({ label: getLabel(city, isEs), value: city }));
  };

  const getCitiesOptions = (citiesOptions: ValueOpt<City>[], cityId: number): ValueOpt<City> | undefined => {
    return citiesOptions?.find((opt: ValueOpt<City>) => opt.value.cityId === cityId);
  };

  const retrieveSectorsByCityId = async (cityId: number): Promise<ValueOpt<Sector>[]> => {
    try {
      if (cityId) {
        const sectors = await getSectors(cityId);
        return sectors.map((sector: Sector) => ({ label: getLabel(sector, isEs), value: sector }));
      }
    } catch (e) {
      console.error(e);
    }
    return [];
  };

  const getSectorOptions = (sectorOptions: ValueOpt<Sector>[], sectorId: number): ValueOpt<Sector> | undefined => {
    return sectorOptions?.find((opt: ValueOpt<Sector>) => opt.value.sectorId === sectorId);
  };

  const [throwsOptions, setThrowsOptions] = useState<ValueOpt<ThrowingHand>[]>([]);
  useEffect(() => {
    setThrowsOptions(
      throwingHands.map((throwingHand: ThrowingHand) => ({ label: getLabel(throwingHand, isEs), value: throwingHand })),
    );
  }, [throwingHands, isEs]);
  const getThrowsOption = (throwsId: number): ValueOpt<ThrowingHand> | undefined => {
    return throwsOptions.find((opt: ValueOpt<ThrowingHand>) => opt.value.throwingHandId === throwsId);
  };

  const [batsOptions, setBatsOptions] = useState<ValueOpt<BattingSide>[]>([]);
  useEffect(() => {
    setBatsOptions(
      battingSides.map((battingSide: BattingSide) => ({ label: getLabel(battingSide, isEs), value: battingSide })),
    );
  }, [battingSides, isEs]);
  const getBatsOption = (batsId: number): ValueOpt<BattingSide> | undefined => {
    return batsOptions.find((opt: ValueOpt<BattingSide>) => opt.value.battingSideId === batsId);
  };

  // Height (cm)
  const [heightCmOptions, setHeightCmOptions] = useState<ValueOpt<number>[]>([]);
  if (heightCmOptions.length == 0) {
    // Create height options (121-213 cm)
    const defaultHeightCmOptions: ValueOpt<number>[] = [];
    for (let i = HEIGHT_MIN_CM; i <= HEIGHT_MAX_CM; i++) {
      defaultHeightCmOptions.push({ value: i, label: "" + i });
    }
    setHeightCmOptions(defaultHeightCmOptions);
  }
  const getHeightCmOption = (heightCm?: number): ValueOpt<number> | undefined => {
    return heightCmOptions.find((opt: ValueOpt<number>) => opt.value === heightCm);
  };

  // Height (ft)
  const [heightFtOptions, setHeightFtOptions] = useState<ValueOpt<number>[]>([]);
  if (heightFtOptions.length == 0) {
    const options: ValueOpt<number>[] = [];
    for (let i = HEIGHT_MIN_FT; i <= HEIGHT_MAX_FT; i++) {
      options.push({ value: i, label: "" + i });
    }
    setHeightFtOptions(options);
  }
  const getHeightFtOption = (heightFt?: number): ValueOpt<number> | undefined => {
    return heightFtOptions.find((opt: ValueOpt<number>) => opt.value === heightFt);
  };

  // Height (in)
  const [heightInOptions, setHeightInOptions] = useState<ValueOpt<number>[]>([]);
  if (heightInOptions.length == 0) {
    const defaultHeightInOptions: ValueOpt<number>[] = [];
    for (let i = HEIGHT_MIN_IN; i <= HEIGHT_MAX_IN; i++) {
      defaultHeightInOptions.push({ value: i, label: "" + i });
    }
    setHeightInOptions(defaultHeightInOptions);
  }
  const getHeightInOption = (heightIn?: number): ValueOpt<number> | undefined => {
    return heightInOptions.find((opt: ValueOpt<number>) => opt.value === heightIn);
  };

  // Weight (kg)
  const [weightKgOptions, setWeightKgOptions] = useState<ValueOpt<number>[]>([]);
  if (weightKgOptions.length == 0) {
    const defaultWeightKgOptions: ValueOpt<number>[] = [];
    for (let i = WEIGHT_MIN_KG; i <= WEIGHT_MAX_KG; i++) {
      defaultWeightKgOptions.push({ value: i, label: "" + i });
    }
    setWeightKgOptions(defaultWeightKgOptions);
  }
  const getWeightKgOption = (weightKg: number): ValueOpt<number> | undefined => {
    return weightKgOptions.find((opt: ValueOpt<number>) => opt.value === weightKg);
  };

  // Weight (lb)
  const [weightLbOptions, setWeightLbOptions] = useState<ValueOpt<number>[]>([]);
  if (weightLbOptions.length == 0) {
    const defaultWeightLbOptions: ValueOpt<number>[] = [];
    for (let i = WEIGHT_MIN_LB; i <= WEIGHT_MAX_LB; i++) {
      defaultWeightLbOptions.push({ value: i, label: "" + i });
    }
    setWeightLbOptions(defaultWeightLbOptions);
  }
  const getWeightLbOption = (weightLb: number): ValueOpt<number> | undefined => {
    return weightLbOptions.find((opt: ValueOpt<number>) => opt.value === weightLb);
  };

  const [yesNoRadioValues, setYesNoRadioValues] = useState<RadioLabelValue[]>([]);
  useEffect(() => {
    setYesNoRadioValues(
      YesNo.map((value) => ({ label: getLabel(value, isEs), value: value.value })).sort((a, b) =>
        a.value > b.value ? -1 : 1,
      ),
    );
  }, [YesNo, isEs]);

  const getYesNoRadioValues = (value?: boolean): RadioLabelValue | undefined => {
    if (value == undefined) return undefined;
    const valueAsInt = value ? 1 : 0;
    return yesNoRadioValues.find((opt: RadioLabelValue) => opt.value === valueAsInt);
  };

  const [phoneNumberTypeOptions, setPhoneNumberTypeOptions] = useState<ValueOpt<PhoneNumberType>[]>([]);
  useEffect(() => {
    setPhoneNumberTypeOptions(
      phoneNumberTypes.map((type: PhoneNumberType) => ({ label: getLabel(type, isEs), value: type })),
    );
  }, [phoneNumberTypes, isEs]);
  const [phoneNumberTypeRadioOptions, setPhoneNumberTypeRadioOptions] = useState<RadioLabelValue[]>([]);
  useEffect(() => {
    setPhoneNumberTypeRadioOptions(
      phoneNumberTypes.map((type: PhoneNumberType) => ({ label: getLabel(type, isEs), value: type.phoneNumberTypeId })),
    );
  }, [phoneNumberTypes, isEs]);
  const getPhoneNumberTypeOption = (typeId: number): ValueOpt<PhoneNumberType> | undefined => {
    return phoneNumberTypeOptions.find((opt: ValueOpt<PhoneNumberType>) => opt.value.phoneNumberTypeId === typeId);
  };

  const [phoneNumberCommunicationTypeRadioOptions, setPhoneNumberCommunicationTypeRadioOptions] = useState<
    RadioLabelValue[]
  >([]);
  useEffect(() => {
    setPhoneNumberCommunicationTypeRadioOptions(
      phoneNumberCommunicationTypes.map((type: PhoneNumberCommunicationType) => ({
        label: getLabel(type, isEs),
        value: type.phoneNumberCommunicationTypeId,
      })),
    );
  }, [phoneNumberCommunicationTypes, isEs]);

  const [emailAddressTypeOptions, setEmailAddressTypeOptions] = useState<ValueOpt<EmailAddressType>[]>([]);
  useEffect(() => {
    setEmailAddressTypeOptions(
      emailAddressTypes.map((type: EmailAddressType) => ({ label: getLabel(type, isEs), value: type })),
    );
  }, [emailAddressTypes, isEs]);
  const getEmailAddressTypeOption = (typeId: number): ValueOpt<EmailAddressType> | undefined => {
    return emailAddressTypeOptions.find((opt: ValueOpt<EmailAddressType>) => opt.value.emailAddressTypeId === typeId);
  };

  const [heightUnitOptions, setHeightUnitOptions] = useState<ToggleButtonOption[]>([]);
  useEffect(() => {
    setHeightUnitOptions(
      heightUnits.map((unit: UnitOfMeasurement) => ({ label: unit.abbreviation, value: unit.unitId })),
    );
  }, [heightUnits]);
  const getHeightUnitOption = (unitId: number): ToggleButtonOption => {
    return heightUnitOptions.find((opt: ToggleButtonOption) => opt.value === unitId);
  };

  const [weightUnitOptions, setWeightUnitOptions] = useState<ToggleButtonOption[]>([]);
  useEffect(() => {
    setWeightUnitOptions(
      weightUnits.map((unit: UnitOfMeasurement) => ({ label: unit.abbreviation, value: unit.unitId })),
    );
  }, [weightUnits]);
  const getWeightUnitOption = (unitId: number): ToggleButtonOption => {
    return weightUnitOptions.find((opt: ToggleButtonOption) => opt.value === unitId);
  };

  const [communicationTypeOptions, setCommunicationTypeOptions] = useState<RadioLabelValue[]>([]);
  useEffect(() => {
    setCommunicationTypeOptions(
      communicationTypes.map((type: CommunicationType) => ({
        label: getLabel(type, isEs),
        value: type.communicationMethodId,
      })),
    );
  }, [communicationTypes, isEs]);
  const getCommunicationTypeOption = (typeId: number): RadioLabelValue | undefined => {
    return communicationTypeOptions.find((opt: RadioLabelValue) => opt.value === typeId);
  };

  const [twinOptions, setTwinOptions] = useState([]);
  useEffect(() => {
    const temp: ValueOpt<number>[] = [];
    for (let i = TWIN_COUNT_MIN; i <= TWIN_COUNT_MAX; i++) {
      temp.push({ value: i, label: i.toString() });
    }
    setTwinOptions(temp);
  }, []);

  const [intlProfessionalLeagueOptions, setIntlProfessionalLeagueOptions] = useState<
    ValueOpt<IntlProfessionalLeague>[]
  >([]);
  useEffect(() => {
    setIntlProfessionalLeagueOptions(
      intlProfessionalLeagues.map((league: IntlProfessionalLeague) => ({
        label: getLabel(league, isEs),
        value: league,
      })),
    );
  }, [intlProfessionalLeagues, isEs]);

  return (
    <DropdownsContext.Provider
      value={{
        positionOptions,
        getPositionOption,
        throwsOptions,
        getThrowsOption,
        batsOptions,
        getBatsOption,
        heightCmOptions,
        getHeightCmOption,
        heightFtOptions,
        getHeightFtOption,
        heightInOptions,
        getHeightInOption,
        weightKgOptions,
        getWeightKgOption,
        weightLbOptions,
        getWeightLbOption,
        nationalIDTypeOptions,
        getNationalIDTypeOptions,
        yesNoRadioValues,
        getYesNoRadioValues,
        countryOptions,
        getCountryOptions,
        countryDialCodeOptions,
        getCountryDialCodeOption,
        stateOptions,
        getStateOptions,
        provinceOptions,
        getProvinceOptions,
        retrieveDistrictsByCountryId,
        getDistrictOptions,
        retrieveCitiesByStateProvinceDistrictId,
        getCitiesOptions,
        retrieveSectorsByCityId,
        getSectorOptions,
        phoneNumberTypeOptions,
        phoneNumberTypeRadioOptions,
        getPhoneNumberTypeOption,
        phoneNumberCommunicationTypeRadioOptions,
        emailAddressTypeOptions,
        getEmailAddressTypeOption,
        heightUnitOptions,
        getHeightUnitOption,
        weightUnitOptions,
        getWeightUnitOption,
        mexicanLeagueTeamOptions,
        getMexicanLeagueTeamOptions,
        communicationTypeOptions,
        getCommunicationTypeOption,
        twinOptions,
        intlProfessionalLeagueOptions,
      }}
    >
      {children}
    </DropdownsContext.Provider>
  );
};

const useDropdowns = (): DropdownsContextType => {
  const dropdownsContext: DropdownsContextType = useContext<DropdownsContextType>(DropdownsContext);
  if (dropdownsContext === undefined) {
    throw new Error(`useDropdowns must be used within a DropdownsProvider`);
  }
  return dropdownsContext;
};

export { DropdownsContext, DropdownsProvider, useDropdowns };
