import { AsSelectPlaceholders, DateInput, FormColumn, Input } from "best-common-react";
import { format, parse } from "date-fns";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { DATE_INPUT_MIN_DATE, dateFormats } from "../../../../util/ProfileUtils";
import { useProspectProfile } from "../../../../contexts/ProsepctProfileContext";
import CubanBornBioInfo from "./CubanBornBioInfo";
import RecentStayTooLongWarningModal from "./RecentStayTooLongWarningModal";

type TraveledToUsCanPrDetailsProps = {
  isCubanBorn: boolean;
};

const TraveledToUsCanPrDetails: React.FC<TraveledToUsCanPrDetailsProps> = ({ isCubanBorn }) => {
  const { t } = useTranslation(["profile", "translation"]);
  const { profile, updateProfileField } = useProspectProfile();
  const [showRecentStayTooLongWarning, setShowRecentStayTooLongWarning] = useState(false);
  const colWidth = 1;

  const dateDropdownPlaceholders: AsSelectPlaceholders = {
    day: t("prospect:day" as const),
    month: t("prospect:month" as const),
    year: t("prospect:year" as const),
  };

  const MONTHS: string[] = t("translation:months");

  return (
    <>
      <RecentStayTooLongWarningModal
        show={showRecentStayTooLongWarning}
        onClose={() => setShowRecentStayTooLongWarning(false)}
      />
      <div className="row pt-3">
        <FormColumn width={colWidth}>
          <Input
            id="traveled-to-us-can-pr-count"
            label={t("profile:usCanPrCount")}
            type="number"
            required
            value={profile?.traveledToUsCanPrCount !== null ? profile?.traveledToUsCanPrCount : ""}
            onChange={(value) => {
              if (value >= 0) {
                updateProfileField("traveledToUsCanPrCount", value);
              }
            }}
          />
        </FormColumn>
      </div>

      <div className="row pt-3">
        <FormColumn width={colWidth}>
          <Input
            id="longest-stay"
            label={t("profile:longestStayUsCanPr")}
            type="number"
            required
            value={profile?.longestStayUsCanPr !== null ? profile?.longestStayUsCanPr : ""}
            onChange={(value) => {
              if (value >= 365) {
                setShowRecentStayTooLongWarning(true);
              } else if (value >= 0) {
                updateProfileField("longestStayUsCanPr", value);
              } else {
                // do nothing
              }
            }}
          />
        </FormColumn>
      </div>

      <div className="row pt-3">
        <FormColumn width={colWidth}>
          <DateInput
            id="most-recent-stay"
            label={t("profile:mostRecentUsCanPr")}
            invalidDateMessage={t("translation:invalidDate")}
            asSelects
            asSelectsPlaceholders={dateDropdownPlaceholders}
            monthsValueOptions={MONTHS}
            required
            minDate={DATE_INPUT_MIN_DATE}
            value={
              !!profile?.recentStayUsPrCan
                ? parse(profile.recentStayUsPrCan, dateFormats.YYYY_MM_DD, new Date())
                : undefined
            }
            onChange={(value: Date | undefined) => {
              updateProfileField("recentStayUsPrCan", value ? format(value, dateFormats.YYYY_MM_DD) : null);
            }}
          />
        </FormColumn>
      </div>

      {isCubanBorn && <CubanBornBioInfo />}
    </>
  );
};

export default TraveledToUsCanPrDetails;
