import React, { useContext } from "react";
import { ThemeContext } from "styled-components";
import { Accordion, AccordionBody, AccordionHeader, useBreakpoints } from "best-common-react";
import { useTranslation } from "react-i18next";
import { Item, Label, Row, Value } from "./ReadOnlyProfileStyles";
import { useProspectProfile } from "../../../contexts/ProsepctProfileContext";
import TrainerDisplay from "../sections/trainer/TrainerDisplay";

const TrainerInfoReadyOnly: React.FC = () => {
  const Theme = useContext(ThemeContext);
  const { isMobile } = useBreakpoints();
  const { t } = useTranslation(["common", "profile"]);
  const { profile } = useProspectProfile();

  return (
    <Accordion
      accordionKey="basball-info"
      styles={{ ...Theme.accordion, body: { ...Theme.accordion.body, background: Theme.colors.cloud, border: "none" } }}
    >
      <AccordionHeader>{t("profile:trainerInformationSection")}</AccordionHeader>
      <AccordionBody>
        <Row>
          <Item isMobile={isMobile}>
            <Label>{t("profile:hasTrainer")}</Label>
            <Value>{profile.hasTrainer ? t("common:yes") : t("common:no")}</Value>
          </Item>
        </Row>

        <div>
          {profile.trainers.map((trainer) => (
            <TrainerDisplay key={`trainer-${trainer.trainerId}`} trainer={trainer} />
          ))}
        </div>
      </AccordionBody>
    </Accordion>
  );
};

export default TrainerInfoReadyOnly;
