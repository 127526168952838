import React, { useContext } from "react";
import { ThemeContext } from "styled-components";
import { Accordion, AccordionBody, AccordionHeader, useBreakpoints } from "best-common-react";
import { useTranslation } from "react-i18next";
import { Item, Label, Row, Value } from "./ReadOnlyProfileStyles";
import { useProspectProfile } from "../../../contexts/ProsepctProfileContext";
import { useMetadata } from "../../../contexts/MetadataContext";

const ProspectNationalIdReadOnly: React.FC = () => {
  const Theme = useContext(ThemeContext);
  const { isMobile } = useBreakpoints();
  const { t } = useTranslation(["common", "profile"]);
  const { profile } = useProspectProfile();
  const { nationalIDTypes } = useMetadata();

  const nationalIdType = nationalIDTypes.find((idType) => idType.nationalIdTypeId === profile.nationalIdTypeId);

  return (
    <Accordion
      accordionKey="basball-info"
      styles={{ ...Theme.accordion, body: { ...Theme.accordion.body, background: Theme.colors.cloud, border: "none" } }}
    >
      <AccordionHeader>{t("profile:nationalIdSection")}</AccordionHeader>
      <AccordionBody>
        <Row>
          <Item isMobile={isMobile}>
            <Label>{t("profile:nationalIdType")}</Label>
            <Value>{nationalIdType?.description}</Value>
          </Item>
          <Item isMobile={isMobile}>
            <Label>{t("profile:nationalId")}</Label>
            <Value>{profile.nationalId}</Value>
          </Item>
        </Row>
      </AccordionBody>
    </Accordion>
  );
};

export default ProspectNationalIdReadOnly;
