import React, { useEffect, useState } from "react";
import { Button } from "best-common-react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import DrugTestLanguage from "./DrugTestLanguage";

const Modal = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1055;
  background: white;
  overflow: hidden;
`;

const Body = styled.div`
  width: 100%;
  flex-grow: 1;
  overflow: auto;
  padding: 1rem;
`;

const Footer = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
  padding: 1rem;
  border-top: 1px solid black;
`;

type DrugTestAcknowledgementModalProps = {
  open: boolean;
  onAcknowledge: () => Promise<void>;
  onClose: () => void;
};

const DrugTestAcknowledgementModal: React.FC<DrugTestAcknowledgementModalProps> = ({
  open,
  onAcknowledge,
  onClose,
}) => {
  const { t } = useTranslation("translation");
  const [canContinue, setCanContinue] = useState(false);

  useEffect(() => {
    if (!open) {
      setCanContinue(false);
    }
  }, [open]);

  const onBodyScroll = (e: React.UIEvent<HTMLElement>) => {
    const bodyEl = e.currentTarget;
    if (bodyEl) {
      const { scrollTop, scrollHeight, clientHeight } = bodyEl;
      if (scrollTop + clientHeight >= scrollHeight - 50) {
        setCanContinue(true);
      }
    }
  };

  if (!open) return null;

  return (
    <Modal>
      <Body onScroll={onBodyScroll}>
        <DrugTestLanguage />
      </Body>
      <Footer>
        <Button onClick={onAcknowledge} disabled={!canContinue} className="me-1" variant="primary">
          {t("translation:continue")}
        </Button>
        <Button onClick={onClose} variant="secondary">
          {t("translation:cancel")}
        </Button>
      </Footer>
    </Modal>
  );
};

export default DrugTestAcknowledgementModal;
