import React, { useEffect, useState } from "react";
import { ConsentFormSignerDTO, ConsentFormSignerType } from "../../types/ConsentForm";
import {
  ConsentFormSignedIcon,
  ConsentFormSignedIconContainer,
  RequiredText,
  SignerCardColumn,
  SignerCardContainer,
  SignerCardStatus,
  SignerCardTitle,
} from "./SignerCardStyles";
import styled from "styled-components";
import { constructPhoneNumber } from "best-common-react";
import { useMetadata } from "../../contexts/MetadataContext";
import { useTranslation } from "react-i18next";
import EditGuardianContactInfoModal from "./EditGuardianContactInfoModal";
import { ContactType } from "../../types/Metadata";

const GuardianContactInfo = styled.div`
  font-size: 1rem;
  color: ${(props) => props.theme.colors.slate};
`;

const EditContactInformation = styled.div`
  font-size: 1rem;
  color: ${(props) => props.theme.colors.blue};
  cursor: pointer;
`;

export const EditIcon = styled.i.attrs({
  className: "fa fa-pencil",
})`
  margin-right: 0.5rem;
`;

type GuardianConsentFormSignerCardProps = {
  signer: ConsentFormSignerDTO;
  onUpdate: (updated: ConsentFormSignerDTO) => Promise<void>;
};

const GuardianConsentFormSignerCard: React.FC<GuardianConsentFormSignerCardProps> = ({ signer, onUpdate }) => {
  const { t } = useTranslation(["consent-form", "translation"]);
  const { countries } = useMetadata();
  const [contactInfo, setContactInfo] = useState("");
  const [showEditModal, setShowEditModal] = useState(false);

  useEffect(() => {
    if (signer.contactTypeId == ContactType.EMAIL) {
      setContactInfo(signer.email);
    } else if (signer?.contactTypeId == ContactType.WHATSAPP) {
      const country = countries.find((c) => c.countryId === signer.phoneNumberCountryId);
      const formattedPhoneNumber = constructPhoneNumber(signer.phoneNumber, country.countryCode)?.formatNational();
      setContactInfo(`+${country.dialCode} ${formattedPhoneNumber}`);
    } else {
      setContactInfo("");
    }
  }, [signer]);

  const name = `${t("consent-form:parentGuardian")} ${
    signer.signerTypeId == ConsentFormSignerType.GAURDIAN1 ? "1" : "2"
  }`;
  const hasSigned = !!signer.signedTs;

  return (
    <>
      <EditGuardianContactInfoModal
        name={name}
        signer={signer}
        open={showEditModal}
        onClose={() => setShowEditModal(false)}
        onSave={onUpdate}
      />
      <SignerCardContainer>
        <SignerCardColumn>
          <SignerCardTitle>{name}</SignerCardTitle>
          <SignerCardStatus signed={hasSigned}>
            {hasSigned ? t("translation:signed") : t("translation:notSigned")}
          </SignerCardStatus>
          <GuardianContactInfo>{contactInfo}</GuardianContactInfo>
          {!hasSigned && (
            <EditContactInformation onClick={() => setShowEditModal(true)}>
              <EditIcon />
              {t("consent-form:editContactInfo")}
            </EditContactInformation>
          )}
        </SignerCardColumn>
        {hasSigned ? (
          <ConsentFormSignedIconContainer>
            <ConsentFormSignedIcon />
          </ConsentFormSignedIconContainer>
        ) : (
          <SignerCardColumn>
            <RequiredText>* {t("translation:required")}</RequiredText>
          </SignerCardColumn>
        )}
      </SignerCardContainer>
    </>
  );
};

export default GuardianConsentFormSignerCard;
