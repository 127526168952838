import { datadogRum } from "@datadog/browser-rum";
import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import { BCR, EnvironmentType, Loading, Navy, ThemeType } from "best-common-react";
import "../i18next/i18n";
import { AuthenticationProvider } from "./contexts/AuthenticationContext";
import App from "./App";
import { Buffer } from "buffer";
import UnauthenticatedContexts from "./contexts/UnauthenticatedContexts";

// Import best-common-react stylesheet
import "best-common-react/lib/styles/bcr.css";

window.Buffer = window.Buffer || Buffer;

const env = window.MLBBest.envVariables.ENV as EnvironmentType;

datadogRum.init({
  applicationId: "9bdae6e1-c026-4da3-a9bd-946f014465bd",
  clientToken: "pubb5972045ea6123acddd7f4658409ac1e",
  site: "us5.datadoghq.com",
  service: "prospect-link-international-(prospect)",
  env: env,
  sampleRate: 100,
  sessionReplaySampleRate: 50,
  trackInteractions: true,
  defaultPrivacyLevel: "mask-user-input",
});

const IplTheme: ThemeType = {
  ...Navy,
  navigation: {
    ...Navy.navigation,
    horizontal: {
      ...Navy.navigation.horizontal,
      color: Navy.colors.white,
      centerLogo: {
        ...Navy.navigation.horizontal.centerLogo,
        height: "34px",
        width: "54px; margin-right: 6px;",
      },
    },
  },
  modal: {
    ...Navy.modal,
    backdropOpacity: 0.5,
  },
  stepper: {
    ...Navy.stepper,
    spacerWidth: 0,
  },
};

const container = document.getElementById("frontend-starter-app");
const root = createRoot(container);
root.render(
  <Suspense fallback={<Loading type="player" />}>
    <UnauthenticatedContexts>
      <AuthenticationProvider>
        <BCR theme={IplTheme}>
          <App />
        </BCR>
      </AuthenticationProvider>
    </UnauthenticatedContexts>
  </Suspense>,
);
