import clsx from "clsx";
import React, { ReactNode } from "react";
import styled from "styled-components";

const Container = styled.div`
  max-width: 768px;
`;

type IplContentContainerProps = {
  children?: ReactNode;
  className?: string;
};

const IPLContentContainer: React.FC<IplContentContainerProps> = ({ children, className = "" }) => {
  return <Container className={clsx("container", className)}>{children}</Container>;
};

export default IPLContentContainer;
