import React, { useEffect, useState } from "react";
import { Button, RadioGroup, RequiredIndicator, Select, useBreakpoints, ValueOpt } from "best-common-react";
import EditSignerContactPreference from "./EditSignerContactPreference";
import { RadioLabelValue } from "best-common-react/lib/esm/types";
import IPLContentContainer from "../display/IPLContentContainer";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { RouteConstants } from "../../constants/RouteConstants";
import { useConsentFormEsign } from "../../contexts/ConsentFormEsignContext";
import { ConsentFormGuardianSignersDTO, ConsentFormSignerDTO } from "../../types/ConsentForm";
import IplContentButtons from "../display/IplContentButtons";
import { useTranslation } from "react-i18next";
import { ContactType } from "../../types/Metadata";

const Label = styled.div`
  font-size: 1rem;
  color: ${(props) => props.theme.colors["mlb-navy"]};
`;

const Title = styled.div`
  font-size: 1rem;
  color: ${(props) => props.theme.colors["mlb-navy"]};
  margin-bottom: 0.5rem;
`;

const FieldsContainer = styled.div`
  min-height: 25rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

const WarningText = styled.div`
  font-size: 0.75rem;
  color: ${(props) => props.theme.colors["mlb-red"]};
`;

const selectOptions: ValueOpt<number>[] = [
  {
    label: "1",
    value: 1,
  },
  {
    label: "2",
    value: 2,
  },
];

const radioOptions: RadioLabelValue[] = [
  {
    label: "1",
    value: 1,
  },
  {
    label: "2",
    value: 2,
  },
];

type ValidContact = {
  guardian1: boolean;
  guardian2: boolean;
};

const GuardianSignerInfoForm: React.FC = () => {
  const { t } = useTranslation(["profile", "translation", "consent-form", "translation"]);
  const { isMobile } = useBreakpoints();
  const { guardianSigners, updateGuardianSignerField, updateMultipleGuardianSignerFields, saveGuardianSigners } =
    useConsentFormEsign();
  const { guardian1, guardian2 } = guardianSigners;
  const navigate = useNavigate();
  const [numGuardianSigners, setNumGuardianSingers] = useState<number>();
  const [validContact, setValidContact] = useState<ValidContact>({
    guardian1: false,
    guardian2: false,
  });
  const [canSave, setCanSave] = useState(false);

  const onNumGuardiansChange = (value: number) => {
    setNumGuardianSingers(value);
    if (value === 1) {
      updateMultipleGuardianSignerFields("guardian2", {
        contactTypeId: null,
        email: null,
        whatsappCountryId: null,
        whatsapp: null,
        required: false,
      });
    } else {
      updateGuardianSignerField("guardian2", "required", true);
    }
  };

  const onContactTypeChange = (guardian: keyof ConsentFormGuardianSignersDTO, value: ContactType) => {
    updateMultipleGuardianSignerFields(guardian, {
      contactTypeId: value,
      email: null,
      whatsappCountryId: null,
      whatsapp: null,
    });
  };

  const updateGuardian1 = (key: keyof ConsentFormSignerDTO, value: any) => {
    updateGuardianSignerField("guardian1", key, value);
  };

  const updateGuardian2 = (key: keyof ConsentFormSignerDTO, value: any) => {
    updateGuardianSignerField("guardian2", key, value);
  };

  useEffect(() => {
    const guardian1Valid =
      validContact.guardian1 &&
      (!!guardian1?.email?.length || (!!guardian1?.phoneNumberCountryId && !!guardian1?.phoneNumber?.length));
    const guardian2Valid =
      validContact.guardian2 &&
      (!!guardian2?.email?.length || (!!guardian2?.phoneNumberCountryId && !!guardian2?.phoneNumber?.length));
    setCanSave(numGuardianSigners != undefined && guardian1Valid && (numGuardianSigners == 2 ? guardian2Valid : true));
  }, [numGuardianSigners, guardian1, guardian2, validContact]);

  return (
    <IPLContentContainer>
      <FieldsContainer>
        <div>
          <div>
            <Label>
              {t("consent-form:numGuardianSignerQuestion")} <RequiredIndicator />
            </Label>
            {isMobile ? (
              <Select
                id="num-guardians-select"
                name="num-guardians-select"
                label=""
                value={selectOptions.find((opt) => opt.value === numGuardianSigners)}
                options={selectOptions}
                onChange={(value: ValueOpt<number>) => {
                  onNumGuardiansChange(value?.value);
                }}
                placeholder={t("translation:selectPlaceholder")}
              />
            ) : (
              <RadioGroup
                id="num-guardians-radio-group"
                label=""
                name="num-guardians-radio-group"
                className="pb-2"
                options={radioOptions}
                value={numGuardianSigners}
                onChange={(value) => {
                  onNumGuardiansChange(value as number);
                }}
              />
            )}
          </div>

          {numGuardianSigners === 1 && <WarningText>{t("consent-form:oneGuardianWarning")}</WarningText>}
        </div>

        {numGuardianSigners != undefined && (
          <div>
            <Title>{t("consent-form:guardianContactPreferenceQuestion")}</Title>
            <EditSignerContactPreference
              name={`${t("consent-form:parentGuardian")} 1`}
              contactTypeId={guardian1?.contactTypeId}
              setContactTypeId={(value) => {
                onContactTypeChange("guardian1", value);
              }}
              email={guardian1?.email}
              setEmail={(value) => {
                updateGuardian1("email", value);
              }}
              phoneNumberCountryId={guardian1?.phoneNumberCountryId}
              setPhoneNumberCountryId={(value) => {
                updateGuardian1("phoneNumberCountryId", value);
              }}
              phoneNumber={guardian1?.phoneNumber}
              setPhoneNumber={(value) => {
                updateGuardian1("phoneNumber", value);
              }}
              setContactValid={(value: boolean) => {
                setValidContact({ ...validContact, guardian1: value });
              }}
            />
          </div>
        )}
        {numGuardianSigners === 2 && (
          <EditSignerContactPreference
            name={`${t("consent-form:parentGuardian")} 2`}
            contactTypeId={guardian2?.contactTypeId}
            setContactTypeId={(value) => {
              onContactTypeChange("guardian2", value);
            }}
            email={guardian2?.email}
            setEmail={(value) => {
              updateGuardian2("email", value);
            }}
            phoneNumberCountryId={guardian2?.phoneNumberCountryId}
            setPhoneNumberCountryId={(value) => {
              updateGuardian2("phoneNumberCountryId", value);
            }}
            phoneNumber={guardian2?.phoneNumber}
            setPhoneNumber={(value) => {
              updateGuardian2("phoneNumber", value);
            }}
            setContactValid={(value: boolean) => {
              setValidContact({ ...validContact, guardian2: value });
            }}
          />
        )}
      </FieldsContainer>
      <IplContentButtons>
        <Button variant="secondary" className="w-100" size="lg" onClick={() => navigate(RouteConstants.BASE)}>
          {t("translation:close")}
        </Button>
        <Button variant="primary" className="w-100" size="lg" disabled={!canSave} onClick={saveGuardianSigners}>
          {t("profile:saveAndContinue")}
        </Button>
      </IplContentButtons>
    </IPLContentContainer>
  );
};

export default GuardianSignerInfoForm;
