import React, { useEffect, useState } from "react";
import {
  Checkbox,
  Email,
  FormColumn2Wide,
  Input,
  Label,
  NaOpt,
  PhoneNumber,
  RequiredIndicator,
  Select,
  ValueOpt,
} from "best-common-react";
import { useTranslation } from "react-i18next";
import { useDropdowns } from "../../../../contexts/DropdownsContext";
import {
  City,
  ContactType,
  Country,
  CountryIds,
  District,
  NationalIDType,
  Province,
  State,
} from "../../../../types/Metadata";
import styled from "styled-components";
import { CountryCode as LibCountryCode } from "libphonenumber-js/types";
import { ProspectAddressDTO, ProspectRelativeDTO } from "../../../../types/ProspectProfile";
import ProspectRelativeExistsModal from "./ProspectRelativeExistsModal";
import StateSelectOrTextInput from "../../fields/StateSelectOrTextInput";
import CitySelectOrTextInput from "../../fields/CitySelectOrTextInput";
import { useMetadata } from "../../../../contexts/MetadataContext";
import NationalIdInput from "../../fields/NationalIdInput";

const LabelTextContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: start;
`;

const PhoneCountryCodeContainer = styled.div`
  flex-basis: 22%;
  margin-right: 4px;
`;

const PhoneNumberContainer = styled.div`
  flex-basis: 78%;
`;

const Asterisk = styled.span`
  color: ${(props) => props.theme.colors["mlb-red"]};
  margin-left: 4px;
`;

type ProspectRelativeInputProps = {
  id: string;
  relative?: ProspectRelativeDTO;
  updateField: (key: string, value: any) => void;
  updateMultipleFields: (patch: { [p: string]: any }) => void;
  required: boolean;
  prospectAddress: ProspectAddressDTO;
};

const ProspectRelativeInput: React.FC<ProspectRelativeInputProps> = ({
  id,
  relative,
  updateField,
  updateMultipleFields,
  required,
  prospectAddress,
}) => {
  const { t } = useTranslation(["profile", "prospect", "translation", "login"]);
  const { countries } = useMetadata();
  const {
    countryOptions,
    getCountryOptions,
    stateOptions,
    getStateOptions,
    provinceOptions,
    getProvinceOptions,
    getDistrictOptions,
    retrieveDistrictsByCountryId,
    retrieveCitiesByStateProvinceDistrictId,
    nationalIDTypeOptions: allNationalIDTypeOptions,
    getNationalIDTypeOptions,
  } = useDropdowns();
  const [dialCode, setDialCode] = useState("");
  const [countryCode, setCountryCode] = useState<LibCountryCode>();
  const [showRelativeExistsModal, setShowRelativeExistsModal] = useState(false);
  const [stateProvinceDistrictOptions, setStateProvinceDistrictOptions] = useState<
    ValueOpt<State | Province | District>[]
  >([]);
  const [stateSelectIdField, setStateSelectIdField] = useState<string>();
  const [cityOptions, setCityOptions] = useState<ValueOpt<City>[]>([]);
  const [nationalIdTypeOptions, setNationalIdTypeOptions] =
    useState<ValueOpt<NationalIDType>[]>(allNationalIDTypeOptions);

  const fieldsRequired = relative?.hasRelative !== false;
  const fieldsDisabled = relative?.hasRelative === false;

  /* Contact Information */
  const ContactTypeOptions: ValueOpt<ContactType>[] = [
    {
      label: "WhatsApp",
      value: ContactType.WHATSAPP,
    },
    {
      label: t("prospect:emailAddress"),
      value: ContactType.EMAIL,
    },
    {
      label: t("prospect:textMessage"),
      value: ContactType.SMS,
    },
  ];

  const onContactTypeChange = (value?: ContactType) => {
    if (value !== relative?.contactTypeId) {
      updateMultipleFields({
        contactTypeId: value,
        phoneNumberCountryId: undefined,
        phoneNumber: "",
        email: "",
      });
    } else {
      updateField("contactTypeId", value);
    }
  };

  const onProspectGuardianDoesNotExist = () => {
    updateMultipleFields({
      hasRelative: false,
      firstName: "",
      lastName: "",
      contactTypeId: undefined,
      phoneNumberCountryId: undefined,
      phoneNumber: "",
      email: "",

      livesWithRelativeFlag: false,
      countryId: undefined,
      stateId: undefined,
      state: "",
      cityId: undefined,
      city: "",
      address: "",

      nationalIdTypeId: undefined,
      nationalId: "",
    });
    setShowRelativeExistsModal(false);
  };

  // Update dialCode and countryCode on country change
  useEffect(() => {
    if (relative?.phoneNumberCountryId) {
      const country = countryOptions.find((c) => c.value.countryId === relative?.phoneNumberCountryId);
      setDialCode(country?.value.dialCode || "");
      setCountryCode(country?.value.countryCode);
    } else {
      setDialCode("");
      setCountryCode(undefined);
    }
  }, [relative?.phoneNumberCountryId]);

  /* Relative Address */
  useEffect(() => {
    const countryId = relative?.countryId;
    if (!countryId) {
      setStateProvinceDistrictOptions([]);
      setStateSelectIdField(undefined);
    } else if (countryId === CountryIds.USA) {
      setStateProvinceDistrictOptions(stateOptions);
      setStateSelectIdField("stateId");
    } else if (countryId === CountryIds.CANADA) {
      setStateProvinceDistrictOptions(provinceOptions);
      setStateSelectIdField("provinceId");
    } else {
      retrieveDistrictsByCountryId(countryId).then((result) => {
        setStateProvinceDistrictOptions(result);
      });
      setStateSelectIdField("districtId");
    }
  }, [relative?.countryId]);

  const getStateProvinceDistrictOptions = (value: number): ValueOpt<State | Province | District> => {
    if (!value || !relative?.countryId) {
      return undefined;
    } else if (relative?.countryId === CountryIds.USA) {
      return getStateOptions(value);
    } else if (relative?.countryId === CountryIds.CANADA) {
      return getProvinceOptions(value);
    } else {
      return getDistrictOptions(stateProvinceDistrictOptions as ValueOpt<District>[], value);
    }
  };

  useEffect(() => {
    const country = countries.find((c) => c.countryId === relative?.countryId);
    if (!!country?.useDropdownFlag && relative?.stateId != undefined) {
      retrieveCitiesByStateProvinceDistrictId(relative?.stateId).then((result) => {
        setCityOptions(result);
      });
    } else {
      setCityOptions([]);
    }
  }, [relative?.stateId]);

  /* National Id */
  useEffect(() => {
    if (!!relative?.countryId) {
      setNationalIdTypeOptions(
        allNationalIDTypeOptions.filter(
          (country: ValueOpt<NationalIDType>) => country.value.countryId == relative.countryId,
        ),
      );
    } else {
      setNationalIdTypeOptions(allNationalIDTypeOptions);
    }
  }, [relative?.countryId]);

  const nationalIdTypeValueOpt = getNationalIDTypeOptions(relative?.nationalIdTypeId);
  const nationalIdFormat = nationalIdTypeValueOpt?.value.format;
  const nationalIdPattern = nationalIdTypeValueOpt?.value.pattern;

  const onCopyProspectAddressChange = (value: boolean) => {
    if (value && !relative?.livesWithRelativeFlag) {
      updateMultipleFields({
        livesWithRelativeFlag: value,
        countryId: prospectAddress.addressCountryId,
        stateId: prospectAddress.addressStateProvinceId,
        state: prospectAddress.addressStateProvince,
        cityId: prospectAddress.addressCityId,
        city: prospectAddress.addressCity,
        address: prospectAddress.address1,
      });
    } else {
      updateField("livesWithRelativeFlag", value);
    }
  };

  return (
    <>
      <ProspectRelativeExistsModal
        open={showRelativeExistsModal}
        onContinue={onProspectGuardianDoesNotExist}
        onClose={() => setShowRelativeExistsModal(false)}
      />
      <div>
        <div className="row mt-1">
          <FormColumn2Wide>
            <Input
              id={`${id}-first-name`}
              label={t("profile:firstName")}
              type="text"
              className="pb-2"
              value={relative?.firstName}
              onChange={(value: string) => {
                updateField("firstName", value);
              }}
              required={fieldsRequired}
              disabled={fieldsDisabled}
            />
          </FormColumn2Wide>
          <FormColumn2Wide>
            <Input
              id={`${id}-last-name`}
              label={t("profile:lastName")}
              type="text"
              value={relative?.lastName}
              onChange={(value: string) => {
                updateField("lastName", value);
              }}
              required={fieldsRequired}
              disabled={fieldsDisabled}
            />
          </FormColumn2Wide>
        </div>
        <div className="row mt-1">
          <FormColumn2Wide>
            <Select
              id="contact-type-select"
              value={ContactTypeOptions.find((opt) => opt.value === relative?.contactTypeId)}
              options={ContactTypeOptions}
              label={`${t("profile:contactPreference")}`}
              onChange={(value: ValueOpt<ContactType>) => {
                onContactTypeChange(value?.value);
              }}
              placeholder={t("translation:selectPlaceholder")}
              required={fieldsRequired}
              disabled={fieldsDisabled}
            />
          </FormColumn2Wide>
          <FormColumn2Wide>
            {(relative?.contactTypeId === ContactType.WHATSAPP || relative?.contactTypeId === ContactType.SMS) && (
              <div>
                <Select
                  id={`${id}-whatsapp-country-select`}
                  label={
                    relative?.contactTypeId === ContactType.WHATSAPP
                      ? t("prospect:whatsAppCountryCode")
                      : t("prospect:countryCode")
                  }
                  className="pb-2"
                  value={getCountryOptions(relative?.phoneNumberCountryId)}
                  options={countryOptions}
                  onChange={(selected: ValueOpt<Country>) => {
                    updateField("phoneNumberCountryId", selected?.value.countryId);
                  }}
                  placeholder={t("translation:selectPlaceholder")}
                  required={fieldsRequired}
                  disabled={fieldsDisabled}
                />
                <div>
                  <Label htmlFor="whatsapp">
                    {t("prospect:phoneNumber")}
                    <RequiredIndicator />
                  </Label>
                  <div className="d-flex">
                    <PhoneCountryCodeContainer>
                      <Input
                        id={`${id}-whatsapp-dial-code`}
                        label=""
                        disabled
                        required
                        styles={{ input: { height: "39px" } }}
                        value={"+" + dialCode}
                        onChange={() => {
                          return; // unused
                        }}
                      />
                    </PhoneCountryCodeContainer>
                    <PhoneNumberContainer>
                      <PhoneNumber
                        id={`${id}-whatsapp-input`}
                        label=""
                        value={relative?.phoneNumber}
                        countryCode={countryCode}
                        invalidMessage="Invalid Phone/WhatsApp Number"
                        onChange={(value) => {
                          updateField("phoneNumber", value as string);
                        }}
                        required={fieldsRequired}
                        disabled={fieldsDisabled}
                      />
                    </PhoneNumberContainer>
                  </div>
                </div>
              </div>
            )}

            {relative?.contactTypeId === ContactType.EMAIL && (
              <Email
                id={`${id}-email-address`}
                label={t("prospect:contactEmail")}
                value={relative?.email}
                className="mb-2"
                onChange={(value) => {
                  updateField("email", value as string);
                }}
                required={fieldsRequired}
                disabled={fieldsDisabled}
                invalidMessage={t("login:invalidEmail")}
              />
            )}
          </FormColumn2Wide>
        </div>

        <div className="mt-3">
          <Checkbox
            id={`${id}-copy-prospect-address`}
            checked={relative?.livesWithRelativeFlag}
            label={t("profile:copyProspectAddress")}
            onChange={onCopyProspectAddressChange}
            disabled={fieldsDisabled}
          />
        </div>

        <div className="row mt-1">
          <FormColumn2Wide>
            <Select
              id="residence-country-select"
              label={t("profile:country")}
              placeholder={t("translation:selectPlaceholder")}
              value={getCountryOptions(relative?.countryId)}
              options={countryOptions}
              onChange={(value: ValueOpt<Country>) => {
                updateMultipleFields({
                  countryId: value?.value.countryId,
                  stateId: null,
                  state: null,
                  cityId: null,
                  city: null,
                  address: null,
                });
              }}
              disabled={fieldsDisabled}
              clearable
            />
          </FormColumn2Wide>

          <FormColumn2Wide>
            <StateSelectOrTextInput
              id="state"
              label={t("profile:stateTerritory")}
              idField={stateSelectIdField}
              idValue={relative?.stateId}
              textValue={relative?.state}
              options={stateProvinceDistrictOptions}
              getOptionValue={getStateProvinceDistrictOptions}
              onChange={(idValue, textValue) => {
                if (idValue !== relative?.cityId || textValue !== relative?.city) {
                  updateMultipleFields({
                    stateId: idValue,
                    state: textValue,
                    cityId: null,
                    city: null,
                  });
                }
              }}
              required={!!relative?.countryId}
              disabled={fieldsDisabled || !relative?.countryId}
              clearable
            />
          </FormColumn2Wide>
        </div>

        <div className="row mt-1">
          <FormColumn2Wide>
            <CitySelectOrTextInput
              id="city"
              label={t("profile:city")}
              cityId={relative?.cityId}
              city={relative?.city}
              options={cityOptions}
              onChange={(cityId, city) => {
                if (cityId !== relative?.cityId || city !== relative?.city) {
                  updateMultipleFields({
                    cityId: cityId,
                    city: city,
                  });
                }
              }}
              disabled={fieldsDisabled || (!relative?.stateId && !relative?.state)}
              required={!!relative?.stateId || !!relative?.state}
              clearable
            />
          </FormColumn2Wide>

          <FormColumn2Wide>
            <Input
              id="street-address"
              label={t("profile:streetAddress")}
              value={relative?.address ? relative?.address : ""}
              onChange={(value: string) => updateField("address", value)}
              maxLength={75}
              required={!!relative?.stateId || !!relative?.state}
              disabled={fieldsDisabled || (!relative?.stateId && !relative?.state)}
            />
          </FormColumn2Wide>
        </div>

        <div className="row mt-3">
          <FormColumn2Wide>
            <Select
              id="national-id-type"
              label={t("profile:nationalIdType")}
              placeholder={t("translation:selectPlaceholder")}
              value={relative?.nationalIdTypeId !== -1 ? getNationalIDTypeOptions(relative?.nationalIdTypeId) : NaOpt}
              options={nationalIdTypeOptions}
              onChange={(selected: ValueOpt<NationalIDType | string> | undefined) => {
                updateMultipleFields({
                  nationalIdTypeId:
                    selected?.value === "N/A" ? -1 : (selected as ValueOpt<NationalIDType>)?.value?.nationalIdTypeId,
                  nationalId: "",
                });
              }}
              disabled={fieldsDisabled || !relative?.countryId}
              clearable
            />
          </FormColumn2Wide>
          <FormColumn2Wide>
            <Label htmlFor="national-id-value">
              <LabelTextContainer>
                {t("profile:nationalId")} {!!nationalIdPattern ? `(${nationalIdPattern})` : ""}
                {!!relative?.nationalIdTypeId && <Asterisk>*</Asterisk>}
              </LabelTextContainer>
            </Label>
            <NationalIdInput
              id="national-id-input"
              value={relative?.nationalId}
              onChange={(value: string) => {
                updateField("nationalId", value);
              }}
              typeId={relative?.nationalIdTypeId}
              format={nationalIdFormat}
              pattern={nationalIdPattern}
              required={!!relative?.nationalIdTypeId}
              disabled={fieldsDisabled || !relative?.nationalIdTypeId}
            />
          </FormColumn2Wide>
        </div>

        <div className="mt-3">
          <Checkbox
            id={`${id}-disable`}
            checked={relative?.hasRelative === false}
            disabled={required}
            label={t("profile:noParent")}
            onChange={(value: boolean) => {
              if (value) {
                setShowRelativeExistsModal(true);
              } else {
                updateField("hasRelative", true);
              }
            }}
          />
        </div>
      </div>
    </>
  );
};

export default ProspectRelativeInput;
