import { Icon, Typography } from "best-common-react";
import React from "react";
import styled from "styled-components";

const Container = styled.div`
  border: 1px solid ${(props) => props.theme.colors.fog};
  background: ${(props) => props.theme.colors.white};
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  padding: 0.5rem;
`;

const StyledText = styled(Typography)`
  display: contents;
`;

type FileDisplayProps = {
  fileName: string;
  resourceUrl: string;
  canDelete: boolean;
  onDelete?: () => Promise<void>;
};

const UploadedProspectDocument: React.FC<FileDisplayProps> = ({ fileName, resourceUrl, canDelete, onDelete }) => {
  return (
    <Container>
      {canDelete && <Icon iconName="fa-trash-alt" className="ms-2 me-4" onClick={onDelete} />}
      <StyledText variant="body1" className="">
        {fileName}
      </StyledText>
      <div className="ms-auto me-2">
        <Icon iconName="fa-download" onClick={() => window.open(resourceUrl, "_blank")} />
      </div>
    </Container>
  );
};

export default UploadedProspectDocument;
