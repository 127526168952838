import React, { useState } from "react";
import { AlertConstants, Button, Paper, SubSection, useAlerts, useLoading } from "best-common-react";
import { useTranslation } from "react-i18next";
import { useProspectProfile } from "../../../contexts/ProsepctProfileContext";
import { EmailAddressDTO, PhoneNumberDTO } from "../../../types/Profile";
import ProspectPhoneNumberCard from "./contact-info/ProspectPhoneNumberCard";
import ProspectEmailAddressCard from "./contact-info/ProspectEmailAddressCard";
import { LinkButtonStyles } from "./contact-info/ContactInformationStyles";
import styled from "styled-components";
import AddPhoneNumberModal from "./contact-info/AddPhoneNumberModal";
import AddEmailAddressModal from "./contact-info/AddEmailAddressModal";
import {
  deleteProspectEmailAddress,
  deleteProspectPhoneNumber,
  saveProspectEmailAddress,
  saveProspectPhoneNumber,
} from "../../../api/AuthenticatedClient";

const ButtonText = styled.span`
  margin-left: 0.25rem;
`;

const ContactInformationSection: React.FC = () => {
  const { t } = useTranslation("profile");
  const { setLoading } = useLoading();
  const { addAlert } = useAlerts();
  const { profile, updateProfileField } = useProspectProfile();
  const [showAddPhoneNumberModal, setShowAddPhoneNumberModal] = useState(false);
  const [showAddEmailAddressModal, setShowAddEmailAddressModal] = useState(false);

  const onAddPhoneNumber = async (phoneNumber: PhoneNumberDTO) => {
    phoneNumber = await saveProspectPhoneNumber(phoneNumber);
    updateProfileField("phoneNumbers", [...profile.phoneNumbers, phoneNumber]);
    setShowAddPhoneNumberModal(false);
  };

  const onDeletePhoneNumber = async (phoneNumberId: number) => {
    try {
      setLoading(true);
      await deleteProspectPhoneNumber(phoneNumberId);
      updateProfileField(
        "phoneNumbers",
        profile.phoneNumbers.filter((pn) => pn.phoneNumberId !== phoneNumberId),
      );
    } catch (e) {
      addAlert({
        type: AlertConstants.TYPES.DANGER,
        text: "Error deleting prospect phone number.",
      });
    } finally {
      setLoading(false);
    }
  };

  const onAddEmailAddress = async (emailAddress: EmailAddressDTO) => {
    emailAddress = await saveProspectEmailAddress(emailAddress);
    updateProfileField("emailAddresses", [...profile.emailAddresses, emailAddress]);
    setShowAddEmailAddressModal(false);
  };

  const onDeleteEmailAddress = async (emailAddressId: number) => {
    try {
      setLoading(true);
      await deleteProspectEmailAddress(emailAddressId);
      updateProfileField(
        "emailAddresses",
        profile.emailAddresses.filter((ea) => ea.emailAddressId !== emailAddressId),
      );
    } catch (e) {
      addAlert({
        type: AlertConstants.TYPES.DANGER,
        text: "Error deleting prospect email address.",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {showAddPhoneNumberModal && (
        <AddPhoneNumberModal
          open={showAddPhoneNumberModal}
          onSave={onAddPhoneNumber}
          onClose={() => setShowAddPhoneNumberModal(false)}
        />
      )}
      {showAddEmailAddressModal && (
        <AddEmailAddressModal
          open={showAddEmailAddressModal}
          onSave={onAddEmailAddress}
          onClose={() => setShowAddEmailAddressModal(false)}
        />
      )}
      <div>
        <Paper className="mb-3">{t("profile:contactInformationSection")}</Paper>
        <SubSection header={t("profile:phoneNumbers")}>
          {profile?.phoneNumbers?.map((phoneNumber) => (
            <ProspectPhoneNumberCard
              key={`phone-number-${phoneNumber.phoneNumberId}`}
              phoneNumber={phoneNumber}
              onDelete={async () => {
                await onDeletePhoneNumber(phoneNumber.phoneNumberId);
              }}
            />
          ))}
          <div className="pb-3">
            <Button
              variant="link"
              className="ps-0"
              styles={LinkButtonStyles}
              onClick={() => setShowAddPhoneNumberModal(true)}
            >
              <i className="fa-solid fa-circle-plus" />
              <ButtonText>
                {!!profile?.phoneNumbers?.length ? t("profile:addAdditionalPhoneNumber") : t("profile:addPhoneNumber")}
              </ButtonText>
            </Button>
          </div>
        </SubSection>
        <SubSection header={t("profile:emailAddresses")}>
          {profile?.emailAddresses?.map((emailAddress) => (
            <ProspectEmailAddressCard
              key={`email-address-${emailAddress.emailAddressId}`}
              emailAddress={emailAddress}
              onDelete={async () => {
                await onDeleteEmailAddress(emailAddress.emailAddressId);
              }}
            />
          ))}
          <div className="pb-3">
            <Button
              variant="link"
              className="ps-0"
              styles={LinkButtonStyles}
              onClick={() => setShowAddEmailAddressModal(true)}
            >
              <i className="fa-solid fa-circle-plus" />
              <ButtonText>
                {!!profile?.emailAddresses.length ? t("profile:addAdditionalEmail") : t("profile:addEmailAddress")}
              </ButtonText>
            </Button>
          </div>
        </SubSection>
      </div>
    </>
  );
};

export default ContactInformationSection;
