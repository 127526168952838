import React, { useEffect, useState } from "react";
import { Chip, Password } from "best-common-react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const ChipsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 0.25rem;
  margin-top: 0.5rem;
  font-size: 0.75rem;
`;

type ProspectPasswordInputProps = {
  value: string;
  setValue: (value: string) => void;
  setValid: (value: boolean) => void;
};

const ProspectPasswordInput: React.FC<ProspectPasswordInputProps> = ({ value, setValue, setValid }) => {
  const { t } = useTranslation(["login"]);
  const [has8Char, setHas8Char] = useState(false);
  const [has1Lowercase, setHas1Lowercase] = useState(false);
  const [has1Uppercase, setHas1Uppercase] = useState(false);
  const [has1Number, setHas1Number] = useState(false);

  const check8Char = (value?: string): void => {
    if (value && value.length > 7) {
      setHas8Char(true);
    } else {
      setHas8Char(false);
    }
  };

  const check1Lowercase = (value?: string): void => {
    if (value && /[a-z]/.test(value)) {
      setHas1Lowercase(true);
    } else {
      setHas1Lowercase(false);
    }
  };

  const check1Uppercase = (value?: string): void => {
    if (value && /[A-Z]/.test(value)) {
      setHas1Uppercase(true);
    } else {
      setHas1Uppercase(false);
    }
  };

  const check1Number = (value?: string): void => {
    if (value && /\d/.test(value)) {
      setHas1Number(true);
    } else {
      setHas1Number(false);
    }
  };

  useEffect(() => {
    check8Char(value);
    check1Lowercase(value);
    check1Uppercase(value);
    check1Number(value);
  }, [value]);

  useEffect(() => {
    setValid(has8Char && has1Lowercase && has1Uppercase && has1Number);
  }, [has8Char, has1Lowercase, has1Uppercase, has1Number]);

  return (
    <div>
      <Password id="password" name="password" label={t("login:password")} value={value} onChange={setValue} required />
      <ChipsContainer>
        <Chip variant={has8Char ? "success" : "danger"} label={t("login:eightCharMin")} />
        <Chip variant={has1Lowercase ? "success" : "danger"} label={t("login:oneLowercaseLetter")} />
        <Chip variant={has1Uppercase ? "success" : "danger"} label={t("login:oneUppercaseLetter")} />
        <Chip variant={has1Number ? "success" : "danger"} label={t("login:oneNumber")} />
      </ChipsContainer>
    </div>
  );
};

export default ProspectPasswordInput;
