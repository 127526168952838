import React, { ReactNode } from "react";
import MonitorActivity from "../components/inactivity/MonitorActivity";
import { ProspectConsentFormSseProvider } from "./ProspectConsentFormSseContext";

type AuthenticatedContextsProps = {
  children?: ReactNode;
};

const AuthenticatedContexts: React.FC<AuthenticatedContextsProps> = ({ children }) => (
  <MonitorActivity>
    <ProspectConsentFormSseProvider>{children}</ProspectConsentFormSseProvider>
  </MonitorActivity>
);

export default AuthenticatedContexts;
