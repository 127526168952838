import React from "react";
import {
  ConsentFormSignedIcon,
  ConsentFormSignedIconContainer,
  RequiredText,
  SignerCardColumn,
  SignerCardContainer,
  SignerCardStatus,
  SignerCardTitle,
} from "./SignerCardStyles";
import { useTranslation } from "react-i18next";
import { Button } from "best-common-react";

type ProspectConsentFormSignerCardProps = {
  name: string;
  signingUrl?: string;
  hasSigned: boolean;
};

const ProspectConsentFormSignerCard: React.FC<ProspectConsentFormSignerCardProps> = ({
  name,
  signingUrl,
  hasSigned,
}) => {
  const { t } = useTranslation(["translation"]);

  return (
    <SignerCardContainer>
      <SignerCardColumn>
        <SignerCardTitle>{name}</SignerCardTitle>
        <SignerCardStatus signed={hasSigned}>
          {hasSigned ? t("translation:signed") : t("translation:notSigned")}
        </SignerCardStatus>
      </SignerCardColumn>
      {hasSigned ? (
        <ConsentFormSignedIconContainer>
          <ConsentFormSignedIcon />
        </ConsentFormSignedIconContainer>
      ) : (
        <SignerCardColumn>
          <RequiredText>* {t("translation:required")}</RequiredText>
          {!hasSigned && (
            <Button
              variant="primary"
              loading={!signingUrl}
              onClick={() => {
                location.href = signingUrl;
              }}
            >
              {t("translation:sign")}
            </Button>
          )}
        </SignerCardColumn>
      )}
    </SignerCardContainer>
  );
};

export default ProspectConsentFormSignerCard;
