import React, { useContext } from "react";
import { ThemeContext } from "styled-components";
import { Accordion, AccordionBody, AccordionHeader, SubSection } from "best-common-react";
import { useTranslation } from "react-i18next";
import { useProspectProfile } from "../../../contexts/ProsepctProfileContext";
import ProspectPhoneNumberCard from "../sections/contact-info/ProspectPhoneNumberCard";
import ProspectEmailAddressCard from "../sections/contact-info/ProspectEmailAddressCard";

const ContactInfoReadyOnly: React.FC = () => {
  const Theme = useContext(ThemeContext);
  const { t } = useTranslation("profile");
  const { profile } = useProspectProfile();

  return (
    <Accordion
      accordionKey="basball-info"
      styles={{ ...Theme.accordion, body: { ...Theme.accordion.body, background: Theme.colors.cloud, border: "none" } }}
    >
      <AccordionHeader>{t("profile:contactInformationSection")}</AccordionHeader>
      <AccordionBody>
        <SubSection header={t("profile:phoneNumbers")}>
          {profile.phoneNumbers.map((pn) => (
            <ProspectPhoneNumberCard key={`phone-number-${pn.phoneNumberId}`} phoneNumber={pn} />
          ))}
        </SubSection>

        <SubSection header={t("profile:emailAddresses")}>
          {profile.emailAddresses.map((e) => (
            <ProspectEmailAddressCard key={`email-address-${e.emailAddressId}`} emailAddress={e} />
          ))}
        </SubSection>
      </AccordionBody>
    </Accordion>
  );
};

export default ContactInfoReadyOnly;
