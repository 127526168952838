import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Modal } from "best-common-react";

type ProspectRelativeExistsModalProps = {
  open: boolean;
  onContinue: () => void;
  onClose: () => void;
};

const ProspectRelativeExistsModal: React.FC<ProspectRelativeExistsModalProps> = ({ open, onContinue, onClose }) => {
  const { t } = useTranslation(["translation", "profile"]);

  return (
    <Modal show={open}>
      <Modal.Header>{t("translation:areYouSure")}</Modal.Header>
      <Modal.Body>{t("profile:noParentConfirmationBody")}</Modal.Body>
      <Modal.Footer>
        <Button onClick={onContinue} className="me-1" variant="primary">
          {t("translation:continue")}
        </Button>
        <Button onClick={onClose} variant="secondary">
          {t("translation:cancel")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ProspectRelativeExistsModal;
