import { Button, Modal } from "best-common-react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { RouteConstants } from "../../../constants/RouteConstants";

type AccountAlreadyExistsModalProps = {
  show: boolean;
  close: () => void;
  username: string;
};

const AccountAlreadyExistsModal: React.FC<AccountAlreadyExistsModalProps> = ({ show, close, username }) => {
  const { t } = useTranslation(["login", "translation", "registration"]);
  const navigate = useNavigate();

  return (
    <Modal show={show}>
      <Modal.Header>Error</Modal.Header>
      <Modal.Body>
        {t("registration:accountAlreadyExists")} <b>{username}</b>.
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => navigate(RouteConstants.BASE)} className="me-1" variant="primary">
          {t("login:signIn")}
        </Button>
        <Button onClick={close} variant="secondary">
          {t("translation:close")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AccountAlreadyExistsModal;
