import React from "react";
import { FormColumn2Wide, NaOpt, Paper, Select, ValueOpt } from "best-common-react";
import styled from "styled-components";
import { BattingSide, CountryIds, MexicanLeagueTeam, Position, ThrowingHand } from "../../../types/Metadata";
import ProspectHeightInput from "../fields/ProspectHeightInput";
import DropdownWithToggle from "../fields/DropdownWithToggle";
import { useTranslation } from "react-i18next";
import { useProspectProfile } from "../../../contexts/ProsepctProfileContext";
import { useDropdowns } from "../../../contexts/DropdownsContext";
import { MEXICO } from "../../../constants/ProfileConstants";
import CubanBornBaseballInfo from "./baseball-info/CubanBornBaseballInfo";

const Container = styled.div``;

const BaseballInformationSection: React.FC = () => {
  const { t } = useTranslation(["profile", "translation"]);
  const { profile, updateProfileField, updateMultipleProfileFields } = useProspectProfile();
  const {
    positionOptions,
    getPositionOption,
    batsOptions,
    getBatsOption,
    throwsOptions,
    getThrowsOption,
    weightKgOptions,
    weightLbOptions,
    getWeightKgOption,
    getWeightLbOption,
    weightUnitOptions,
  } = useDropdowns();
  const { getMexicanLeagueTeamOptions, mexicanLeagueTeamOptions } = useDropdowns();

  const weightLookupToggles = [
    {
      options: weightKgOptions,
      optionGetter: getWeightKgOption,
      toggleOption: weightUnitOptions.length > 0 ? weightUnitOptions[0] : null,
    },
    {
      options: weightLbOptions,
      optionGetter: getWeightLbOption,
      toggleOption: weightUnitOptions.length > 0 ? weightUnitOptions[1] : null,
    },
  ];

  return (
    <Container>
      <Paper className="mb-3">{t("profile:baseballInformationSection")}</Paper>
      <div className="row pb-2">
        <FormColumn2Wide>
          <Select
            id="position"
            label={t("profile:position")}
            placeholder={t("translation:selectPlaceholder")}
            className="pb-2"
            required
            value={getPositionOption(profile?.positionId)}
            options={positionOptions}
            onChange={(option: ValueOpt<Position> | undefined) => {
              updateProfileField("positionId", option?.value?.positionId);
            }}
          />
        </FormColumn2Wide>
      </div>
      <div className="row pb-2">
        <FormColumn2Wide>
          <Select
            id="bats"
            label={t("profile:bats")}
            placeholder={t("translation:selectPlaceholder")}
            value={getBatsOption(profile?.batsId)}
            options={batsOptions}
            onChange={(option: ValueOpt<BattingSide> | undefined) => {
              updateProfileField("batsId", option?.value.battingSideId);
            }}
          />
        </FormColumn2Wide>
        <FormColumn2Wide>
          <Select
            id="throws"
            label={t("profile:throws")}
            placeholder={t("translation:selectPlaceholder")}
            className="pb-2"
            required
            value={getThrowsOption(profile?.throwingHandId)}
            options={throwsOptions}
            onChange={(option: ValueOpt<ThrowingHand> | undefined) => {
              updateProfileField("throwingHandId", option?.value.throwingHandId);
            }}
          />
        </FormColumn2Wide>
      </div>
      <div className="row pb-3">
        <FormColumn2Wide>
          <ProspectHeightInput
            height={profile?.height}
            heightUnit={profile?.heightUnitId}
            onHeightChange={(value: number) => {
              updateProfileField("height", value);
            }}
            onHeightUnitChange={(value: number) => {
              updateMultipleProfileFields({ heightUnitId: value, height: undefined });
            }}
          />
        </FormColumn2Wide>
        <FormColumn2Wide>
          <DropdownWithToggle
            id="weight"
            label={t("profile:weight")}
            lookupToggles={weightLookupToggles}
            dropdownValue={profile?.weight}
            toggleValue={!!profile?.weightUnitId ? profile.weightUnitId : 2}
            required
            onDropdownChange={(option: ValueOpt<number> | undefined) => {
              updateProfileField("weight", option?.value);
            }}
            onToggleChange={(value: number) => {
              updateMultipleProfileFields({ weightUnitId: value, weight: undefined });
            }}
          />
        </FormColumn2Wide>
      </div>
      {profile?.registrationCountryId === MEXICO.id && (
        <div>
          <Select
            id="mexican-lg-team-name"
            label={t("profile:whatMexicanLeagueTeam")}
            required
            placeholder={t("translation:selectPlaceholder")}
            showNA={true}
            value={
              profile?.mexicanLeagueTeamId !== "-1" ? getMexicanLeagueTeamOptions(profile?.mexicanLeagueTeamId) : NaOpt
            }
            options={mexicanLeagueTeamOptions}
            onChange={(value: ValueOpt<MexicanLeagueTeam | string> | undefined) => {
              updateProfileField(
                "mexicanLeagueTeamId",
                value?.value == "N/A" ? "-1" : (value as ValueOpt<MexicanLeagueTeam>)?.value?.id,
              );
            }}
          />
        </div>
      )}
      {profile?.birthCountryId === CountryIds.CUBA && <CubanBornBaseballInfo />}
    </Container>
  );
};

export default BaseballInformationSection;
