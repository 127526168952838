import React, { useEffect, useState } from "react";
import {
  Button,
  Input,
  Label,
  Modal,
  PhoneNumber,
  RadioGroup,
  RequiredIndicator,
  Select,
  ValueOpt,
} from "best-common-react";
import { PhoneNumberDTO } from "../../../../types/Profile";
import { Country } from "../../../../types/Metadata";
import { useDropdowns } from "../../../../contexts/DropdownsContext";
import { useAuthentication } from "../../../../contexts/AuthenticationContext";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { validatePhoneNumber } from "../../../../util/ProspectProfileValidationUtil";

const Body = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const PhoneCountryCodeContainer = styled.div`
  flex-basis: 18%;
  margin-right: 4px;
`;

const PhoneNumberContainer = styled.div`
  flex-basis: 82%;
`;

type AddPhoneNumberModalProps = {
  open: boolean;
  onSave: (phoneNumber: PhoneNumberDTO) => Promise<void>;
  onClose: () => void;
};

const AddPhoneNumberModal: React.FC<AddPhoneNumberModalProps> = ({ open, onSave, onClose }) => {
  const { t } = useTranslation(["profile", "translation"]);
  const { userDetails } = useAuthentication();
  const { countryOptions, getCountryOptions, phoneNumberCommunicationTypeRadioOptions, phoneNumberTypeRadioOptions } =
    useDropdowns();
  const [phoneNumber, setPhoneNumber] = useState<PhoneNumberDTO>({
    prospectId: userDetails?.prospectId,
    dialCode: "1",
  });
  const [canSave, setCanSave] = useState(false);

  useEffect(() => {
    setCanSave(validatePhoneNumber(phoneNumber));
  }, [phoneNumber]);

  const handleChange = (key: string, value: any) => {
    setPhoneNumber({ ...phoneNumber, [key]: value });
  };

  return (
    <Modal show={open}>
      <Modal.Header>{t("profile:addPhoneNumberTitle")}</Modal.Header>
      <Modal.Body>
        <Body>
          <FormContainer>
            <Select
              id="country-id-select"
              label={t("profile:phoneNumberCountryCode")}
              className="pb-2"
              required
              placeholder={t("translation:selectPlaceholder")}
              value={getCountryOptions(phoneNumber.countryId)}
              options={countryOptions}
              onChange={(selected: ValueOpt<Country>) => {
                setPhoneNumber({
                  ...phoneNumber,
                  countryId: selected?.value.countryId,
                  countryCode: selected?.value.countryCode,
                  dialCode: selected?.value.dialCode,
                });
              }}
            />
            <div>
              <Label htmlFor="phoneNumber">
                {t("profile:phoneNumber")}
                <RequiredIndicator />
              </Label>
              <div className="d-flex">
                <PhoneCountryCodeContainer>
                  <Input
                    id="dial-code"
                    label=""
                    disabled
                    required
                    styles={{ input: { height: "39px" } }}
                    value={"+" + phoneNumber.dialCode}
                    onChange={() => {
                      return; // unused
                    }}
                  />
                </PhoneCountryCodeContainer>
                <PhoneNumberContainer>
                  <PhoneNumber
                    id="phone-number-input"
                    label=""
                    required
                    value={phoneNumber.phoneNumber}
                    countryCode={phoneNumber.countryCode}
                    invalidMessage={t("profile:invalidPhoneNumber")}
                    onChange={(value) => {
                      handleChange("phoneNumber", value);
                    }}
                  />
                </PhoneNumberContainer>
              </div>
            </div>
            <RadioGroup
              id={"phone-number-communication-type-" + phoneNumber.sequenceNumber}
              name={"phone-number-communication-type-" + phoneNumber.sequenceNumber}
              label={t("profile:phoneNumberCommunicationType")}
              required
              vertical
              value={phoneNumber.phoneNumberCommunicationTypeId}
              options={phoneNumberCommunicationTypeRadioOptions}
              onChange={(value) => handleChange("phoneNumberCommunicationTypeId", value)}
              optionStyles={{ paddingBottom: "6px" }}
            />
            <RadioGroup
              id={"phoneType" + phoneNumber.sequenceNumber}
              name={"phoneType" + phoneNumber.sequenceNumber}
              label={t("profile:phoneNumberType")}
              required
              vertical
              value={phoneNumber.phoneNumberTypeId}
              options={phoneNumberTypeRadioOptions}
              onChange={(value) => handleChange("phoneNumberTypeId", value)}
              optionStyles={{ paddingBottom: "6px" }}
            />
          </FormContainer>
        </Body>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => onSave(phoneNumber)} disabled={!canSave} className="me-1" variant="primary">
          {t("translation:continue")}
        </Button>
        <Button onClick={onClose} variant="secondary">
          {t("translation:close")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddPhoneNumberModal;
