import React, { useEffect, useState } from "react";
import { Button, Modal } from "best-common-react";
import { useTranslation } from "react-i18next";
import { ConsentFormSignerDTO } from "../../types/ConsentForm";
import EditSignerContactPreference from "./EditSignerContactPreference";
import { ContactType } from "../../types/Metadata";

type EditGuardianContactInfoModalProps = {
  name: string;
  signer: ConsentFormSignerDTO;
  open: boolean;
  onClose: () => void;
  onSave: (value: ConsentFormSignerDTO) => Promise<void>;
};

const EditGuardianContactInfoModal: React.FC<EditGuardianContactInfoModalProps> = ({
  name,
  signer,
  open,
  onClose,
  onSave,
}) => {
  const { t } = useTranslation(["translation", "profile"]);
  const [contactTypeId, setContactTypeId] = useState<ContactType>(signer.contactTypeId);
  const [email, setEmail] = useState<string>(signer.email);
  const [phoneNumberCountryId, setPhoneNumberCountryId] = useState(signer.phoneNumberCountryId);
  const [phoneNumber, setPhoneNumber] = useState<string>(signer.phoneNumber);
  const [contactValid, setContactValid] = useState<boolean>(true);
  const [canSave, setCanSave] = useState<boolean>(false);

  useEffect(() => {
    setCanSave(contactValid && (!!email?.length || (!!phoneNumberCountryId && !!phoneNumber?.length)));
  }, [contactTypeId, email, phoneNumberCountryId, phoneNumber, contactValid]);

  const onContactTypeChange = (value: ContactType) => {
    setContactTypeId(value);
    setEmail(null);
    setPhoneNumberCountryId(null);
    setPhoneNumber(null);
  };

  const close = () => {
    // Reset any edits made to local values
    setContactTypeId(signer.contactTypeId);
    setEmail(signer.email);
    setPhoneNumberCountryId(signer.phoneNumberCountryId);
    setPhoneNumber(signer.phoneNumber);
    setContactValid(true);
    setCanSave(false);

    onClose();
  };

  const saveGuardianSigner = () => {
    onSave({
      signerTypeId: signer.signerTypeId,
      contactTypeId,
      email,
      phoneNumberCountryId,
      phoneNumber,
      required: signer.required,
      signedTs: signer.signedTs,
    }).finally(() => {
      onClose();
    });
  };

  return (
    <Modal show={open}>
      <Modal.Header>
        {name} {t("profile:contactInformationSection")}
      </Modal.Header>
      <Modal.Body>
        <EditSignerContactPreference
          name={name}
          contactTypeId={contactTypeId}
          setContactTypeId={onContactTypeChange}
          email={email}
          setEmail={setEmail}
          phoneNumberCountryId={phoneNumberCountryId}
          setPhoneNumberCountryId={setPhoneNumberCountryId}
          phoneNumber={phoneNumber}
          setPhoneNumber={setPhoneNumber}
          setContactValid={setContactValid}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={saveGuardianSigner} disabled={!canSave} className="me-1" variant="primary">
          {t("translation:save")}
        </Button>
        <Button onClick={close} variant="secondary">
          {t("translation:cancel")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditGuardianContactInfoModal;
