import { format, parse } from "date-fns";
import { CountryIds } from "../types/Metadata";
import { REQUIRED_NATIONAL_ID_COUNTRIES } from "../constants/ProfileConstants";
import { ProspectProfileDTO } from "../types/ProspectProfile";

export const ACCEPTED_FILE_TYPES = [
  "application/pdf",
  "image/jpeg",
  "image/bmp",
  "image/png",
  "image/heic",
  "image/heif",
  ".heis",
  ".raw",
  ".dng",
  ".doc",
  ".docx",
];

const KG_TO_LB_MULTIPLIER = 2.205;

export const kgToPounds = (weightKg: number): number => {
  const pounds = weightKg * KG_TO_LB_MULTIPLIER;
  return Math.round(pounds);
};

export const cmToHeightStr = (heightCm: number): string => {
  let inches = Math.floor(heightCm * 0.393700787);
  const feet = Math.floor(inches / 12);
  inches %= 12;
  return feet + "'" + inches + '"';
};

export const inToHeightStr = (inches: number): string => {
  const feet = Math.floor(inches / 12);
  inches %= 12;
  return feet + "'" + inches + '"';
};

export const createNewDate = (date: string): Date => {
  if (!!date) {
    const dateAsStringArray: string[] = date.split("-");
    const newDate = new Date();
    newDate.setFullYear(Number(dateAsStringArray[0]), Number(dateAsStringArray[1]) - 1, Number(dateAsStringArray[2]));
    return newDate;
  } else {
    return null;
  }
};

export const formatDateWithSlashes = (date: string): string => {
  return !date ? "" : replaceAll(date, "-", "/");
};

export const formatStringDate = (dateStr: string, formatString: string) => {
  if (!dateStr) {
    return "";
  }
  const dateWithSlashes: string = formatDateWithSlashes(dateStr);
  const date: Date = new Date(dateWithSlashes);
  return format(date, formatString);
};

function replaceAll(string: string, search: string, replace: string): string {
  return string.split(search).join(replace);
}

export const formatLocalDate = (date: string) => {
  if (!date) return "";
  const parsed = parse(date, dateFormats.YYYY_MM_DD, new Date());
  return format(parsed, dateFormats.MM_DD_YYYY);
};

export const isNationalIdRequired = (profile: ProspectProfileDTO): boolean => {
  return (
    profile?.birthCountryId === CountryIds.USA ||
    REQUIRED_NATIONAL_ID_COUNTRIES.includes(profile?.registrationCountryId)
  );
};

export const DATE_INPUT_MIN_DATE = new Date(1995, 0, 1);

export const dateFormats = {
  MM_DD_YYYY: "MM/dd/yyyy",
  DD_MMMM_YYYY: "dd MMMM yyyy",
  DATE_FORMAT: "MM/yyyy",
  YY_MM_DD: "yyyy/MM/DD",
  DATE_TIME: "yyyy-MM-dd HH:mm:ss",
  MM_DD_YYYY_HH_MM_SS: "MM/dd/yyyy HH:mm:ss",
  YYYY_MM_DD: "yyyy-MM-dd",
};
