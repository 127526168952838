import React, { ReactNode, useEffect, useState } from "react";
import {
  getConsentFormGuardianSigners,
  getProspectConsentForm,
  updateConsentFormGuardianSigners,
} from "../api/AuthenticatedClient";
import { ConsentFormGuardianSignersDTO, ConsentFormSignerDTO, ProspectConsentFormDTO } from "../types/ConsentForm";
import { guardianSignatureIncomplete } from "../util/ConsentFormUtil";
import { AlertConstants, useAlerts, useLoading } from "best-common-react";
import { useProspectConsentFormSse } from "./ProspectConsentFormSseContext";

type ConsentFormEsignContextType = {
  loaded: boolean;
  prospectConsentForm?: ProspectConsentFormDTO;
  guardianSigners?: ConsentFormGuardianSignersDTO;
  updateGuardianSignerField: (
    guardianKey: keyof ConsentFormGuardianSignersDTO,
    field: keyof ConsentFormSignerDTO,
    value: any,
  ) => void;
  updateMultipleGuardianSignerFields: (
    guardianKey: keyof ConsentFormGuardianSignersDTO,
    patch: { [p: string]: any },
  ) => void;
  saveGuardianSigners: () => Promise<void>;
  updateAndSaveGuardianSigner: (
    guardianKey: keyof ConsentFormGuardianSignersDTO,
    signerDTO: ConsentFormSignerDTO,
  ) => Promise<void>;
  showEditGuardians: boolean;
};

const ConsentFormEsignContext = React.createContext<ConsentFormEsignContextType | undefined>(undefined);

type ConsentFormEsignProviderProps = {
  children?: ReactNode;
};

const ConsentFormEsignProvider: React.FC<ConsentFormEsignProviderProps> = ({ ...props }) => {
  const { setLoading: setGlobalLoading } = useLoading();
  const { addAlert } = useAlerts();
  const { signingUrl, signedTs } = useProspectConsentFormSse();
  const [loaded, setLoaded] = useState(false);
  const [prospectConsentForm, setProspectConsentForm] = useState<ProspectConsentFormDTO>();
  const [guardianSigners, setGuardianSigners] = useState<ConsentFormGuardianSignersDTO>({
    guardian1: undefined,
    guardian2: undefined,
  });
  const [showEditGuardians, setShowEditGuardians] = useState<boolean>();
  const fetchConsentFormData = async () => {
    const pcf = await getProspectConsentForm();
    setProspectConsentForm(pcf);
    if (pcf.guardianSignaturesRequired) {
      const guardians = await getConsentFormGuardianSigners();
      setGuardianSigners(guardians);
      setShowEditGuardians(
        guardianSignatureIncomplete(guardians.guardian1) || guardianSignatureIncomplete(guardians.guardian2),
      );
    } else {
      setShowEditGuardians(false);
    }
    setLoaded(true);
  };

  useEffect(() => {
    fetchConsentFormData().catch(console.error);
  }, []);

  useEffect(() => {
    if (signingUrl) {
      setProspectConsentForm({ ...prospectConsentForm, prospectSigningUrl: signingUrl });
    }
  }, [signingUrl]);

  useEffect(() => {
    if (signedTs) {
      setProspectConsentForm({ ...prospectConsentForm, prospectSignedTs: signedTs });
    }
  }, [signedTs]);

  const updateGuardianSignerField = (
    guardianKey: keyof ConsentFormGuardianSignersDTO,
    field: keyof ConsentFormSignerDTO,
    value: any,
  ) => {
    setGuardianSigners({ ...guardianSigners, [guardianKey]: { ...guardianSigners[guardianKey], [field]: value } });
  };

  const updateMultipleGuardianSignerFields = (
    guardianKey: keyof ConsentFormGuardianSignersDTO,
    patch: { [p: string]: any },
  ) => {
    setGuardianSigners({ ...guardianSigners, [guardianKey]: { ...guardianSigners[guardianKey], ...patch } });
  };

  const saveGuardianSigners = async () => {
    try {
      setGlobalLoading(true);
      const saved = await updateConsentFormGuardianSigners(guardianSigners);
      setGuardianSigners(saved);
      setShowEditGuardians(
        guardianSignatureIncomplete(saved.guardian1) || guardianSignatureIncomplete(saved.guardian2),
      );
    } catch (e) {
      addAlert({
        type: AlertConstants.TYPES.DANGER,
        text: "Error saving guardians.",
      });
    } finally {
      setGlobalLoading(false);
    }
  };

  const updateAndSaveGuardianSigner = async (
    guardianKey: keyof ConsentFormGuardianSignersDTO,
    signerDTO: ConsentFormSignerDTO,
  ) => {
    try {
      setGlobalLoading(true);
      const saved = await updateConsentFormGuardianSigners({ ...guardianSigners, [guardianKey]: signerDTO });
      setGuardianSigners(saved);
    } catch (e) {
      addAlert({
        type: AlertConstants.TYPES.DANGER,
        text: "Error updating guardians.",
      });
    } finally {
      setGlobalLoading(false);
    }
  };

  return (
    <ConsentFormEsignContext.Provider
      value={{
        loaded,
        prospectConsentForm,
        guardianSigners,
        updateGuardianSignerField,
        updateMultipleGuardianSignerFields,
        saveGuardianSigners,
        updateAndSaveGuardianSigner,
        showEditGuardians,
      }}
      {...props}
    />
  );
};

const useConsentFormEsign = (): ConsentFormEsignContextType => {
  const context: ConsentFormEsignContextType | undefined = React.useContext(ConsentFormEsignContext);
  if (context === undefined) {
    throw new Error(`useConsentFormEsign must be used within a ConsentFormEsignProvider`);
  }
  return context;
};

export { ConsentFormEsignProvider, useConsentFormEsign };
