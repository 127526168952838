import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ProspectProfessionalTeamDTO, ProspectTravelDateDTO } from "../../../../types/ProspectProfile";
import {
  AsSelectPlaceholders,
  Button,
  DateInput,
  FormColumn2Wide,
  Input,
  Modal,
  Select,
  useLoading,
  ValueOpt,
} from "best-common-react";
import { useDropdowns } from "../../../../contexts/DropdownsContext";
import { Country, CountryIds } from "../../../../types/Metadata";
import StateSelectOrTextInput from "../../fields/StateSelectOrTextInput";
import { DATE_INPUT_MIN_DATE, dateFormats } from "../../../../util/ProfileUtils";
import { format, parse } from "date-fns";

type AddTravelDateModal = {
  show: boolean;
  onClose: () => void;
  onSave: (toSave: ProspectProfessionalTeamDTO) => Promise<void>;
};

const defaultTravelDate: ProspectTravelDateDTO = {
  id: undefined,
  startDate: undefined,
  endDate: undefined,
  countryId: undefined,
  stateId: undefined,
  state: "",
  cityId: undefined,
  city: "",
  purpose: "",
};

const AddTravelDateModal: React.FC<AddTravelDateModal> = ({ show, onClose, onSave }) => {
  const { t } = useTranslation(["profile", "translation"]);
  const [canSave, setCanSave] = useState(false);
  const { countryOptions, getCountryOptions, stateOptions, getStateOptions, provinceOptions, getProvinceOptions } =
    useDropdowns();
  const { setLoading } = useLoading();
  const [filteredCountryOptions, setFilteredCountryOptions] = useState<ValueOpt<Country>[]>([]);
  const [travelDate, setTravelDate] = useState<ProspectTravelDateDTO>(defaultTravelDate);

  useEffect(() => {
    setFilteredCountryOptions(
      countryOptions.filter(
        (country) => country.value?.countryId === CountryIds.USA || country.value?.countryId === CountryIds.CANADA,
      ),
    );
  }, [countryOptions]);

  useEffect(() => {
    setCanSave(
      !!travelDate.startDate?.length &&
        !!travelDate.endDate?.length &&
        !!travelDate.countryId &&
        (!!travelDate.stateId || !!travelDate.state?.length) &&
        !!travelDate.city?.length &&
        !!travelDate.purpose?.length,
    );
  }, [travelDate]);

  const close = () => {
    setTravelDate(defaultTravelDate);
    onClose();
  };

  const save = async () => {
    setLoading(true);
    await onSave(travelDate);
    setLoading(false);
    close();
  };

  const onCountryChange = (countryId: number | undefined) => {
    if (countryId != travelDate.countryId) {
      setTravelDate({
        ...travelDate,
        countryId,
        stateId: undefined,
        state: "",
        cityId: undefined,
        city: "",
      });
    }
  };

  const MONTHS: string[] = t("translation:months");
  const dateDropdownPlaceholders: AsSelectPlaceholders = {
    day: t("prospect:day" as const),
    month: t("prospect:month" as const),
    year: t("prospect:year" as const),
  };

  return (
    <Modal show={show}>
      <Modal.Header>{t("profile:addTravelDateModalHeader")}</Modal.Header>
      <Modal.Body>
        <div className="row mb-2">
          <DateInput
            id="start-date-input"
            label={t("translation:startDate")}
            invalidDateMessage={t("translation:invalidDate")}
            asSelects
            asSelectsPlaceholders={dateDropdownPlaceholders}
            monthsValueOptions={MONTHS}
            minDate={DATE_INPUT_MIN_DATE}
            maxDate={new Date()}
            value={
              !!travelDate?.startDate ? parse(travelDate.startDate, dateFormats.YYYY_MM_DD, new Date()) : undefined
            }
            onChange={(value: Date | undefined) => {
              setTravelDate({ ...travelDate, startDate: !!value ? format(value, dateFormats.YYYY_MM_DD) : undefined });
            }}
            required
          />
        </div>

        <div className="row mb-2">
          <DateInput
            id="end-date-input"
            label={t("translation:endDate")}
            invalidDateMessage={t("translation:invalidDate")}
            asSelects
            asSelectsPlaceholders={dateDropdownPlaceholders}
            monthsValueOptions={MONTHS}
            minDate={parse(travelDate.startDate, dateFormats.YYYY_MM_DD, new Date())}
            maxDate={new Date()}
            value={!!travelDate?.endDate ? parse(travelDate.endDate, dateFormats.YYYY_MM_DD, new Date()) : undefined}
            onChange={(value: Date | undefined) => {
              setTravelDate({ ...travelDate, endDate: !!value ? format(value, dateFormats.YYYY_MM_DD) : undefined });
            }}
            required
            disabled={!travelDate?.startDate}
          />
        </div>

        <div className="row">
          <FormColumn2Wide>
            <Select
              id="travel-date-country"
              label={t("profile:country")}
              placeholder={t("translation:selectPlaceholder")}
              className="pb-2"
              value={getCountryOptions(travelDate?.countryId)}
              required
              onChange={(selected: ValueOpt<Country> | undefined) => {
                onCountryChange(selected?.value?.countryId);
              }}
              options={filteredCountryOptions}
            />
          </FormColumn2Wide>
          <FormColumn2Wide>
            {travelDate?.countryId === CountryIds.USA && (
              <StateSelectOrTextInput
                id="travel-date-state"
                label={t("profile:stateTerritory")}
                idField="stateId"
                idValue={travelDate?.stateId}
                textValue={travelDate?.state}
                options={stateOptions}
                getOptionValue={getStateOptions}
                onChange={(idValue, textValue) => {
                  if (idValue !== travelDate?.stateId || textValue !== travelDate?.state) {
                    setTravelDate({
                      ...travelDate,
                      stateId: idValue,
                      state: textValue,
                      cityId: undefined,
                      city: "",
                    });
                  }
                }}
                disabled={!travelDate?.countryId}
                required
              />
            )}
            {travelDate?.countryId === CountryIds.CANADA && (
              <StateSelectOrTextInput
                id="birth-province"
                label={t("profile:stateTerritory")}
                idField="provinceId"
                idValue={travelDate?.stateId}
                textValue={travelDate?.state}
                options={provinceOptions}
                getOptionValue={getProvinceOptions}
                onChange={(idValue, textValue) => {
                  if (idValue !== travelDate?.stateId || textValue !== travelDate?.state) {
                    setTravelDate({
                      ...travelDate,
                      stateId: idValue,
                      state: textValue,
                      cityId: undefined,
                      city: "",
                    });
                  }
                }}
                disabled={!travelDate?.countryId}
                required
              />
            )}
            {travelDate?.countryId != CountryIds.USA && travelDate?.countryId != CountryIds.CANADA && (
              <Select
                id="birth-province"
                label={t("profile:stateTerritory")}
                value={undefined}
                options={[]}
                onChange={() => {
                  // placeholder
                }}
                disabled={true}
                required
              />
            )}
          </FormColumn2Wide>
        </div>
        <div className="row mb-2">
          <FormColumn2Wide />
          <FormColumn2Wide>
            <Input
              id="travel-date-city-input"
              label={t("profile:city")}
              type="text"
              value={travelDate?.city ? travelDate?.city : ""}
              onChange={(value) => {
                setTravelDate({ ...travelDate, city: value });
              }}
              disabled={!travelDate?.stateId && !travelDate?.state.length}
              required
            />
          </FormColumn2Wide>
        </div>
        <div className="row mb-2">
          <Input
            id="travel-date-purpose"
            label={t("profile:purpose")}
            value={travelDate.purpose}
            onChange={(value) => setTravelDate({ ...travelDate, purpose: value })}
            required
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={save} disabled={!canSave} className="me-1" variant="primary">
          {t("translation:continue")}
        </Button>
        <Button onClick={close} variant="secondary">
          {t("translation:cancel")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddTravelDateModal;
