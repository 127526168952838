export const DocumentTypes = {
  PASSPORT: 1,
  BIRTH_CERTIFICATE: 2,
  CEDULA_ID: 3,
  CONSENT_FORM: 4,
  NON_CONSENT_FORM: 5,
  CURP: 6,
  EVENT_WAIVER: 7,
  SINGLE_PARENT_GUARDIAN: 8,
  SUPPLEMENTAL_TPP_DOCUMENT: 9,
  ALL_PASSPORT_PAGES: 10,
  SWORN_DECLARATION: 11,
};
