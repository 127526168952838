import { datadogRum } from "@datadog/browser-rum";
import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { RouteConstants } from "../../constants/RouteConstants";
import { DropdownsProvider } from "../../contexts/DropdownsContext";
import { MetadataProvider } from "../../contexts/MetadataContext";
import RegistrationForm from "./RegistrationForm";

const INVITATION_LINK_ID = "invitationLinkId";
const IS_TPP = "isTPP";

const Registration: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    datadogRum.startSessionReplayRecording();
    return () => datadogRum.stopSessionReplayRecording();
  }, []);

  return (
    <MetadataProvider>
      <DropdownsProvider>
        <RegistrationForm
          onBack={() => navigate(RouteConstants.BASE)}
          invitationLinkId={searchParams.get(INVITATION_LINK_ID)}
          isTPP={searchParams.get(IS_TPP) === "true"}
        />
      </DropdownsProvider>
    </MetadataProvider>
  );
};

export default Registration;
