import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { RouteConstants } from "../constants/RouteConstants";
import { Auth0Token } from "../types/Auth";
import { useAuthentication } from "../contexts/AuthenticationContext";
import { getTokens, persistToken } from "../api/auth";
import { Auth0Callback } from "auth0-js";

const LoginCallback: React.FC = () => {
  const { setAuthenticated } = useAuthentication();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const code = searchParams.get("code");

  useEffect(() => {
    const callback: Auth0Callback<Auth0Token> = (error, result) => {
      if (!error) {
        persistToken(result);
        setAuthenticated(true);
        navigate(RouteConstants.BASE, { replace: true });
      }
    };

    getTokens(code, callback);
  }, [code]);

  return null;
};

export default LoginCallback;
