import React, { ReactNode, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  CompleteModalBody,
  CompleteModalCheckIcon,
  CompleteModalContainer,
  CompleteModalHeader,
  EmptyIcon,
  HeaderText,
  IconsContainer,
  NextStepContainer,
} from "./CompletionModalStyles";
import { Button, Modal, useBreakpoints } from "best-common-react";
import { RouteConstants } from "../../constants/RouteConstants";
import styled, { ThemeContext } from "styled-components";

const ContinueButton = styled(Button)`
  min-width: 100px;
`;

type SectionCompleteModalProps = {
  headerIcon: ReactNode;
  headerText: string;
  buttonText: string;
  children?: ReactNode;
  loading?: boolean;
};

const MobileModal: React.FC<SectionCompleteModalProps> = ({
  headerIcon,
  headerText,
  buttonText,
  children,
  loading,
}) => {
  const navigate = useNavigate();

  return (
    <CompleteModalContainer>
      <CompleteModalBody>
        <NextStepContainer>
          <IconsContainer>
            <CompleteModalCheckIcon />
            {headerIcon}
            {/* Centers Profile Icon */}
            <EmptyIcon />
          </IconsContainer>
          <HeaderText>{headerText}</HeaderText>
        </NextStepContainer>
        {children}
        <Button
          variant="secondary"
          className="w-100"
          size="lg"
          onClick={() => navigate(RouteConstants.BASE)}
          loading={loading}
        >
          {buttonText}
        </Button>
      </CompleteModalBody>
    </CompleteModalContainer>
  );
};

const DesktopModal: React.FC<SectionCompleteModalProps> = ({
  headerIcon,
  headerText,
  buttonText,
  children,
  loading,
}) => {
  const navigate = useNavigate();
  const Theme = useContext(ThemeContext);

  return (
    <Modal show={true} styles={{ ...Theme.modal, backdropOpacity: 1 }}>
      <Modal.Header>
        <CompleteModalHeader>
          <CompleteModalCheckIcon />
          {headerIcon}
          <div>{headerText}</div>
        </CompleteModalHeader>
      </Modal.Header>
      <Modal.Body>
        <div className="p-2">{children}</div>
      </Modal.Body>
      <Modal.Footer>
        <ContinueButton variant="primary" onClick={() => navigate(RouteConstants.BASE)} loading={loading}>
          {buttonText}
        </ContinueButton>
      </Modal.Footer>
    </Modal>
  );
};

const SectionCompleteModal: React.FC<SectionCompleteModalProps> = (props) => {
  const { isMobile } = useBreakpoints();
  return isMobile ? <MobileModal {...props} /> : <DesktopModal {...props} />;
};

export default SectionCompleteModal;
