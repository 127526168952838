import axios, { AxiosInstance } from "axios";
import { ProspectUserDetails } from "../types/Auth";
import {
  BaseballInformationDTO,
  BioInformationDTO,
  ProspectAddressDTO,
  ProspectNameDTO,
  ProspectNationalIdDTO,
  ProspectProfessionalTeamDTO,
  ProspectProfileDTO,
  ProspectRelativesDTO,
  ProspectTravelDateDTO,
  TrainerInformationDTO,
} from "../types/ProspectProfile";
import { EmailAddressDTO, PhoneNumberDTO } from "../types/Profile";
import { TrainerDTO } from "../types/Trainer";
import {
  ProspectConsentFormDTO,
  ConsentFormGuardianSignersDTO,
  ProspectConsentFormStatusDTO,
  ProspectTppConsentFormDTO,
} from "../types/ConsentForm";
import { FileUploadDTO, ProspectUploadDTO, RequiredDocumentDTO } from "../types/Documents";
import { HelpDescription, HelpResourceDTO } from "../types/Help";

const BASE_URL = window.MLBBest.envVariables.SERVICES_URL;

export const AuthenticatedClient: AxiosInstance = axios.create({
  baseURL: `${BASE_URL}/api`,
});

export const consentToDataStorage = async (): Promise<any> =>
  (await AuthenticatedClient.post("/prospect/consent")).data.entity;

export const getProspectUserDetails = async (): Promise<ProspectUserDetails> =>
  (await AuthenticatedClient.get(`/prospect/info`)).data.entity;

export const getProspectProfile = async (): Promise<ProspectProfileDTO> =>
  (await AuthenticatedClient.get(`/prospect/profile`)).data.entity;

export const completeProspectProfile = async (): Promise<void> =>
  (await AuthenticatedClient.post(`/prospect/profile/complete`)).data.entity;

export const submitProspect = async (): Promise<void> =>
  (await AuthenticatedClient.post(`/prospect/submit`)).data.entity;

export const getProspectConsentForm = async (): Promise<ProspectConsentFormDTO> =>
  (await AuthenticatedClient.get(`/prospect/consent-form`)).data.entity;

export const getProspectTppConsentForm = async (): Promise<ProspectTppConsentFormDTO> =>
  (await AuthenticatedClient.get(`/prospect/consent-form/tpp`)).data.entity;

export const getProspectConsentFormStatus = async (): Promise<ProspectConsentFormStatusDTO> =>
  (await AuthenticatedClient.get(`/prospect/consent-form/status`)).data.entity;

export const uploadProspectTppConsentForm = async (toUpload: FileUploadDTO): Promise<ProspectTppConsentFormDTO> =>
  (await AuthenticatedClient.post(`/prospect/consent-form`, toUpload)).data.entity;

export const downloadProspectTppConsentForm = async (): Promise<any> =>
  await AuthenticatedClient.get(`/prospect/consent-form/tpp/blank`, {
    responseType: "blob",
  });

export const downloadProspectOptionalConsentForm = async (): Promise<any> =>
  await AuthenticatedClient.get(`/prospect/consent-form/optional`, {
    responseType: "blob",
  });

export const acknowledgeDrugTestLanguage = async (): Promise<void> =>
  (await AuthenticatedClient.post(`/prospect/consent-form/tpp/drug-testing`)).data.entity;

export const getConsentFormGuardianSigners = async (): Promise<ConsentFormGuardianSignersDTO> =>
  (await AuthenticatedClient.get(`/prospect/consent-form/guardians`)).data.entity;

export const updateConsentFormGuardianSigners = async (
  guardians: ConsentFormGuardianSignersDTO,
): Promise<ConsentFormGuardianSignersDTO> =>
  (await AuthenticatedClient.put(`/prospect/consent-form/guardians`, guardians)).data.entity;

export const getProspectConsentFormSigningUrl = async (): Promise<string> =>
  (await AuthenticatedClient.get(`/prospect/consent-form/signingUrl`)).data.entity;

/* Prospect Profile */
export const saveProspectName = async (body: ProspectNameDTO): Promise<void> =>
  (await AuthenticatedClient.put(`/prospect/profile/name`, body)).data.entity;

export const saveBioInformation = async (body: BioInformationDTO): Promise<void> =>
  (await AuthenticatedClient.put(`/prospect/profile/bio-info`, body)).data.entity;

export const saveBaseballInformation = async (body: BaseballInformationDTO): Promise<void> =>
  (await AuthenticatedClient.put(`/prospect/profile/baseball-info`, body)).data.entity;

export const saveProspectProfessionalTeam = async (
  body: ProspectProfessionalTeamDTO,
): Promise<ProspectProfessionalTeamDTO> =>
  (await AuthenticatedClient.post(`/prospect/profile/pro-team`, body)).data.entity;

export const saveProspectTravelDate = async (body: ProspectTravelDateDTO): Promise<ProspectTravelDateDTO> =>
  (await AuthenticatedClient.post(`/prospect/profile/travel-date`, body)).data.entity;

export const deleteProspectProfessionalTeam = async (prospectProTeamId: number): Promise<void> =>
  (await AuthenticatedClient.delete(`/prospect/profile/pro-team/${prospectProTeamId}`)).data.entity;

export const deleteProspectTravelDate = async (travelDateId: number): Promise<void> =>
  (await AuthenticatedClient.delete(`/prospect/profile/travel-date/${travelDateId}`)).data.entity;

export const saveProspectAddress = async (body: ProspectAddressDTO): Promise<void> =>
  (await AuthenticatedClient.put(`/prospect/profile/address`, body)).data.entity;

export const saveProspectPhoneNumber = async (body: PhoneNumberDTO): Promise<PhoneNumberDTO> =>
  (await AuthenticatedClient.post(`/prospect/profile/phone-number`, body)).data.entity;

export const deleteProspectPhoneNumber = async (phoneNumberId: number): Promise<void> =>
  (await AuthenticatedClient.delete(`/prospect/profile/phone-number/${phoneNumberId}`)).data.entity;

export const saveProspectEmailAddress = async (body: EmailAddressDTO): Promise<EmailAddressDTO> =>
  (await AuthenticatedClient.post(`/prospect/profile/email-address`, body)).data.entity;

export const deleteProspectEmailAddress = async (emailAddressId: number): Promise<void> =>
  (await AuthenticatedClient.delete(`/prospect/profile/email-address/${emailAddressId}`)).data.entity;

export const saveProspectTrainerInformation = async (body: TrainerInformationDTO): Promise<void> =>
  (await AuthenticatedClient.put(`/prospect/profile/trainer-info`, body)).data.entity;

export const saveProspectNationalId = async (body: ProspectNationalIdDTO): Promise<void> =>
  (await AuthenticatedClient.put(`/prospect/profile/national-id`, body)).data.entity;

export const getTrainers = async (textSearch: string): Promise<TrainerDTO[]> =>
  (await AuthenticatedClient.post(`/trainers/search`, { textSearch })).data.entity;

export const associateProspectWithTrainer = async (trainerId: number): Promise<any> =>
  (await AuthenticatedClient.post(`/prospect/profile/trainers/${trainerId}/associate`)).data.entity;

export const disassociateProspectWithTrainer = async (trainerId: number): Promise<any> =>
  (await AuthenticatedClient.post(`/prospect/profile/trainers/${trainerId}/disassociate`)).data.entity;

export const saveProspectRelatives = async (body: ProspectRelativesDTO): Promise<void> =>
  (await AuthenticatedClient.put(`/prospect/profile/relatives`, body)).data.entity;

/* Trainers */
export const createTrainer = async (trainer: TrainerDTO): Promise<TrainerDTO> =>
  (await AuthenticatedClient.post(`/trainers/create`, trainer)).data.entity;

export const getTrainerMatches = async (trainer: TrainerDTO): Promise<TrainerDTO[]> =>
  (await AuthenticatedClient.post(`/trainers/matches`, trainer)).data.entity;

/* Prospect Documents */
export const uploadProspectDocument = async (toUpload: FileUploadDTO): Promise<ProspectUploadDTO> =>
  (await AuthenticatedClient.post(`/prospect/documents`, toUpload)).data.entity;

export const deleteProspectDocument = async (prospectUploadId: number): Promise<void> =>
  (await AuthenticatedClient.delete(`/prospect/documents/${prospectUploadId}`)).data.entity;

export const getProspectOutstandingDocumentsCount = async (): Promise<number> =>
  (await AuthenticatedClient.get(`/prospect/documents/outstanding`)).data.entity;

export const getProspectRequiredDocuments = async (): Promise<RequiredDocumentDTO[]> =>
  (await AuthenticatedClient.get(`/prospect/documents/required`)).data.entity;

export const getProspectUploadedDocuments = async (): Promise<ProspectUploadDTO[]> =>
  (await AuthenticatedClient.get(`/prospect/documents/uploaded`)).data.entity;

export const downloadProspectBlankSwornDeclaration = async (): Promise<any> =>
  await AuthenticatedClient.get(`/prospect/documents/sworn-declaration`, {
    responseType: "blob",
  });

/* Help */
export const getHelpResources = async (): Promise<HelpResourceDTO[]> =>
  (await AuthenticatedClient.get(`/help/resources`)).data.entity;

export const getHelpDescription = async (): Promise<HelpDescription> =>
  (await AuthenticatedClient.get(`/help/description`)).data.entity;
