import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: inherit;
`;

const ButtonContainer = styled.div`
  font-size: 1.5rem;
`;

const LanguageContainer = styled.div`
  font-size: 1rem;
  font-weight: bold;
`;

const PointerDiv = styled.div`
  width: fit-content;
  cursor: pointer;
`;

const Text = styled.span`
  margin-left: 0.5rem;
`;

type BackButtonProps = {
  onClick: () => void;
};

const BackButton: React.FC<BackButtonProps> = ({ onClick }) => {
  const { t, i18n } = useTranslation(["onboarding", "navigation"]);

  const toggleLanguage = async () => {
    if (i18n.language === "es") {
      document.documentElement.lang = "en";
      await i18n.changeLanguage("en");
    } else {
      document.documentElement.lang = "es";
      await i18n.changeLanguage("es");
    }
  };

  return (
    <Container>
      <ButtonContainer>
        <PointerDiv onClick={onClick}>
          <i className="fas fa-angle-left" />
          <Text>{t("onboarding:back")}</Text>
        </PointerDiv>
      </ButtonContainer>
      <LanguageContainer>
        <PointerDiv onClick={toggleLanguage}>{t("navigation:changeLanguage.name")}</PointerDiv>
      </LanguageContainer>
    </Container>
  );
};

export default BackButton;
