import React, { ReactNode } from "react";
import { DropdownsProvider } from "./DropdownsContext";
import { MetadataProvider } from "./MetadataContext";
import { AlertProvider, LoadingProvider } from "best-common-react";

type UnauthenticatedContextsProps = {
  children?: ReactNode;
};

const UnauthenticatedContexts: React.FC<UnauthenticatedContextsProps> = ({ children }) => (
  <LoadingProvider>
    <AlertProvider>
      <MetadataProvider>
        <DropdownsProvider>{children}</DropdownsProvider>
      </MetadataProvider>
    </AlertProvider>
  </LoadingProvider>
);

export default UnauthenticatedContexts;
