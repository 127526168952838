import btoa from "btoa";

function base64URLEncode(str: string): string {
  return btoa(str).replace(/\+/g, "-").replace(/\//g, "_").replace(/=/g, "");
}

function generateVerifier(size: number): string {
  const charset = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_.~";
  const charsetIndexBuffer = new Uint8Array(size);

  for (let i = 0; i < size; i += 1) {
    charsetIndexBuffer[i] = (Math.random() * charset.length) | 0;
  }

  const randomChars = [];
  for (let i = 0; i < charsetIndexBuffer.byteLength; i += 1) {
    const index = charsetIndexBuffer[i] % charset.length;
    randomChars.push(charset[index]);
  }

  return randomChars.join("");
}

const computeChallenge = async (verifier: string) => {
  const buffer = new TextEncoder().encode(verifier);
  const hash = await window.crypto.subtle.digest("SHA-256", buffer);
  const str = String.fromCharCode.apply(null, new Uint8Array(hash) as unknown as number[]);
  return base64URLEncode(str);
};

export { generateVerifier, computeChallenge };
