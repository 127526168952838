import React from "react";
import BaseballInfoReadyOnly from "./read-only/BaseballInfoReadyOnly";
import BioInfoReadyOnly from "./read-only/BioInfoReadOnly";
import ContactInfoReadOnly from "./read-only/ContactInfoReadOnly";
import TrainerInfoReadyOnly from "./read-only/TrainerInfoReadOnly";
import IPLContentContainer from "../display/IPLContentContainer";
import ProspectNameReadOnly from "./read-only/ProspectNameReadOnly";
import { useProspectProfile } from "../../contexts/ProsepctProfileContext";
import ProspectAddressReadOnly from "./read-only/ProspectAddressReadOnly";
import ProspectNationalIdReadOnly from "./read-only/ProspectNationalIdReadOnly";
import { Button } from "best-common-react";
import { RouteConstants } from "../../constants/RouteConstants";
import IplContentButtons from "../display/IplContentButtons";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ProspectRelativesReadOnly from "./read-only/ProspectRelativesReadOnly";

const ProspectReadOnlyProfile: React.FC = () => {
  const { t } = useTranslation("translation");
  const { profile } = useProspectProfile();
  const navigate = useNavigate();

  if (!profile) return undefined;

  return (
    <IPLContentContainer>
      <ProspectNameReadOnly />
      <BioInfoReadyOnly />
      <BaseballInfoReadyOnly />
      <ProspectAddressReadOnly />
      <ContactInfoReadOnly />
      <TrainerInfoReadyOnly />
      <ProspectRelativesReadOnly />
      <ProspectNationalIdReadOnly />
      <IplContentButtons>
        <Button variant="secondary" className="w-100" size="lg" onClick={() => navigate(RouteConstants.BASE)}>
          {t("translation:close")}
        </Button>
      </IplContentButtons>
    </IPLContentContainer>
  );
};

export default ProspectReadOnlyProfile;
