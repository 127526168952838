import React from "react";
import { Button, Modal } from "best-common-react";
import { useTranslation } from "react-i18next";

type RecentStayTooLongWarningModalProps = {
  show: boolean;
  onClose: () => void;
};

const RecentStayTooLongWarningModal: React.FC<RecentStayTooLongWarningModalProps> = ({ show, onClose }) => {
  const { t } = useTranslation(["profile", "translation"]);

  return (
    <Modal show={show}>
      <Modal.Header>{t("translation:attention")}</Modal.Header>
      <Modal.Body>
        {t("profile:recentStayTooLongWarning1")} <a href="mailto:registro@mlb.com">registro@mlb.com</a>{" "}
        {t("profile:recentStayTooLongWarning2")}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={onClose}>
          {t("translation:continue")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default RecentStayTooLongWarningModal;
