import React from "react";
import { Button, Icon, SubSection } from "best-common-react";
import { Label, Value } from "../../read-only/ReadOnlyProfileStyles";
import { LinkButtonStyles } from "../contact-info/ContactInformationStyles";
import { useTranslation } from "react-i18next";
import { useProspectProfile } from "../../../../contexts/ProsepctProfileContext";
import { deleteProspectTravelDate, saveProspectTravelDate } from "../../../../api/AuthenticatedClient";
import { ProspectTravelDateDTO } from "../../../../types/ProspectProfile";
import AddTravelDateModal from "./AddTravelDateModal";
import styled from "styled-components";
import { useMetadata } from "../../../../contexts/MetadataContext";
import { dateFormats } from "../../../../util/ProfileUtils";
import { format, parse } from "date-fns";

const Card = styled.div`
  display: flex;
  padding: 0.5rem;
  border: 1px solid ${(props) => props.theme.colors.fog};
  background: ${(props) => props.theme.colors.white};
`;

const CardInfo = styled.div`
  width: 90%;
  display: flex;
  flex-wrap: wrap;
`;

const IconContainer = styled.div`
  width: 10%;
  display: flex;
  justify-content: end;
  align-content: start;
`;

const Column = styled.div`
  display: flex;
  flex-basis: 50%;
  flex-direction: column;
`;

const ButtonText = styled.span`
  margin-left: 0.25rem;
`;

const Placeholder = styled.div`
  height: 42px;
`;

const Asterisk = styled.span`
  color: ${(props) => props.theme.colors["mlb-red"]};
`;

const CubanBornBioInfo: React.FC = () => {
  const { t } = useTranslation(["profile", "translation"]);
  const { profile, updateProfileField } = useProspectProfile();
  const { countries } = useMetadata();
  const [showAddTravelDateModal, setShowAddTravelDateModal] = React.useState(false);

  const onDelete = async (id: number) => {
    await deleteProspectTravelDate(id);
    updateProfileField(
      "travelDates",
      profile.travelDates.filter((travelDate) => travelDate.id !== id),
    );
  };

  const onSave = async (toSave: ProspectTravelDateDTO) => {
    const saved = await saveProspectTravelDate(toSave);
    updateProfileField("travelDates", [...profile.travelDates, saved]);
  };

  return (
    <>
      <AddTravelDateModal
        show={showAddTravelDateModal}
        onClose={() => setShowAddTravelDateModal(false)}
        onSave={onSave}
      />
      <div className="row pt-3 pb-3">
        <SubSection
          header={
            <span>
              {t("profile:travelDates")} <Asterisk>*</Asterisk>
            </span>
          }
        >
          {profile.travelDates?.map((travelDate) => {
            const country = countries.find((country) => country.countryId === travelDate.countryId);
            const countryName = !!country?.esDescription?.length ? country.esDescription : country.description;

            const startDate = format(
              parse(travelDate.startDate, dateFormats.YYYY_MM_DD, new Date()),
              dateFormats.MM_DD_YYYY,
            );
            const endDate = format(
              parse(travelDate.endDate, dateFormats.YYYY_MM_DD, new Date()),
              dateFormats.MM_DD_YYYY,
            );

            return (
              <Card key={`travel-date-card-${travelDate.id}`}>
                <CardInfo>
                  <Column>
                    <div className="mb-2">
                      <Label>{t("translation:startDate")}</Label>
                      <Value>{startDate}</Value>
                    </div>
                    <div>
                      <Label>{t("profile:country")}</Label>
                      <Value>{countryName}</Value>
                    </div>
                    <Placeholder />
                  </Column>
                  <Column>
                    <div className="mb-2">
                      <Label>{t("translation:endDate")}</Label>
                      <Value>{endDate}</Value>
                    </div>
                    <div>
                      <Label>{t("profile:stateTerritory")}</Label>
                      <Value>{travelDate.state}</Value>
                    </div>
                    <div>
                      <Label>{t("profile:city")}</Label>
                      <Value>{travelDate.city}</Value>
                    </div>
                  </Column>
                  <div className="mt-2" style={{ flexBasis: "100%" }}>
                    <Label>{t("profile:purpose")}</Label>
                    <Value>{travelDate.purpose}</Value>
                  </div>
                </CardInfo>
                <IconContainer>
                  <Icon iconName="fa-trash-alt" onClick={() => onDelete(travelDate.id)} />
                </IconContainer>
              </Card>
            );
          })}
        </SubSection>
      </div>
      <div className="pb-3">
        <Button
          variant="link"
          className="ps-0"
          styles={LinkButtonStyles}
          onClick={() => setShowAddTravelDateModal(true)}
        >
          <i className="fa-solid fa-circle-plus" />
          <ButtonText>{t("profile:addTravelDateButton")}</ButtonText>
        </Button>
      </div>
    </>
  );
};

export default CubanBornBioInfo;
