import axios, { AxiosInstance } from "axios";
import { RegistrationCutoffDTO, RegistrationDTO, RegistrationInfoDTO } from "../types/Registration";
import {
  BattingSide,
  CacheTimeDTO,
  City,
  CommunicationType,
  Country,
  District,
  EmailAddressType,
  IntlProfessionalLeague,
  IntlProfessionalTeam,
  MexicanLeagueTeam,
  NationalIDType,
  PhoneNumberCommunicationType,
  PhoneNumberType,
  Position,
  Province,
  Sector,
  State,
  ThrowingHand,
  UnitOfMeasurement,
} from "../types/Metadata";
import { ActuatorHealth } from "../types/Auth";

const BASE_URL = window.MLBBest.envVariables.SERVICES_URL;

const UnauthenticatedClient: AxiosInstance = axios.create({
  baseURL: `${BASE_URL}/api`,
});

/* Prospect Registration */
export const getCacheRefresh = async (): Promise<CacheTimeDTO> =>
  (await UnauthenticatedClient.get(`/cache`)).data.entity;

export const validateRegistration = async (registrationDTO: RegistrationDTO): Promise<void> =>
  (await UnauthenticatedClient.post(`/registration/validate`, registrationDTO)).data.entity;

export const registerProspect = async (registrationDTO: RegistrationDTO): Promise<void> =>
  (await UnauthenticatedClient.post(`/registration`, registrationDTO)).data.entity;

export const getRegistrationInformation = async (invitationLinkId: string): Promise<RegistrationInfoDTO> =>
  (await UnauthenticatedClient.get(`/registration/${invitationLinkId}`)).data.entity;

export const checkRegistrationPeriod = async (): Promise<any> =>
  (await UnauthenticatedClient.get(`/registration/open`)).data.entity;

export const getRegistrationCutoffDates = async (): Promise<RegistrationCutoffDTO> =>
  (await UnauthenticatedClient.get(`/registration/cutoff`)).data.entity;

/* Application Metadata */
export const getNationalIDTypes = async (): Promise<NationalIDType[]> =>
  (await UnauthenticatedClient.get("/metadata/nationalIdTypes")).data.entity;

export const getCountries = async (): Promise<Country[]> =>
  (await UnauthenticatedClient.get("/metadata/countries")).data.entity;

export const getStates = async (): Promise<State[]> =>
  (await UnauthenticatedClient.get("/metadata/states")).data.entity;

export const getProvinces = async (): Promise<Province[]> =>
  (await UnauthenticatedClient.get("/metadata/provinces")).data.entity;

export const getDistricts = async (countryId: number): Promise<District[]> =>
  (await UnauthenticatedClient.get(`/metadata/districts/${countryId}`)).data.entity;

export const getCities = async (stateProvinceDistrictId: number): Promise<City[]> =>
  (await UnauthenticatedClient.get(`/metadata/cities/${stateProvinceDistrictId}`)).data.entity;

export const getSectors = async (cityId: number): Promise<Sector[]> =>
  (await UnauthenticatedClient.get(`/metadata/sectors/${cityId}`)).data.entity;

export const getPositions = async (): Promise<Position[]> =>
  (await UnauthenticatedClient.get("/metadata/positions")).data.entity;

export const getThrowingHands = async (): Promise<ThrowingHand[]> =>
  (await UnauthenticatedClient.get("/metadata/throwingHand")).data.entity;

export const getBattingSide = async (): Promise<BattingSide[]> =>
  (await UnauthenticatedClient.get("/metadata/battingSide")).data.entity;

export const getPhoneNumberTypes = async (): Promise<PhoneNumberType[]> =>
  (await UnauthenticatedClient.get("/metadata/phoneNumberTypes")).data.entity;

export const getPhoneNumberCommunicationTypes = async (): Promise<PhoneNumberCommunicationType[]> =>
  (await UnauthenticatedClient.get("/metadata/phoneNumberCommunicationTypes")).data.entity;

export const getEmailAddressTypes = async (): Promise<EmailAddressType[]> =>
  (await UnauthenticatedClient.get("/metadata/emailAddressTypes")).data.entity;

export const getHeightUnitsOfMeasurement = async (): Promise<UnitOfMeasurement[]> =>
  (await UnauthenticatedClient.get("/metadata/heightUnitsOfMeasurement")).data.entity;

export const getWeightUnitsOfMeasurement = async (): Promise<UnitOfMeasurement[]> =>
  (await UnauthenticatedClient.get("/metadata/weightUnitsOfMeasurement")).data.entity;

export const getMexicanLeagueTeams = async (): Promise<MexicanLeagueTeam[]> =>
  (await UnauthenticatedClient.get("/stats/teams/mexicanLeague")).data.entity;

export const getCommunicationTypes = async (): Promise<CommunicationType[]> =>
  (await UnauthenticatedClient.get("/metadata/communicationPreference")).data.entity;

export const getIntlProfessionalLeagues = async (): Promise<IntlProfessionalLeague[]> =>
  (await UnauthenticatedClient.get(`/metadata/intl-professional-leagues`)).data.entity;

export const getIntlProfessionalTeamsByLeagueId = async (leagueId: number): Promise<IntlProfessionalTeam[]> =>
  (await UnauthenticatedClient.get(`/metadata/intl-professional-leagues/${leagueId}/teams`)).data.entity;

export const getIntlProfessionalTeams = async (): Promise<IntlProfessionalTeam[]> =>
  (await UnauthenticatedClient.get(`/metadata/intl-professional-teams`)).data.entity;

export const healthCheck = async (): Promise<ActuatorHealth> => await axios.get(`${BASE_URL}/actuator/health`);
