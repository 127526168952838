import React, { useContext } from "react";
import { useProspectProfile } from "../../../contexts/ProsepctProfileContext";
import { Accordion, AccordionBody, AccordionHeader, useBreakpoints } from "best-common-react";
import { Body, Item, Label, Row, Value } from "./ReadOnlyProfileStyles";
import { useMetadata } from "../../../contexts/MetadataContext";
import { useTranslation } from "react-i18next";
import { ThemeContext } from "styled-components";
import { HeightUnits, WeightUnits } from "../../../constants/ProfileConstants";

const BaseballInfoReadyOnly: React.FC = () => {
  const { t, i18n } = useTranslation();
  const { profile } = useProspectProfile();
  const { isMobile } = useBreakpoints();
  const Theme = useContext(ThemeContext);
  const { positions, battingSides, throwingHands } = useMetadata();

  const position = positions.find((p) => p.positionId === profile?.positionId);
  const bats = battingSides.find((p) => p.battingSideId === profile?.batsId);
  const throws = throwingHands.find((p) => p.throwingHandId === profile?.throwingHandId);
  const height =
    profile?.heightUnitId === HeightUnits.CM
      ? profile.height
      : `${Math.floor(profile.height / 12)}'${profile.height % 12}"`;
  const weight = profile?.weightUnitId === WeightUnits.KG ? `${profile.weight} kg` : `${profile.weight} lbs`;

  return (
    <Accordion
      accordionKey="basball-info"
      styles={{ ...Theme.accordion, body: { ...Theme.accordion.body, background: Theme.colors.cloud, border: "none" } }}
    >
      <AccordionHeader>{t("profile:baseballInformationSection")}</AccordionHeader>
      <AccordionBody>
        <Body>
          <Row>
            <Item isMobile={isMobile}>
              <Label>{t("profile:position")}</Label>
              <Value>{i18n.language === "en" ? position?.description : position?.esDescription}</Value>
            </Item>
          </Row>
          <Row>
            <Item isMobile={isMobile}>
              <Label>{t("profile:bats")}</Label>
              <Value>{i18n.language === "en" ? bats?.description : bats?.esDescription}</Value>
            </Item>
            <Item isMobile={isMobile}>
              <Label>{t("profile:throws")}</Label>
              <Value>{i18n.language === "en" ? throws?.description : throws?.esDescription}</Value>
            </Item>
          </Row>
          <Row>
            <Item isMobile={isMobile}>
              <Label>
                {profile?.heightUnitId === HeightUnits.CM ? t("profile:heightCm") : t("profile:heightFtIn")}
              </Label>
              <Value>{height}</Value>
            </Item>
            <Item isMobile={isMobile}>
              <Label>{t("profile:weight")}</Label>
              <Value>{weight}</Value>
            </Item>
          </Row>
        </Body>
      </AccordionBody>
    </Accordion>
  );
};

export default BaseballInfoReadyOnly;
