export const RouteConstants = {
  BASE: "/",
  PROFILE: "/profile",
  CONSENT_FORM: "/consent-form",
  CONSENT_FORM_SIGNED: "/consent-form/signed",
  DOCUMENTS: "/documents",
  LOGIN_CALLBACK: "/login/callback",
  REGISTER: "/register",
  WILDCARD: "*",
};
