import { CountryIds } from "../types/Metadata";

export const ProfileStatuses = {
  NOT_REGISTERED: 1,
  PENDING: 2,
  VERIFIED: 3,
  SENT_TO_EBIS: 4,
  PROFILE_COMPLETE: 5,
  CORRECTION_NEEDED: 6,
};

export const HeightUnits = {
  CM: 1,
  IN: 2,
};

export const WeightUnits = {
  KG: 1,
  LB: 2,
};

export const CUBA = {
  id: 18,
  name: "Cuba",
};

export const MEXICO = {
  id: 41,
  name: "Mexico",
};

export const REQUIRED_NATIONAL_ID_COUNTRIES = [CountryIds.MEXICO, CountryIds.VEN];

export const USA_TERRITORIES_CANADA_IDS = [
  CountryIds.USA,
  CountryIds.CANADA,
  CountryIds.PR,
  CountryIds.USVI,
  CountryIds.GUAM,
];

export const REQUIRED_ZIP_CODE_COUNTRIES = [
  CountryIds.USVI,
  CountryIds.MEXICO,
  CountryIds.JAPAN,
  CountryIds.GUAM,
  CountryIds.PR,
  CountryIds.CANADA,
  CountryIds.USA,
];
