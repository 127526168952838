import React from "react";
import styled from "styled-components";

const DividerContainer = styled.div`
  width: 100%;
  margin-top: 2rem;
  display: flex;
  justify-content: center;
`;

const DividerDiv = styled.div`
  width: 75%;
  border-bottom: 1px solid darkgrey;
`;

const Divider: React.FC = () => {
  return (
    <DividerContainer>
      <DividerDiv />
    </DividerContainer>
  );
};

export default Divider;
