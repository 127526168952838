import React, { useState } from "react";
import { Button, Paper, RadioGroup, useLoading } from "best-common-react";
import { useDropdowns } from "../../../contexts/DropdownsContext";
import { TrainerDTO } from "../../../types/Trainer";
import { LinkButtonStyles } from "./contact-info/ContactInformationStyles";
import styled from "styled-components";
import { associateProspectWithTrainer, disassociateProspectWithTrainer } from "../../../api/AuthenticatedClient";
import { useProspectProfile } from "../../../contexts/ProsepctProfileContext";
import TrainerDisplay from "./trainer/TrainerDisplay";
import ProspectProfileTrainerModal from "./trainer/ProspectProfileTrainerModal";
import { useTranslation } from "react-i18next";

const ButtonText = styled.span`
  margin-left: 0.25rem;
`;

const TrainerInformationSection: React.FC = () => {
  const { t } = useTranslation("profile");
  const { yesNoRadioValues, getYesNoRadioValues } = useDropdowns();
  const { profile, updateProfileField } = useProspectProfile();
  const { setLoading } = useLoading();
  const [addTrainerModalOpen, setAddTrainerModalOpen] = useState(false);

  const addTrainer = async (trainer: TrainerDTO) => {
    await associateProspectWithTrainer(trainer.trainerId);
    updateProfileField("trainers", [...profile.trainers, trainer]);
    setAddTrainerModalOpen(false);
  };

  const removeTrainer = async (trainerId: number) => {
    setLoading(true);
    await disassociateProspectWithTrainer(trainerId);
    updateProfileField(
      "trainers",
      profile.trainers.filter((t) => t.trainerId != trainerId),
    );
    setLoading(false);
  };

  return (
    <>
      {addTrainerModalOpen && (
        <ProspectProfileTrainerModal onClose={() => setAddTrainerModalOpen(false)} addTrainer={addTrainer} />
      )}
      <div>
        <Paper className="mb-3">{t("profile:trainerInformationSection")}</Paper>
        <RadioGroup
          id="has-trainer-question"
          label={t("profile:hasTrainer")}
          name="trainer-radio-buttons"
          className="pb-2"
          required
          options={yesNoRadioValues.sort((a, b) => (a.value > b.value ? -1 : 1))}
          value={getYesNoRadioValues(profile?.hasTrainer)?.value}
          onChange={(value) => {
            updateProfileField("hasTrainer", value === 1);
          }}
        />
        {!!profile?.hasTrainer && (
          <div>
            {profile?.trainers.map((trainer) => (
              <TrainerDisplay
                key={`trainer-${trainer.trainerId}`}
                trainer={trainer}
                onRemove={() => removeTrainer(trainer.trainerId)}
              />
            ))}
            <Button
              variant="link"
              className="ps-0"
              styles={LinkButtonStyles}
              onClick={() => setAddTrainerModalOpen(true)}
            >
              <i className="fa-solid fa-circle-plus" />
              <ButtonText>{t("profile:addTrainer")}</ButtonText>
            </Button>
          </div>
        )}
      </div>
    </>
  );
};

export default TrainerInformationSection;
