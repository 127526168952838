import React from "react";
import { useProspectProfile } from "../../../contexts/ProsepctProfileContext";
import { FormColumn2Wide, Input, Paper } from "best-common-react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const Container = styled.div``;

const NameSection: React.FC = () => {
  const { t } = useTranslation("profile");
  const { profile, updateProfileField } = useProspectProfile();

  return (
    <Container>
      <Paper className="mb-3">{t("profile:nameSection")}</Paper>
      <div className="row pt-2">
        <FormColumn2Wide>
          <Input
            id="first-name"
            label={t("profile:firstName")}
            type="text"
            className="pb-2"
            required
            value={profile?.firstName}
            onChange={(value) => {
              updateProfileField("firstName", value);
            }}
          />
        </FormColumn2Wide>
        <FormColumn2Wide>
          <Input
            id="last-name"
            label={t("profile:lastName")}
            type="text"
            required
            value={profile?.lastName}
            onChange={(value) => {
              updateProfileField("lastName", value);
            }}
          />
        </FormColumn2Wide>
      </div>

      <div className="row pt-2">
        <FormColumn2Wide>
          <Input
            id="middle-name"
            label={t("profile:middleName")}
            type="text"
            className="pb-2"
            required
            showNA
            value={profile?.middleName}
            onChange={(value) => {
              updateProfileField("middleName", value);
            }}
          />
        </FormColumn2Wide>
        <FormColumn2Wide>
          <Input
            id="ext-last-name"
            label={t("profile:extLastName")}
            type="text"
            required
            showNA
            value={profile?.extLastName}
            onChange={(value) => {
              updateProfileField("extLastName", value);
            }}
          />
        </FormColumn2Wide>
      </div>
    </Container>
  );
};

export default NameSection;
