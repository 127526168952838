import React, { useEffect, useState } from "react";
import SectionCompleteModal from "./SectionCompleteModal";
import ConsentFormIcon from "../icons/ConsentFormIcon";
import { HeaderText, NextStepContainer } from "./CompletionModalStyles";
import DocumentsIcon from "../icons/DocumentsIcon";
import { useProspectConsentFormSse } from "../../contexts/ProspectConsentFormSseContext";
import { getProspectConsentForm } from "../../api/AuthenticatedClient";
import { useTranslation } from "react-i18next";

const ConsentFormCompleteModal: React.FC = () => {
  const { t } = useTranslation(["navigation", "home", "translation", "consent-form"]);
  const { signedTs } = useProspectConsentFormSse();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getProspectConsentForm().then((res) => {
      if (!!res.prospectSignedTs) {
        setLoading(false);
      }
    });
  }, []);

  useEffect(() => {
    if (!!signedTs) {
      setLoading(false);
    }
  }, [signedTs]);

  return (
    <SectionCompleteModal
      headerIcon={<ConsentFormIcon />}
      headerText={t("home:consentFormSigned")}
      buttonText={t("translation:continue")}
      loading={loading}
    >
      {loading ? (
        <div>{t("consent-form:consentFormProcessing")}</div>
      ) : (
        <NextStepContainer>
          <HeaderText>{t("navigation:nextStep")}</HeaderText>
          <div>{t("home:documentsCardHeaderIncomplete")}</div>
          <DocumentsIcon />
        </NextStepContainer>
      )}
    </SectionCompleteModal>
  );
};

export default ConsentFormCompleteModal;
