import React from "react";
import styled from "styled-components";
import FooterImage from "./FooterImage";

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const FillerBackground = styled.div`
  width: 100%;
  height: 50px;
  background: ${(props) => props.theme.colors["mlb-navy"]};
`;

const Footer: React.FC = () => {
  return (
    <Container>
      <FooterImage />
      <FillerBackground />
    </Container>
  );
};

export default Footer;
