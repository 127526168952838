import { Auth0Callback, Auth0Error, WebAuth } from "auth0-js";
import { Auth0Token, AuthenticationToken } from "../types/Auth";
import { getLocalStorageItem, setLocalStorageItem } from "best-common-react";
import { LocalStorageConstants } from "../constants/LocalStorageConstants";
import jwt_decode from "jwt-decode";
import { IdToken } from "@auth0/auth0-react";
import { computeChallenge, generateVerifier } from "./pkce";

export type Auth0ErrorCallback<E = Auth0Error> = (error: null | E) => void;

const DOMAIN = window.MLBBest.envVariables.AUTH0_DOMAIN;
const CLIENT_ID = window.MLBBest.envVariables.AUTH0_CLIENT_ID;
const REALM = window.MLBBest.envVariables.AUTH0_REALM;
const AUDIENCE = window.MLBBest.envVariables.AUTH0_AUDIENCE;

const webAuth = new WebAuth({
  domain: DOMAIN,
  clientID: CLIENT_ID,
  audience: AUDIENCE,
  scope: "openid profile email offline_access",
});

function parseIdToken(idToken: string, field: string): string {
  const decoded = jwt_decode<IdToken>(idToken);
  return decoded[field];
}

function login(username: string, password: string, callback?: Auth0ErrorCallback) {
  const verifier = generateVerifier(43);
  setLocalStorageItem(LocalStorageConstants.CODE_VERIFIER, verifier);

  computeChallenge(verifier).then((challenge) => {
    webAuth.login(
      {
        realm: REALM,
        username: username,
        password: password,
        responseType: "code",
        redirectUri: `${window.location.origin}/login/callback`,
        // @ts-ignore
        codeChallenge: challenge,
        codeChallengeMethod: "S256",
      },
      (error) => {
        if (callback) callback(error);
      },
    );
  });
}

function getTokens(authorization_code: string, callback?: Auth0Callback<Auth0Token>) {
  const verifier = getLocalStorageItem(LocalStorageConstants.CODE_VERIFIER);
  webAuth.client.oauthToken(
    {
      grantType: "authorization_code",
      code: authorization_code,
      codeVerifier: verifier,
      redirectUri: window.location.origin,
    },
    (error, result) => {
      if (callback) callback(error, result);
    },
  );
}

function refresh(refresh_token: string, callback?: Auth0ErrorCallback) {
  webAuth.client.oauthToken(
    {
      grantType: "refresh_token",
      refreshToken: refresh_token,
    },
    (error, result) => {
      if (!error) {
        persistToken(result as Auth0Token);
      }
      callback(error);
    },
  );
}

function register(username: string, password: string, callback: (error?: Auth0Error, id?: string) => void) {
  webAuth.signupAndAuthorize(
    {
      email: username,
      password: password,
      connection: "Username-Password-Authentication",
    },
    (error, result) => {
      let id;
      if (!error) {
        persistToken(result as Auth0Token);
        id = parseIdToken(result.idToken, "sub");
      }
      callback(error, id);
    },
  );
}

function persistToken(token_: Auth0Token) {
  const token: AuthenticationToken = {
    accessToken: token_.accessToken,
    idToken: token_.idToken,
    refreshToken: token_.refreshToken,
    expiresAt: Date.now() + token_.expiresIn,
  };
  setLocalStorageItem(LocalStorageConstants.AUTH0_TOKEN, token);
}

export { webAuth, login, refresh, register, getTokens, persistToken };
