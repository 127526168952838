import { Input, Select, ValueOpt } from "best-common-react";
import React from "react";
import { useTranslation } from "react-i18next";

type StateSelectOrTextInputProps<T> = {
  id: string;
  label: string;
  idField: string;
  idValue: number;
  textValue: string;
  options: ValueOpt<T>[];
  getOptionValue: (value: number) => ValueOpt<T>;
  onChange: (idValue: number | null, textValue: string | null) => void;
  required: boolean;
  readOnly?: boolean;
  disabled: boolean;
  clearable?: boolean;
};

const StateSelectOrTextInput = <T,>(props: StateSelectOrTextInputProps<T>) => {
  const {
    id,
    label,
    idField,
    idValue,
    textValue,
    options,
    getOptionValue,
    onChange,
    required,
    readOnly = false,
    disabled,
    clearable = false,
  } = props;
  const { t } = useTranslation("translation");

  return !!options?.length ? (
    <Select
      id={`${id}-select`}
      label={label}
      value={getOptionValue(idValue)}
      options={options}
      onChange={(option: ValueOpt<T> | undefined) => {
        // @ts-ignore
        onChange(option?.value[idField] as number, option?.value.description as string);
      }}
      placeholder={t("translation:selectPlaceholder")}
      required={required}
      readOnly={readOnly}
      disabled={disabled}
      clearable={clearable}
    />
  ) : (
    <Input
      id={`${id}-input`}
      type="text"
      label={label}
      value={textValue ? textValue : ""}
      onChange={(value) => onChange(null, value)}
      required={required}
      readOnly={readOnly}
      disabled={disabled}
    />
  );
};

export default StateSelectOrTextInput;
