import { Input, Select, ValueOpt } from "best-common-react";
import React from "react";
import { City } from "../../../types/Metadata";
import { useTranslation } from "react-i18next";

type CitySelectOrTextInputProps = {
  id: string;
  label: string;
  cityId: number;
  city: string;
  options: ValueOpt<City>[];
  onChange: (cityId: number | null, city: string | null) => void;
  readOnly?: boolean;
  disabled: boolean;
  required?: boolean;
  clearable?: boolean;
};

const CitySelectOrTextInput: React.FC<CitySelectOrTextInputProps> = ({
  id,
  label,
  cityId,
  city,
  options,
  onChange,
  readOnly = false,
  disabled,
  required,
  clearable = false,
}) => {
  const { t } = useTranslation("translation");

  return options?.length > 0 ? (
    <Select
      id={`${id}-select`}
      label={label}
      value={options.find((option) => option.value.cityId === cityId)}
      options={options}
      onChange={(option: ValueOpt<City> | undefined) => {
        onChange(option?.value.cityId, option?.value.description);
      }}
      placeholder={t("translation:selectPlaceholder")}
      readOnly={readOnly}
      disabled={disabled}
      required={required}
      clearable={clearable}
    />
  ) : (
    <Input
      id={`${id}-input`}
      label={label}
      type="text"
      value={city ? city : ""}
      onChange={(value) => {
        onChange(null, value);
      }}
      readOnly={readOnly}
      disabled={disabled}
      required={required}
    />
  );
};

export default CitySelectOrTextInput;
