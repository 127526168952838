import { CountryCode } from "libphonenumber-js/types";
import { ProspectUploadDTO } from "./Documents";
import { TrainerDTO } from "./Trainer";

export type ProfileSectionKeys = "baseballInfo" | "bioInfo" | "contactInfo" | "consent" | "documents";

export type ProfileCompleteSections = {
  baseballInfo: boolean;
  bioInfo: boolean;
  contactInfo: boolean;
  consent: boolean;
  documents: boolean;
};

export type ProfileKeys =
  | "firstName"
  | "middleName"
  | "lastName"
  | "extLastName"
  | "hasTwin"
  | "twinCount"
  | "registrationCountryId"
  | "birthCountryId"
  | "birthStateProvince"
  | "birthStateProvinceId"
  | "birthCity"
  | "birthCityId"
  | "birthSectorId"
  | "traveledToUsCanPrCount"
  | "longestStayUsCanPr"
  | "recentStayUsPrCan"
  | "nationalIds"
  | "positionId"
  | "batsId"
  | "throwingHandId"
  | "height"
  | "heightUnitId"
  | "weight"
  | "weightUnitId"
  | "dateOfBirth"
  | "mexicanLeagueTeamId"
  | "addressCountryId"
  | "addressStateProvinceId"
  | "addressStateProvince"
  | "addressCityId"
  | "addressCity"
  | "addressSectorId"
  | "address1"
  | "address2"
  | "address3"
  | "addressZipCode"
  | "phoneNumbers"
  | "emailAddresses"
  | "numberGuardiansSigned"
  | "uploadDocuments"
  | "consentForms"
  | "hasTrainer"
  | "trainers"
  | "isProfileLocked"
  | "nationalId";

export type ProspectProfileDTO = {
  prospectId?: number;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  extLastName?: string;
  fullName?: string;
  hasTwin?: boolean;
  twinCount?: number;
  registrationCountryId?: number;
  birthCountryId?: number;
  birthStateProvince?: string;
  birthStateProvinceId?: number;
  birthCity?: string;
  birthCityId?: number;
  birthSectorId?: number;
  traveledToUsCanPrFlag?: boolean;
  traveledToUsCanPrCount?: number;
  longestStayUsCanPr?: number;
  recentStayUsPrCan?: string;
  nationalIds?: NationalIdDTO[];
  positionId?: number;
  batsId?: number;
  throwingHandId?: number;
  height?: number;
  heightUnitId?: number;
  weight?: number;
  weightUnitId?: number;
  age?: number;
  dateOfBirth?: string;
  mexicanLeagueTeamId?: string;
  addressCountryId?: number;
  addressStateProvinceId?: number;
  addressStateProvince?: string;
  addressCityId?: number;
  addressCity?: string;
  addressSectorId?: number;
  address1?: string;
  address2?: string;
  address3?: string;
  addressZipCode?: string;
  phoneNumbers?: PhoneNumberDTO[];
  emailAddresses?: EmailAddressDTO[];
  numberGuardiansSigned?: number;
  requiredDocuments?: Document[];
  uploadDocuments?: ProspectUploadDTO[];
  consentForms?: ProspectUploadDTO[];
  trainers?: TrainerDTO[];
  isProfileLocked?: boolean;
  isTPP?: boolean;
};

export type NationalIdDTO = {
  id?: number;
  sequenceNumber?: number;
  nationalIdTypeId?: number;
  nationalId?: string;
};

export type AddressDTO = {
  addressId?: number;
  prospectId?: number;
  sequenceNumber?: number;
  addressCountryId?: number;
  address1?: string;
  address2?: string;
  address3?: string;
  addressCityId?: number;
  addressCity?: string;
  addressSectorId?: number;
  addressStateProvince?: string;
  addressStateProvinceId?: number;
  zipCode?: string;
};

export type PhoneNumberDTO = {
  phoneNumberId?: number;
  prospectId?: number;
  sequenceNumber?: number;
  phoneNumber?: string;
  phoneNumberCommunicationTypeId?: number;
  phoneNumberTypeId?: number;
  countryId?: number;
  dialCode?: string;
  countryCode?: CountryCode;
};

export type EmailAddressDTO = {
  prospectId?: number;
  emailAddressId?: number;
  sequenceNumber?: number;
  emailAddress?: string;
  emailAddressTypeId?: number;
};

export type Document = {
  countryDocumentId: number;
  countryId: number;
  countryName: string;
  documentId: number;
  documentName: string;
  requiredFlag: boolean;
  optionalFlag: boolean;
  esDocumentName: string;
};

export type ProspectStatus = 1 | 2 | 3 | 4 | 5 | 6;

export const ProfileIncompleteStatuses: ProspectStatus[] = [1, 2];

export const ProfileCompleteStatusIds: ProspectStatus[] = [3, 4, 5, 6];

export type ProspectStatusDTO = {
  statusId: ProspectStatus;
  submittedToEbisDate: Date;
};

export type OnboardingDTO = {
  communicationPreferenceTypeId?: number;
  phoneCountryId?: number;
  phoneNumber?: string;
};
