import { useTranslation } from "react-i18next";

type useLanguageType = {
  currentLanguage: string;
  isEn: boolean;
  isEs: boolean;
};

const useLanguage = (): useLanguageType => {
  const { i18n } = useTranslation([]);
  const currentLanguage: string = i18n.language;
  const isEs: boolean = currentLanguage === "es";
  const isEn: boolean = currentLanguage === "en";

  return { currentLanguage, isEs, isEn };
};

export default useLanguage;
