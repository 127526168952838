import {
  BaseballInformationDTO,
  BioInformationDTO,
  ContactInformationDTO,
  ProspectNameDTO,
  ProspectProfileDTO,
  ProspectRelativesDTO,
  TrainerInformationDTO,
} from "../types/ProspectProfile";
import { NationalIdDTO } from "../types/Profile";
import { isNationalIdRequired } from "./ProfileUtils";
import { CountryIds } from "../types/Metadata";

const NUM_SECTIONS = 7;

export const calcProspectProfileCompletion = (profile: ProspectProfileDTO): number => {
  if (!profile) return 0.0;

  const nationalIdRequired = isNationalIdRequired(profile);
  let completion = 0.0;

  completion += (1 / NUM_SECTIONS) * calcProspectNameCompletion(profile as ProspectNameDTO);
  completion += (1 / NUM_SECTIONS) * calcBioInformationCompletion(profile as BioInformationDTO);
  completion += (1 / NUM_SECTIONS) * calcBaseballInformationCompletion(profile as BaseballInformationDTO);
  completion += (1 / NUM_SECTIONS) * calcContactInformationCompletion(profile as ContactInformationDTO);
  completion += (1 / NUM_SECTIONS) * calcProspectRelativesCompletion(profile as ProspectRelativesDTO);
  completion += (1 / NUM_SECTIONS) * calcTrainerInformationCompletion(profile as TrainerInformationDTO);
  completion += (1 / NUM_SECTIONS) * calcNationalIdCompletion(profile as NationalIdDTO, nationalIdRequired);
  return completion;
};

const calcProspectNameCompletion = (profile: ProspectNameDTO): number => {
  if (!profile) return 0.0;
  let completion = 0.0;

  if (!!profile?.firstName?.length) {
    completion += 0.25;
  }

  if (!!profile?.middleName?.length) {
    completion += 0.25;
  }

  if (!!profile?.lastName?.length) {
    completion += 0.25;
  }

  if (!!profile?.extLastName?.length) {
    completion += 0.25;
  }

  return completion;
};

const calcBioInformationCompletion = (profile: BioInformationDTO): number => {
  if (!profile) return 0.0;

  let completion = 0.0;

  if (!!profile.registrationCountryId) {
    completion += 0.2;
  }

  if (!!profile.dateOfBirth?.length) {
    completion += 0.2;
  }

  if (profile.hasTwin != undefined && (!profile.hasTwin || profile.twinCount != undefined)) {
    completion += 0.2;
  }

  if (
    !!profile.birthCountryId &&
    (!!profile.birthStateProvince?.length || !!profile.birthStateProvinceId) &&
    (!!profile.birthCity?.length || !!profile.birthCityId)
  ) {
    completion += 0.2;
  }

  if (
    (profile.birthCountryId !== CountryIds.CUBA ||
      (!!profile.livesInUsCanPr != undefined && profile.livesInUsCanPr != null)) &&
    profile.traveledToUsCanPrFlag != undefined &&
    (!profile.traveledToUsCanPrFlag ||
      (profile.traveledToUsCanPrCount != undefined &&
        profile.longestStayUsCanPr != undefined &&
        !!profile.recentStayUsPrCan?.length &&
        (profile.birthCountryId !== CountryIds.CUBA || !!profile.travelDates.length)))
  ) {
    completion += 0.2;
  }

  return completion;
};

const calcBaseballInformationCompletion = (profile: BaseballInformationDTO): number => {
  if (!profile) return 0.0;

  let completion = 0.0;

  if (!!profile.positionId) {
    completion += 0.25;
  }

  if (!!profile.throwingHandId) {
    completion += 0.25;
  }

  if (!!profile.weight && !!profile.weightUnitId) {
    completion += 0.25;
  }

  if (!!profile.height && !!profile.heightUnitId) {
    completion += 0.25;
  }

  return completion;
};

const calcContactInformationCompletion = (profile: ContactInformationDTO): number => {
  if (!profile) return 0.0;
  return !!profile.phoneNumbers?.length || !!profile.emailAddresses?.length ? 1.0 : 0.0;
};

const calcProspectRelativesCompletion = (profile: ProspectRelativesDTO): number => {
  if (!profile) return 0.0;
  const { mother, father } = profile;
  if (!mother || !father) return 0.0;

  let completion = 0.0;
  if (mother.hasRelative === false) {
    completion += 0.5;
  } else {
    if (mother.firstName?.length) {
      completion += 0.125;
    }
    if (mother.lastName?.length) {
      completion += 0.125;
    }
    if (!!mother.contactTypeId) {
      completion += 0.125;
    }
    if ((!!mother.phoneNumberCountryId && !!mother.phoneNumber?.length) || !!mother.email?.length) {
      completion += 0.125;
    }
  }

  if (father.hasRelative === false) {
    completion += 0.5;
  } else {
    if (father.firstName?.length) {
      completion += 0.125;
    }
    if (father.lastName?.length) {
      completion += 0.125;
    }
    if (!!father.contactTypeId) {
      completion += 0.125;
    }
    if ((!!father.phoneNumberCountryId && !!father.phoneNumber?.length) || !!father.email?.length) {
      completion += 0.125;
    }
  }

  return completion;
};

const calcTrainerInformationCompletion = (profile: TrainerInformationDTO): number => {
  if (!profile) return 0.0;
  return profile.hasTrainer === false || !!profile.trainers?.length ? 1.0 : 0.0;
};

const calcNationalIdCompletion = (profile: NationalIdDTO, required: boolean): number => {
  if (!profile) {
    return 0.0;
  } else if (!required && !profile.nationalIdTypeId && !profile.nationalId?.length) {
    return 1.0;
  } else {
    return !!profile.nationalIdTypeId && !!profile.nationalId?.length ? 1.0 : 0.0;
  }
};
