import React, { useContext } from "react";
import { Accordion, AccordionBody, AccordionHeader, useBreakpoints } from "best-common-react";
import { ThemeContext } from "styled-components";
import { Body, Item, Label, Row, Value } from "./ReadOnlyProfileStyles";
import { useProspectProfile } from "../../../contexts/ProsepctProfileContext";
import { useTranslation } from "react-i18next";

const ProspectNameReadOnly: React.FC = () => {
  const { t } = useTranslation("profile");
  const { isMobile } = useBreakpoints();
  const { profile } = useProspectProfile();
  const Theme = useContext(ThemeContext);

  return (
    <Accordion
      accordionKey="basball-info"
      styles={{ ...Theme.accordion, body: { ...Theme.accordion.body, background: Theme.colors.cloud, border: "none" } }}
    >
      <AccordionHeader>{t("profile:nameSection")}</AccordionHeader>
      <AccordionBody>
        <Body>
          <Row>
            <Item isMobile={isMobile}>
              <Label>{t("profile:firstName")}</Label>
              <Value>{profile.firstName}</Value>
            </Item>
            <Item isMobile={isMobile}>
              <Label>{t("profile:lastName")}</Label>
              <Value>{profile.lastName}</Value>
            </Item>
          </Row>
          <Row>
            <Item isMobile={isMobile}>
              <Label>{t("profile:middleName")}</Label>
              <Value>{profile.middleName}</Value>
            </Item>
            <Item isMobile={isMobile}>
              <Label>{t("profile:extLastName")}</Label>
              <Value>{profile.extLastName}</Value>
            </Item>
          </Row>
        </Body>
      </AccordionBody>
    </Accordion>
  );
};

export default ProspectNameReadOnly;
