import { Input } from "best-common-react";
import React, { useEffect, useState } from "react";
import { IsValidEndAdorner } from "../../../util/ProspectProfileValidationUtil";

type NationIdInputProps = {
  id: string;
  value: string;
  onChange: (value: string) => void;
  typeId?: number;
  format?: string;
  pattern?: string;
  readOnly?: boolean;
  disabled?: boolean;
  required?: boolean;
};

const NationalIdInput: React.FC<NationIdInputProps> = ({
  id,
  value,
  onChange,
  typeId,
  format,
  pattern,
  readOnly = false,
  disabled = false,
  required = false,
}) => {
  const [valid, setValid] = useState<boolean>(!!typeId && !format);

  useEffect(() => {
    if (!!typeId && !!format) {
      if (!value?.length) {
        setValid(true);
      } else {
        const regex = new RegExp(format);
        const repeatingRegex = new RegExp("^(\\\\d)\\\\1+$");
        const validPattern = regex.test(value) && !repeatingRegex.test(value);
        setValid(validPattern);
      }
    } else if (!!typeId && !format && !!value?.length) {
      setValid(true);
    } else {
      setValid(false);
    }
  }, [typeId, format, value]);

  return (
    <Input
      id={id}
      value={value}
      onChange={onChange}
      placeholder={!!pattern ? pattern : ""}
      readOnly={readOnly}
      disabled={disabled}
      required={required}
      endAdorner={
        !!value?.length && (
          <IsValidEndAdorner valid={valid} className={valid ? "fa fa-check-circle" : "fa fa-exclamation-circle"} />
        )
      }
    />
  );
};

export default NationalIdInput;
