import React, { useEffect, useState } from "react";
import { useConsentFormEsign } from "../../contexts/ConsentFormEsignContext";
import styled from "styled-components";
import { useAuthentication } from "../../contexts/AuthenticationContext";
import GuardianConsentFormSignerCard from "./GuardianConsentFormSignerCard";
import ProspectConsentFormSignerCard from "./ProspectConsentFormSignerCard";
import IPLContentContainer from "../display/IPLContentContainer";
import IplContentButtons from "../display/IplContentButtons";
import { Button } from "best-common-react";
import { useNavigate } from "react-router-dom";
import { RouteConstants } from "../../constants/RouteConstants";
import { ConsentFormSignerDTO } from "../../types/ConsentForm";
import { useProspectConsentFormSse } from "../../contexts/ProspectConsentFormSseContext";
import { useTranslation } from "react-i18next";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  min-height: 25rem;
`;

const ConsentFormSigners: React.FC = () => {
  const { t } = useTranslation("translation");
  const { userDetails } = useAuthentication();
  const { prospectConsentForm, guardianSigners, updateAndSaveGuardianSigner } = useConsentFormEsign();
  const navigate = useNavigate();
  const { signingUrl, signedTs } = useProspectConsentFormSse();
  const [prospectSigningUrl, setProspectSigningUrl] = useState<string>(prospectConsentForm?.prospectSigningUrl);
  const [prospectSignedTs, setProspectSignedTs] = useState<Date>(prospectConsentForm?.prospectSignedTs);

  useEffect(() => {
    if (signingUrl) {
      setProspectSigningUrl(signingUrl);
    }
  }, [signingUrl]);

  useEffect(() => {
    if (signedTs) {
      setProspectSignedTs(signedTs);
    }
  }, [signedTs]);

  const onClose = () => {
    navigate(RouteConstants.BASE);
  };

  return (
    <IPLContentContainer>
      <Container>
        <ProspectConsentFormSignerCard
          name={`${userDetails?.firstName} ${userDetails?.lastName}`}
          hasSigned={!!prospectSignedTs}
          signingUrl={prospectSigningUrl}
        />
        {prospectConsentForm.guardianSignaturesRequired && (
          <>
            {guardianSigners.guardian1.required && (
              <GuardianConsentFormSignerCard
                signer={guardianSigners.guardian1}
                onUpdate={(updated: ConsentFormSignerDTO) => updateAndSaveGuardianSigner("guardian1", updated)}
              />
            )}
            {guardianSigners.guardian2.required && (
              <GuardianConsentFormSignerCard
                signer={guardianSigners.guardian2}
                onUpdate={(updated: ConsentFormSignerDTO) => updateAndSaveGuardianSigner("guardian2", updated)}
              />
            )}
          </>
        )}
      </Container>
      <IplContentButtons>
        <Button variant="secondary" className="w-100" size="lg" onClick={onClose}>
          {t("translation:close")}
        </Button>
      </IplContentButtons>
    </IPLContentContainer>
  );
};

export default ConsentFormSigners;
