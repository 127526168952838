import { InButtonOverrideStyles, NavyColors } from "best-common-react";

export const LinkButtonStyles: InButtonOverrideStyles = {
  link: {
    color: NavyColors["blue"],
    hover: NavyColors["blue-hover"],
    size: "0.875rem",
    disabled: NavyColors["fog"],
    opacity: 1,
    textDecoration: "none",
  },
};
